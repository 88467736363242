/*
  WGLTR.js 1.0.0 by @ilvimafr - https://ilvimafr.github.io/wgltr/
  -----------------
  Table of contents

  1. Helpers
  1.1. Abbreviations
  1.2. Global variables
  1.2.1. Default options
  1.2.2. Presets
  1.2.3. Non inherited options
  1.2.4. Options list to refresh
  1.3. Global functions
  1.3.1. General functions
  1.3.2. WebGL functions
  1.3.3. DOM functions
  1.3.4. Math functions
  1.3.5. Parse functions

  2. GLSL Effects information
  2.1. Effects base count
  2.2. WebGL effects arguments base
  2.3. WebGL effects

  3. Options
  3.1. Process raw options
  3.2. Set option
  3.3. Resolve string presets to default values
  3.4. Get array of IDs which are involved in the property
  3.5. Get parent value if current value not set
  3.6. Calculate non inherited options taking into account change status

  4. Font Faces
  4.1. Add to loaded font faces with timestamp
  4.2. Update existing font faces
  4.3. Check availability

  5. Font Baseline
  5.1. Initialization
  5.2. Clear cache for specified family
  5.3. Find baseline offset

  6. Text Glyphs
  6.1. Place invisible block to process
  6.2. Set node to process
  6.3. Get count of symbols that a glyph has

  7. Canvas
  7.1. Initialization
  7.2. Resize
  7.3. Set global composite operation
  7.4. Set style
  7.5. Fill rect
  7.6. Clear
  7.7. Set shadow
  7.8. Create pattern
  7.9. Draw linear gradient
  7.10. Draw radial gradient
  7.11. Draw gradient
  7.12. Draw decorations
  7.13. Create canvases

  8. Canvas patterns
  8.1. Get
  8.2. Create
  8.3. Image
  8.4. Lines
  8.5. Zigzag
  8.6. Circles
  8.7. Triangles
  8.8. Noise

  9. Pictographic
  9.1. Initialize canvas
  9.2. Is pictographic

  10. HTML Text
  10.1. Initialize
  10.2. Process text nodes
  10.3. Transition callback
  10.4. Change child nodes visibility with the specified index
  10.5. Hide change node visibility
  10.6. Check if node is current
  10.7. Check if node is visible on HTML
  10.8. Check if node visible on screen
  10.9. Refresh styles
  10.10. Refresh colors
  10.11. Process color
  10.12. Refresh shadows
  10.13. Get text nodes in order
  10.14. Calculate bounding box for all possible cases
  10.15. Calculate bounding box for current visible nodes
  10.16. Calculate bounding box for each change text
  10.17. Resize
  10.18. Get the position and text of nodes, taking into account line breaks
  10.19. Reset lines information
  10.20. Process line number
  10.21. Process and push word to list
  10.22. Add change current line to all screen visible change nodes
  10.23. Restore positions after change for smooth animation
  10.24. Refresh styles unique id & styles paddings
  10.25. Detect if HTML block needs to be smooth resized
  10.26. Start HTML smooth resize
  10.27. Reset HTML smooth resize
  10.28. Process HTML smooth resize
  10.29. Destroy

  11. Font texture
  11.1. Initialization
  11.2. Refresh texture if needed
  11.3. Get character unique ID
  11.4. Get Glyph by ID
  11.5. Measure text and canvas size
  11.6. Process glyph
  11.7. Process shadow glyphs
  11.8. Calculate glyph sizes
  11.9. Calculate maximum glyphs size
  11.10. Calculate glyphs offset
  11.11. Calculate texture size
  11.12. Prepare texture sizes
  11.13. Load decorations images
  11.14. Calculate decorations texture sizes
  11.15. Create decorations texture
  11.16. Update WebGL texture
  11.17. Update texture shader
  11.18. Destroy font texture

  12. WebGL Text
  12.1. Initialization
  12.2. Refresh
  12.3. Refresh chars position and delay
  12.4. Calculate delays for each characters group
  12.5. Calculate change additive and total times
  12.6. Turn chars px position to WebGL percent
  12.7. Set changed lines coords for using in vertex shader
  12.8. Calculate canvas, word, line and wrap sizes
  12.9. Calculate rect offsets
  12.10. Calculate glyphs webgl uv coordinates
  12.11. Refresh dynamic backgrounds
  12.12. Create backgrounds textures
  12.13. Bind backgrounds textures
  12.14. Refresh scrolls values
  12.15. Process scrolls times
  12.16. Set scrolls strength
  12.17. Create shader object
  12.18. Refresh shader code
  12.19. Generate GLSL backgrounds code
  12.19. Update WebGL Buffers
  12.20. Update shader uniforms
  12.21. Update uniforms for dynamic backgrounds
  12.22. Update mouse uniform
  12.23. Update Scroll uniform
  12.24. Update canvas offset uniform
  12.25. Refresh offsets for vertex buffers
  12.26. Update VBO general data
  12.27. Update Appears VBO
  12.28. Update positions VBO
  12.29. Update colors VBO
  12.30. Draw text
  12.31. Process change elements
  12.32. Manual Appear
  12.33. Manual Disappear

  13. Text Order
  13.1. Start
  13.2. Push character to current operation
  13.3. Process operations
  13.4. Create array of character groups

  14. WebGL renderer
  14.1. Initialization
  14.2. Use shader program
  14.3. Resize WebGL renderer
  14.4. Drawing glyphs with shader
  14.4. Create noise texture
  14.5. Prepare to drawing font texture
  14.6. Finish drawing font texture
  14.7. Restore blending function
  14.8. Draw glyph on font texture
  14.9. Create WebGL Texture
  14.10. Bind WebGL Texture
  14.11. Update WebGL Texture
  14.12. Destroy WebGL Renderer

  15. Shaders global variables
  16. Shader
  16.1. Initialization
  16.2. Refresh shader code
  16.3. Link
  16.4. Compile
  16.5. Generate code
  16.6. Get Vertex Buffers
  16.7. Update Vertex Buffers
  16.8. Clear Vertex Buffers
  16.9. Load Uniform
  16.10. Clean all dynamic uniforms
  16.11. Add dynamic uniform
  16.12. Destroy

  17. Animation manager
  17.1. Change drawing status
  17.2. Draw loop
  17.3. Request animation frame
  17.4. Pause drawing for all items.
  17.5. Resume drawing

  18. WebGL Text Renderer
  18.1. Initialization
  18.2. MutationObserver callback
  18.3. Start changing
  18.4. Stop changing
  18.5. Font loaded callback
  18.6. Mouse move callback
  18.7. Scroll callback
  18.8. Resize callback
  18.9. Remove CSS transform
  18.10. Restore CSS transform
  18.11. Refresh canvas position
  18.12. Refresh canvas size
  18.13. Draw text
  18.14. Set options
  18.15. Change text with animation
  18.16. Is text appeared
  18.17. Is text manual appeared
  18.18. Is text disappeared
  18.19. Reset text appear and change
  18.20. Manual Appear
  18.21. Manual Disappear
  18.22. Dispatch WGLTR event
  18.23. Add WGLTR events
  18.24. Remove WGLTR events
  18.25. Destroy

  19. WebGL Text Renderer global variables

  20. WebGL Text Renderer global functions
  20.1. Refresh new elements by attribute
  20.2. Resize all WGLTR instances
  20.3. Destroy all WGLTR instances
  20.4. Set default options
  20.5. Refresh Intersection Observer

  21. Compatibility check

  22. Global events
  22.1. Intersection Observer
  22.2. Resize Observer
  22.3. Window events
*/

/**
 * 1. Helpers
 * 1.1. Abbreviations
 */
const wnd = window;
const doc = document;
const raf = wnd.requestAnimationFrame.bind(wnd);
const IO = wnd.IntersectionObserver;
const RO = wnd.ResizeObserver;
const MO = wnd.MutationObserver;
const Rect = wnd.DOMRect;
const q = doc.querySelectorAll.bind(doc);
const create = doc.createElement.bind(doc);
const ua = wnd.navigator.userAgent.toLowerCase();
const isGecko = ua.indexOf('gecko/') !== -1;
const isWebkit = ua.indexOf('webkit') > 0 && !(ua.indexOf('chrome') > 0);
let dpr = wnd.devicePixelRatio;
const {
  min, max, round, ceil, floor, pow, abs, sign,
  sqrt, log2, atan2, tan,
  sin, cos, PI, random,
} = Math;


/**
 * 1.2. Global variables
 */
const prefix = 'wgltr';
const prefixOptions = `${prefix}Options`;
const prefixAttr = `data-${prefix}`;
const prefixInnerAttr = `data-${prefix}-wrap`;
const pictographicRegex = new RegExp(
    '(\\u00a9|' +
    '\\u00ae|' +
    '[\\u2000-\\u3300]|' +
    '\\ud83c[\\ud000-\\udfff]|' +
    '\\ud83d[\\ud000-\\udfff]|' +
    '\\ud83e[\\ud000-\\udfff])',
    '');

/**
 * @typedef {object} WGLTROptions
 * @property {HTMLElement} [el]
 * Element for which options are applied
 * @property {number} [colorsChangeDelay]
 * After a color change, the color will be checked for that time in milliseconds
 * @property {number|number[]} [canvasPadding]
 * Extra padding of the canvas in pixels
 * @property {number} [glyphsPadding]
 * Extra padding of each glyph on the WebGL canvas in pixels
 * @property {number} [texturePadding]
 * Extra padding of each glyph on the texture in pixels
 * @property {string} [textureColor]
 * Color for drawing glyphs on the texture
 * @property {boolean} [pauseOnBlur]
 * Pause the animation when the window loses focus. Only for `setDefaultOptions`
 * @property {boolean} [wrapOnly]
 * Process only children elements that have own options
 * @property {boolean} [roundPosition]
 * Round off the position of characters on the canvas
 * @property {number} [fov]
 * Field of view
 * @property {boolean|number} [scaleFontSize]
 * Base font size for scale effects values
 * @property {'letters'|'words'} [split]
 * Splitting characters or words on the texture
 * @property {string[]} [reserved]
 * Reserved characters for the texture
 * @property {string[]} [ligatures]
 * Groups of characters to draw as a single glyph
 * @property {boolean} [cssShadows]
 * Process CSS shadows
 * @property {boolean} [customTextDecorationColor]
 * Do not process the CSS text-decorations

 * @property {HTMLElement} [scrollParent]
 * Scroll parent element for scroll event
 * @property {boolean|number} [smoothWidth]
 * Smooth width resize. Number means time in seconds
 * @property {boolean|number} [smoothHeight]
 * Smooth height resize. Number means time in seconds

 * @property {boolean} [appear]
 * Activate text appearance
 * @property {boolean} [appearDestroy]
 * Destroy WGLTR instance after text is appeared
 * @property {number} [appearMargin]
 * Distance in pixels how much text should be visible on screen
 * to start appearing
 * @property {string} [appearFunction]
 * Timing function for text appearing
 * @property {string} [appearPartFunction]
 * Timing function for the appearance of each character
 * @property {boolean} [appearFunctionReset]
 * The point at which the time function is reset for the following text
 * @property {boolean} [appearReset]
 * The point at which the time is reset for the following text
 * @property {number} [appearDelay]
 * Text appearance delay
 * @property {number} [appearStartDelay]
 * Text appearance delayed start
 * @property {number} [appearSpeed]
 * Appearance time
 * @property {number|string} [appearCount]
 * Delayed characters count
 * @property {boolean} [appearReverse]
 * Text appearance in reverse order
 * @property {boolean} [appearRandom]
 * Text appearance in random order
 * @property {number} [appearAsync]
 * Text appearance additive random delay
 * @property {boolean} [appearShadowOpacityOnly]
 * Disabling appearance effects for shadows

 * @property {boolean} [disappearReset]
 * The point at which the time is reset for the following text
 * @property {string|number} [disappearDelay]
 * Disappearance delay for characters
 * @property {string|number} [disappearSpeed]
 * Disappearance time
 * @property {string|number} [disappearCount]
 * Delayed characters count
 * @property {string|boolean} [disappearReverse]
 * Text disappearance in reverse order
 * @property {string|boolean} [disappearRandom]
 * Text disappearance in random order
 * @property {string|number} [disappearAsync]
 * Text disappearance additive random delay

 * @property {boolean|string} [change]
 * Activate animated text changes
 * @property {string} [changeAlign]
 * Text alignment when changing
 * @property {number} [changeTime]
 * Text change delay
 * @property {number} [changeStartTime]
 * Text change start time
 * @property {number} [changeDelay]
 * Delay between change text appearing and disappearing
 * @property {boolean} [changeSkipFirst]
 * Ignore first change element when change
 * @property {string|boolean} [changeAppearReset]
 * The point at which the time is reset for the following text
 * @property {string|number} [changeAppearDelay]
 * Change appearance delay for characters
 * @property {string|number} [changeAppearSpeed]
 * Change appearance time
 * @property {string|number} [changeAppearCount]
 * Change appearance delayed characters count
 * @property {string|boolean} [changeAppearRandom]
 * Change appearance in random order
 * @property {string|boolean} [changeAppearReverse]
 * Change appearance in reverse order
 * @property {string|number} [changeAppearAsync]
 * Change appearance additive random delay
 * @property {boolean} [changeLoop]
 * Infinite change restart
 * @property {boolean} [changeRandom]
 * Random change order
 * @property {number|boolean} [changeMove]
 * Smoothly move text when changing
 * @property {boolean} [changeMultiline]
 * Move animation for text after change if text line is changed

 * @property {string|boolean} [stroke]
 * Activate text stroke
 * @property {number} [strokeWidth]
 * Text stroke width in pixels
 * @property {number} [strokeBlur]
 * Text stroke blur in pixels
 * @property {string|boolean} [decorsStroke]
 * Cut the text stroke out of the decorations
 * @property {number} [decorsStrokeWidth]
 * The width of the text stroke to cut out of the decorations
 * @property {number} [decorsStrokeBlur]
 * The blur of the text stroke to cut out of the decorations

 * @property {string|[]} [decors]
 * Decorations that are cut from the glyphs on the texture
 * @property {string|[]} [backgrounds]
 * Dynamic text backgrounds
 * @property {string|[]} [effects]
 * Animated text effects
 * @property {string|[]} [staticEffects]
 * Static effects applied to the texture
 * @property {string|[]} [mouseEffects]
 * Animated mouse effects
 * @property {string|[]} [scrollEffects]
 * Animated scroll effects
 * @property {string|[]} [appearEffects]
 * Animated text appear effects
 * @property {string|[]} [disappearEffects]
 * Animated text disappear effects
 * @property {string|[]} [changeAppearEffects]
 * Animated text appear effects for change animation only

 * @property {function|undefined} [appeared]
 * Callback when text is appeared
 * @property {function|undefined} [disappeared]
 * Callback when text is disappeared
 * @property {function|undefined} [changed]
 * Callback when text is changed
 * @property {function|undefined} [changeStarted]
 * Callback when text change is started
 * @property {function|undefined} [changeAppeared]
 * Callback when text is appeared when changing
 * @property {function|undefined} [changeDisappeared]
 * Callback when text is disappeared when changing
 */

/**
 * 1.2.1. Default options
 * @type {WGLTROptions}
 */
const defaultOptions = {
  'colorsChangeDelay': isGecko ? 120 : 10,
  'canvasPadding': 4,
  'glyphsPadding': isGecko ? 4 : 2,
  'texturePadding': 2,
  'textureColor': 'currentcolor',
  'pauseOnBlur': false,
  'wrapOnly': false,
  'roundPosition': true,
  'fov': 40,
  'scaleFontSize': false,
  'scrollParent': document.documentElement,
  'split': 'letters',
  'reserved': [],
  'ligatures': [],
  'smoothWidth': false,
  'smoothHeight': .2,
  'cssShadows': true,
  'customTextDecorationColor': false,

  'appear': false,
  'appearDestroy': false,
  'appearMargin': 100,
  'appearFunction': 'inOutSine',
  'appearPartFunction': 'inOutSine',
  'appearFunctionReset': false,
  'appearReset': false,
  'appearDelay': .5,
  'appearStartDelay': 0,
  'appearSpeed': .5,
  'appearCount': 1,
  'appearReverse': false,
  'appearRandom': false,
  'appearAsync': 0,
  'appearShadowOpacityOnly': true,

  'disappearReset': false,
  'disappearDelay': 'inherit',
  'disappearSpeed': 'inherit',
  'disappearCount': 'inherit',
  'disappearReverse': 'inherit',
  'disappearRandom': 'inherit',
  'disappearAsync': 'inherit',

  'change': false,
  'changeAlign': 'inherit',
  'changeTime': 3,
  'changeStartTime': 1,
  'changeDelay': -.2,
  'changeSkipFirst': false,
  'changeAppearReset': false,
  'changeAppearDelay': 'inherit',
  'changeAppearSpeed': 'inherit',
  'changeAppearCount': 'inherit',
  'changeAppearRandom': 'inherit',
  'changeAppearReverse': 'inherit',
  'changeAppearAsync': 'inherit',
  'changeLoop': true,
  'changeRandom': false,
  'changeMove': .2,
  'changeMultiline': true,

  'stroke': false,
  'strokeWidth': 2,
  'strokeBlur': 0,
  'decors': [],
  'decorsStroke': false,
  'decorsStrokeWidth': 2,
  'decorsStrokeBlur': 0,
  'backgrounds': [],

  'effects': [],
  'staticEffects': [],
  'mouseEffects': [],
  'scrollEffects': [],
  'appearEffects': [],
  'disappearEffects': [],
  'changeAppearEffects': [],

  'appeared': undefined,
  'disappeared': undefined,
  'changed': undefined,
  'changeStarted': undefined,
  'changeAppeared': undefined,
  'changeDisappeared': undefined,
};

/**
 * 1.2.2. Presets
 * @internal
 */
const defaultSimplePresets = {
  'effects': {
    'wobbling': [['wobbling', 1, 1]],
    'waves': [['waves', 1, 1, 1]],
    'longWaves': [['waves', 1, 3, 1]],
    'verticalWaves': [['verticalWaves', 1, 1, 1, 0]],
    'noise': [['wobbling', .5, 1], ['noise', 1, 1]],
    'noiseColor': [
      ['wobbling', .5, 1],
      ['colorNoise', 5, 1, 'word', .95, '#f00f', .5, 1, 0, 1, .5],
    ],
    'smallNoiseColor': [
      ['wobbling', .5, 1],
      ['colorNoise', 5, 1, 'word', 0.95, '#f00f', 0.28, 0.26, 1, 0.3, 0.3],
      ['colorNoise', 5, 1, 'word', 0, '#f00f', 0.2, -0.51, 1, 0.2, 0.2],
    ],
    'verticalNoiseColor': [
      ['wobbling', .5, 1],
      ['colorNoise', 5, 1, 'word', .95, '#f00f', .5, 0, 1, .5, 1],
    ],
    'alphaNoiseColor': [
      ['wobbling', .5, 1],
      ['colorNoise', 5, 1, 'word', .95, '#f00f', .4, 1, 0, 1, .5],
      ['alphaNoise', 5, 1, 'word', .95, .5, 0.25, 1, 0, 1, .5],
    ],
    'noiseAlpha': [
      ['wobbling', .5, 1],
      ['alphaNoise', 5, 1, 'word', .95, .7, 0.4, 1, 0, 1, .5],
    ],
    'glitch': [
      ['wobbling', .5, 1],
      ['glitch', 5, 0.4, 'word', .95, 1, 1, 90],
    ],
    'smoothGlitch': [
      ['wobbling', .5, 1],
      ['smoothGlitch', 5, 0.4, 'word', .95, 1, 1, 145],
    ],
    'colorGlitch': [
      ['wobbling', .5, 1],
      ['colorGlitch', 5, 0.4, 'word', .95, '#f00b', '#0afb', 1, 1],
    ],
    'flame': [
      ['wobbling', .5, 1],
      ['flame', 5, 1, 'word', .95, '#f00f', 1, 1, 1, 90],
    ],
    'colors': [
      ['wobbling', .5, 1],
      ['colors', '#f005', '#0af5', '#ff05', '#0f75', 1.2, 1],
    ],
    'translate': [
      ['wobbling', .5, 1],
      ['translate', 3, 0.2, 'glyph', .95, .2, 0, 0],
    ],
    'scale': [
      ['wobbling', .5, 1],
      ['scale', 3, 0.2, 'glyph', .95, 0.2, 0.2],
    ],
    'rotate': [
      ['wobbling', .5, 1],
      ['rotate', 3, 0.2, 'glyph', .95, 0, 0, 30],
    ],
  },
  'staticEffects': {
    'distortion': [['distortion', 1, 1, 1, 1, 1]],
    'waves': [['waves', 1, 1]],
    'noise': [['noise', .7]],
    'alphaNoise': [['alphaNoise', .25, .9, 2, 2]],
    'stretchedNoise': [['alphaNoise', .25, .9, 10, 1.5]],
    'strokeNoise': [['strokeNoise', 1]],
    'glitch': [['glitch', .8, .9, 120]],
    'smallGlitch': [['glitch', .7, .2, 120]],
  },
  'decors': {
    'linearGrad': [['linearGrad', 'out', 90, .6, .9]],
    'stroke': {
      presets: [],
      options: {
        stroke: 'out',
      },
    },
    'filledStroke': {
      presets: [['fill', 'in', 1]],
      options: {
        stroke: 'out',
        decorsStroke: 'all',
        decorsStrokeWidth: 3,
      },
    },
    'lines': [['lines', 'out', 0, 4, 1, .5]],
    'zigzag': [['zigzag', 'out', 135, 4, 1, .5]],
    'obliqueLines': [['lines', 'out', 135, 4, 1, .5]],
    'gradLines': [
      ['lines', 'out', 135, 4, 2, .5],
      ['linearGrad', 'decorsOut', 90, 1, .8],
    ],
    'strokedLines': {
      presets: [['lines', 'out', 135, 5, 2, 0.5]],
      options: {
        decorsStroke: 'out',
        decorsStrokeWidth: 4,
        decorsStrokeBlur: 2,
      },
    },
    'noiseLines': [['noiseLines', 'out', 135, 6, 2, .5, 2]],
    'hardNoiseLines': [['noiseLines', 'out', 135, 10, 2, .5, 10]],
    'dots': [['circles', 'out', 1, 4, 1, 1, 0, .8]],
    'circles': [['circles', 'out', 0, 2, 0, 4, 4, 0.7]],
  },
  'backgrounds': {
    'linearGrad': [['linearGrad', 'in', 1, 'wrap', 45, '#f00f', '#fa0f', .8]],
    'radialGrad': [['radialGrad', 'in', 1, 'wrap', 'circumscribed',
      '#f08f', '#fa0f']],
    'lines': [['lines', 'out', .4, '#c224', '#c22f', 10, 1, -135, 4]],
    'gradLines': [
      ['lines', 'out', .8, '#c224', '#c22f', 10, 1, -135, 4],
      ['linearGrad', 'bgOut', 1, 'glyph', 0, '#f220', '#f22', .75],
    ],
    'noiseLines': [
      ['noiseLines', 'out', '#c22d', 10, 3, 135, 10],
      ['linearGrad', 'bgOut', 1, 'glyph', 180, '#f220', '#f22', .75],
    ],
    'dots': [
      ['dots', 'out', '#c22f', 0.5, 0.5, 1],
    ],
  },
  'appearEffects': {
    'alpha': [['alpha', 0, 1, 1]],
    'color': [
      ['alpha', 0, .3, 1],
      ['color', 0, 1, '#c22f'],
    ],
    'alphaNoise': [['alphaNoise', 0, 1, -1, 0, 1, .5]],
    'colorNoise': [
      ['alphaNoise', 0, 0.8, -1, 0, 1, .5],
      ['colorNoise', 0, 1, '#f00f', -1, 0, 1, .5],
    ],
    'noise': [
      ['alphaNoise', 0, 0.5, -1, 0, 1, .5],
      ['noise', 0, 1, 2, 1],
    ],
    'wobbling': [
      ['alpha', 0, .5, 1],
      ['wobbling', 0, 1, 2, 2],
    ],
    'glitch': [['glitch', 0, 1, 1, 1, 90]],
    'colorGlitch': [
      ['alpha', 0, .2, 1],
      ['glitch', 0, 1, 1, 1, 90],
      ['colorGlitch', 0, 1, '#f00b', '#0afb', 1, 1],
    ],
    'roll': [
      ['alpha', 0, .7, 1],
      ['rotate', 0, 1, 0, 0, 45],
      ['translate', 0, 1, -.6, 0, 0],
    ],
    'noiseFromTop': [
      ['alphaNoise', 0, 1, .2, 1.4, .7, 1.5],
      ['alpha', 0, .3, 1],
      ['translate', 0, 1, 0, -.6, 0],
      ['wobbling', 0, 1, 2.5, 1],
    ],
    'noiseWaves': [
      ['alphaNoise', 0, 1, -1, 0, 1.5, 1.5],
      ['waves', 0, 1, 2, .5, 2],
      ['translate', 0, 1, .4, 0, 0],
    ],
  },
  'mouseEffects': {
    'follow': [['follow', 'word', 30, .5]],
    'bubble': [['bubble', 200, .4]],
    'alpha': [['alpha', 150, 1, 1]],
    'wobbling': [['wobbling', 150, 1, 1.5, 2]],
    'waves': [['waves', 150, 1, 1, 1, 1]],
    'color': [['color', 150, 1, '#08ff']],
    'noise': [['noise', 150, 1, 1, 1]],
    'noiseColor': [['colorNoise', 150, .5, '#f00f', 1, 0, 1, .5]],
    'noiseAlpha': [['alphaNoise', 150, .5, 1, 1, 0, 1, .5]],
    'glitch': [['glitch', 150, 1, 1, 1, 90]],
    'colorGlitch': [['colorGlitch', 150, 1, '#f00b', '#0afb', 1, 1]],
    'translate': [['translate', 150, 1, 0, -.2, 0]],
    'scale': [['scale', 150, 1, 0.2, 0.2]],
    'rotate': [['rotate', 150, 1, 0, 0, 30]],
  },
  'scrollEffects': {
    'wobbling': [['wobbling', 40, 1, 1.5, 2]],
    'waves': [['waves', 40, 1, 1, 1.5, 5]],
    'alpha': [['alpha', 40, 1, 1]],
    'color': [['color', 40, 1, '#08ff']],
    'noise': [['noise', 40, 1, 1, 1]],
    'noiseColor': [['colorNoise', 100, 1, '#f00f', 0, 1, .5, 1]],
    'noiseAlpha': [['alphaNoise', 100, 1, 1, 0, 1, 1, 1]],
    'glitch': [['glitch', 40, .5, 1, 1, 90]],
    'colorGlitch': [['colorGlitch', 40, .5, '#f00b', '#0afb', 1, 1]],
    'translate': [['translate', 40, .5, 0, .4, 0]],
    'scale': [['scale', 40, .5, 0.2, 0.2]],
    'rotate': [['rotate', 40, .5, 0, 0, 30]],
  },
};
defaultSimplePresets['disappearEffects'] =
  defaultSimplePresets['appearEffects'];

/**
 * Default presets
 * @internal
 */
const defaultPresets = {};
defaultPresets['effects'] = {
  'wobbling': (strength = 1, speed = 1) => [[
    'fdistortion', 5, 1, 0, 'canvas', 0, 0, 1,
    200, 300, 2 * strength, 4 * strength, 1, 1, 2.5 / speed, 2 / speed,
  ]],
  'waves': (strength, length, speed) => [[
    'fdistortion', 5, 1, 0, 'canvas', 0, 0, 1,
    0, 300 * length, 0, 10 * strength, 0, 0, 0, 2 / speed,
  ]],
  'verticalWaves': (strength, length, speed, offset) => [[
    'fdistortion', 5, 1, 0, 'canvas', 0, 0, 1,
    300 * length, 0, 10 * strength, 0, offset, 0, 2 / speed, 0,
  ]],
  'noise': (strength, speed) => [[
    'fnoiseDistortion', 5, 1, 0, 'canvas', 0, 0, 1,
    1.5 * strength, 2 * strength, 10 * speed, 20 * speed, 10, 10,
  ]],
  'colorNoise': (time, duration, size, offset, color, strength, moveX, moveY,
      scaleX, scaleY) => [[
    'fcolorNoise', time, duration, .5, size, offset, 0, strength,
    color, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'alphaNoise': (time, duration, size, offset, alpha, strength, moveX, moveY,
      scaleX, scaleY) => [[
    'falphaNoise', time, duration, .5, size, offset, 0, strength,
    alpha, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'alpha': (time, duration, size, offset, strength) => [[
    'falpha', time, duration, .5, size, offset, 0, 1, strength,
  ]],
  'color': (time, duration, size, offset, strength, color) => [[
    'fcolor', time, duration, .5, size, offset, 0, strength, color,
  ]],
  'glitch': (time, duration, size, offset, strength, speed, angle) => [[
    'fglitch', time, duration, .5, size, offset, 0, 1,
    20 * strength, 1, 30, angle, 0.1 / speed, 0.4,
  ]],
  'smoothGlitch': (time, duration, size, offset, strength, speed, angle) => [[
    'fglitch', time, duration, .5, size, 0.98, 0, 1,
    10 * strength, 0, 15, angle, 0.1 / speed, 1,
  ]],
  'colorGlitch': (time, duration, size, offset, c1, c2, strength, speed) => [[
    'cglitch', time, duration, .5, size, offset, 0, 1,
    c1, c2, 8 * strength, 90, true, 0.5, 0.05 / speed, 2, 50,
  ]],
  'colors': (c1, c2, c3, c4, strength, speed) => [[
    'cglitch', 1, 1, 0, 'glyph', 0, 0, 1,
    c1, c2, 8 * strength, 45, false, 0, 0.5 / speed, .7, 8,
  ], [
    'cglitch', 1, 1, 0, 'glyph', 0, 0, 1,
    c3, c4, 8 * strength, 135, false, 0, 0.5 / speed, .7, 8,
  ]],
  'flame': (time, duration, size, offset, c, length, strength, speed, a) => [[
    'cglitch', time, duration, .5, size, offset, 0, 1, c, '#0000',
    10 * strength, a, false, 0.7, 0.1 / speed, .8, 6 * length,
  ]],
  'translate': (time, duration, size, offset, x, y, z) => [[
    'vtranslate', time, duration, .5, size, offset, 0, 1,
    100 * x, 100 * y, 100 * z,
  ]],
  'scale': (time, duration, size, offset, x, y) => [[
    'vscale', time, duration, .5, size, offset, 0, 1, x, y,
  ]],
  'rotate': (time, duration, size, offset, x, y, z) => [[
    'vrotate', time, duration, .5, size, offset, 0, 1, x, y, z,
  ]],
};

defaultPresets['staticEffects'] = {
  'distortion': (s, lenX, lenY, ampX, ampY) => [[
    'fdistortion', 1, 100 * lenX * s, 100 * lenY * s, 1.5 * ampX * s,
    1.5 * ampY * s, 1, 1, 1, 1,
  ]],
  'waves': (strength, length) => [[
    'fdistortion', 1, 0, 10 * length, 0, 1 * strength, 0, 0, 0, 0,
  ]],
  'alphaNoise': (strength, alpha, scaleX, scaleY) => [[
    'falphaNoise', strength, alpha, 0, 0, 2 * scaleX, 2 * scaleY,
  ]],
  'noise': (strength) => [[
    'fnoiseDistortion', strength, 3 * strength, 4 * strength, 10, 10, 10, 10,
  ]],
  'strokeNoise': (strength) => [[
    'fnoiseDistortion', strength, 3 * strength, 4 * strength, 10, 10, .1, .1,
  ]],
  'glitch': (strength, length, angle) => [[
    'fglitch', strength, 10 * strength, 1, 5 * length, angle, 0.1, 0.4,
  ]],
};

defaultPresets['mouseEffects'] = {
  'bubble': (length, strength) => [
    ['fbubble', length, strength],
  ],
  'follow': (size, length, strength) => [
    ['vfollow', size, length, length, strength, strength],
  ],
  'wobbling': (mlength, mstrength, strength, speed) => [[
    'fdistortion', mlength, mstrength, 200, 300,
    4 * strength, 8 * strength, 1, 1, 2.5 / speed, 2 / speed,
  ]],
  'waves': (mlength, mstrength, strength, length, speed) => [[
    'fdistortion', mlength, mstrength,
    0, 300 * length, 0, 20 * strength, 0, 0, 0, 2 / speed,
  ]],
  'glitch': (mlength, mstrength, strength, speed, angle) => [[
    'fglitch', mlength, mstrength,
    20 * strength, 1, 30, angle, 0.1 / speed, 0.4,
  ]],
  'colorGlitch': (mlength, mstrength, c1, c2, strength, speed) => [[
    'cglitch', mlength, mstrength,
    c1, c2, 8 * strength, 90, true, 0.5, 0.05 / speed, 2, 50,
  ]],
  'alpha': (mlength, mstrength, strength) => [
    ['falpha', mlength, mstrength, strength],
  ],
  'color': (mlength, mstrength, color) => [
    ['fcolor', mlength, mstrength, color],
  ],
  'noise': (mlength, mstrength, strength, speed) => [[
    'fnoiseDistortion', mlength, mstrength,
    3 * strength, 4 * strength, 10 * speed, 20 * speed, 10, 10,
  ]],
  'colorNoise': (mlength, mstrength, color, moveX, moveY, scaleX, scaleY) => [[
    'fcolorNoise', mlength, mstrength,
    color, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'alphaNoise': (mlength, mstrength, alpha, moveX, moveY, scaleX, scaleY) => [[
    'falphaNoise', mlength, mstrength,
    alpha, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'translate': (mlength, mstrength, x, y, z) => [
    ['vtranslate', mlength, mstrength, 100 * x, 100 * y, 100 * z],
  ],
  'scale': (mlength, mstrength, x, y) => [
    ['vscale', mlength, mstrength, x, y],
  ],
  'rotate': (mlength, mstrength, x, y, z) => [
    ['vrotate', mlength, mstrength, x, y, z],
  ],
};

defaultPresets['scrollEffects'] = {
  'wobbling': (sdistance, sfading, strength, speed) => [[
    'fdistortion', sdistance, sfading, 200, 300,
    4 * strength, 8 * strength, 1, 1, 2.5 / speed, 2 / speed,
  ]],
  'waves': (sdistance, sfading, strength, length, speed) => [[
    'fdistortion', sdistance, sfading,
    0, 300 * length, 0, 20 * strength, 0, 0, 0, 2 / speed,
  ]],
  'glitch': (sdistance, sfading, strength, speed, angle) => [[
    'fglitch', sdistance, sfading,
    20 * strength, 1, 30, angle, 0.1 / speed, 0.4,
  ]],
  'colorGlitch': (sdistance, sfading, c1, c2, strength, speed) => [[
    'cglitch', sdistance, sfading,
    c1, c2, 8 * strength, 90, true, 0.5, 0.05 / speed, 2, 50,
  ]],
  'alpha': (sdistance, sfading, strength) => [
    ['falpha', sdistance, sfading, strength],
  ],
  'color': (sdistance, sfading, color) => [
    ['fcolor', sdistance, sfading, color],
  ],
  'noise': (sdistance, sfading, strength, speed) => [[
    'fnoiseDistortion', sdistance, sfading,
    3 * strength, 4 * strength, 10 * speed, 20 * speed, 10, 10,
  ]],
  'colorNoise': (sdistance, sfading, color, moveX, moveY, scaleX, scaleY) => [[
    'fcolorNoise', sdistance, sfading,
    color, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'alphaNoise': (sdistance, sfading, alpha, moveX, moveY, scaleX, scaleY) => [[
    'falphaNoise', sdistance, sfading,
    alpha, -20 * moveX, -20 * moveY, 10 * scaleX, 10 * scaleY,
  ]],
  'translate': (sdistance, sfading, x, y, z) => [
    ['vtranslate', sdistance, sfading, 100 * x, 100 * y, 100 * z],
  ],
  'scale': (sdistance, sfading, x, y) => [
    ['vscale', sdistance, sfading, x, y],
  ],
  'rotate': (sdistance, sfading, x, y, z) => [
    ['vrotate', sdistance, sfading, x, y, z],
  ],
};

defaultPresets['appearEffects'] = {
  'wobbling': (start, end, strength, speed) => [[
    'fdistortion', start, end, 200, 300,
    6 * strength, 12 * strength, 1, 1, 1.25 / speed, 1 / speed,
  ]],
  'waves': (start, end, strength, length, speed) => [[
    'fdistortion', start, end, 0, 300 * length, 0, 20 * strength,
    0, 0, 0, 2 / speed,
  ]],
  'alpha': (start, end, strength) => [['falpha', start, end, strength]],
  'color': (start, end, color) => [['fcolor', start, end, color]],
  'noise': (start, end, strength, speed) => [[
    'fnoiseDistortion', start, end,
    3 * strength, 4 * strength, 10 * speed, 20 * speed, 10, 10,
  ]],
  'alphaNoise': (start, end, speedX, speedY, scaleX, scaleY) => [
    ['falphaNoise', start, end, 1, 100 * speedX, 100 * speedY,
      10 * scaleX, 10 * scaleY],
  ],
  'colorNoise': (start, end, color, speedX, speedY, scaleX, scaleY) => [
    ['fcolorNoise', start, end, color, 100 * speedX, 100 * speedY,
      10 * scaleX, 10 * scaleY],
  ],
  'glitch': (start, end, strength, speed, angle) => [[
    'fglitch', start, end, 20 * strength, 1, 30, angle, 0.1 / speed, 0.4,
  ]],
  'colorGlitch': (start, end, c1, c2, strength, speed) => [[
    'cglitch', start, end,
    c1, c2, 8 * strength, 90, true, 0.5, 0.05 / speed, 2, 50,
  ]],
  'translate': (start, end, x, y, z) => [
    ['vtranslate', start, end, 100 * x, 100 * y, 100 * z],
  ],
  'scale': (start, end, x, y) => [
    ['vscale', start, end, x, y],
  ],
  'rotate': (start, end, x, y, z) => [
    ['vrotate', start, end, x, y, z],
  ],
};

defaultPresets['disappearEffects'] = {
  ...defaultPresets['appearEffects'],
};

defaultPresets['decors'] = {
  'linearGrad': (operation, angle, opacity, position) => [
    ['dlinearGrad', operation, angle, [[0, 0], [position, opacity]]],
  ],
  'fill': (operation, opacity) => [
    ['dpattern', operation, 0, 1, 1, [['fill', 0, opacity]], []],
  ],
  'lines': (operation, angle, distance, width, opacity) => [
    ['dpattern', operation, 0, 1, 1,
      [['lines', 0, opacity, distance, width, angle]], []],
  ],
  'noiseLines': (operation, angle, distance, width, opacity, strength) => [
    ['dpattern', operation, 0, 1, 1,
      [['lines', 0, opacity, distance, width, angle]],
      [['fnoiseDistortion', 1, strength, strength, 0, 20,
        4 + strength / 2, 4 + strength / 2]]],
  ],
  'zigzag': (operation, angle, distance, width, opacity) => [
    ['dpattern', operation, 0, 1, 1,
      [['zigzag', 0, opacity, distance, width, 5, 5, angle]], []],
  ],
  'circles': (operation, fill, distance, minRad, maxRad, random, opacity) => [
    ['dpattern', operation, 0, 1, 1,
      [['circles', 0, opacity, fill, 1, minRad, maxRad, distance,
        distance * 2, random, random]], []],
  ],
};

defaultPresets['backgrounds'] = {
  'linearGrad': (operation, opacity, size, angle, c1, c2, pos) => [
    ['dlinearGrad', operation, opacity, size, angle, [[0, c1], [pos, c2]]],
  ],
  'radialGrad': (operation, opacity, size, type, c1, c2) => [
    ['dradialGrad', operation, opacity, size, false, 'center', type,
      0.5, 0.5, 1, [[.1, c1], [.9, c2]]],
  ],
  'lines': (operation, opacity, c1, c2, distance, width, angle, strength) => [
    ['dpattern', operation, opacity, 'word', 0, 0, 1, [
      ['lines', 0, c1, 3, 5, angle],
      ['lines', 0, c2, distance, width, angle]], [
      ['fdistortion', 5, 1, 0, 'glyph', 0, 0, 1, 47, 85,
        strength, strength, 0, 0, 2, 4]],
    ],
  ],
  'noiseLines': (operation, color, distance, width, angle, strength) => [
    ['dpattern', operation, 1, 'wrap', 0, 0, 1,
      [['lines', 0, color, distance, width, angle]],
      [['fnoiseDistortion', 5, 1, 0, 'glyph', 0, 0, 1,
        strength, strength, 0, 20, 5 + strength / 5, 5 + strength / 5]]],
  ],
  'dots': (operation, color, maxRadius, strength, speed) => [
    ['dpattern', operation, 1, 'canvas', 0, 0, 1,
      [['circles', 0, color, 1, 1, 0.5, 4 * maxRadius, 4, 4, 0, 0]],
      [['fdistortion', 5, 1, 0, 'glyph', 0, 0, 1, 200, 200, 5 * strength,
        5 * strength, 1, 0, 1 / speed, .5 / speed]]],
  ],
};


/**
 * 1.2.3. Non inherited options
 * Options that should ignore parent values and
 * inheirt values from defaultOptions
 * @internal
 */
const nonInheritedOptions = [
  'reserved',
  'appearReverse',
  'appearRandom',
  'appearReset',
  'appearStartDelay',
  'appearFunctionReset',
  'disappearReset',
  'disappearRandom',
  'disappearReverse',
  'change',
  'changeAppearReset',
  'changeAppearRandom',
  'changeAppearReverse',
  'appeared',
  'changed',
  'changeStarted',
  'changeAppeared',
  'changeDisappeared',
];

/**
 * 1.2.4. Options list to refresh
 * List of events that should be triggered if options from the list changed
 * @internal
 */
const optionsRefresh = {
  resizeHard: [
    'split',
    'roundPosition',
    'disappear',
    'appearAsync',
    'changeAppearAsync',
    'disappearAsync',
  ],
  resizeSoft: [
    'fov',
    'canvasPadding',
    'scaleFontSize',
    'effects',
    'appear',
    'mouseEffects',
    'scrollEffects',
    'appearEffects',
    'disappearEffects',
    'stroke',
    'strokeWidth',
    'strokeBlur',
    'appearSpeed',
    'appearCount',
    'appearDelay',
    'appearReverse',
    'appearRandom',
    'changeAppearSpeed',
    'changeAppearCount',
    'changeAppearDelay',
    'changeAppearReverse',
    'changeAppearRandom',
    'disappearSpeed',
    'disappearCount',
    'disappearDelay',
    'disappearReverse',
    'disappearRandom',
    'stroke',
    'strokeWidth',
    'strokeBlur',
    'decors',
    'decorsStroke',
    'decorsStrokeWidth',
    'decorsStrokeBlur',
    'staticEffects',
  ],
  shadersHard: [
    'scaleFontSize',
    'effects',
    'appearFunction',
    'appearPartFunction',
    'appearEffects',
    'mouseEffects',
    'scrollEffects',
    'disappearEffects',
    'backgrounds',
  ],
  shadersSoft: [
    'fov',
  ],
  textureHard: [
    'scaleFontSize',
    'decors',
    'staticEffects',
  ],
  textureSoft: [
    'scaleFontSize',
    'decors',
    'staticEffects',
    'stroke',
    'strokeWidth',
    'strokeBlur',
    'decorsStroke',
    'decorsStrokeWidth',
    'decorsStrokeBlur',
  ],
};


/**
 * WebGL GLSL block sizes: canvas, glyph, word, line, wrap
 * @internal
 */
const glUVSizes = [
  'vCP.xy',
  'vUP',
  '(vCP.zw-uWC.xy)/uWC.zw',
  '(vCP.zw-uLC.xy)/uLC.zw',
  '(vCP.zw-uWRC.xy)/uWRC.zw',
];
const glBlockIDs = [
  '1.0',
  'vAP.x',
  'uID.x',
  'uID.y',
  'uID.z',
];
const glBlockSizes = [
  'vU.zw',
  'vAP.zw',
  'uWC.zw',
  'uLC.zw',
  'uWRC.zw',
];
const glBlockCoords = [
  'vec4(0.0,0.0,vU.zw)',
  'vec4(vVP.zw-vAP.zw/vec2(2.),vAP.zw)',
  'uWC',
  'uLC',
  'uWRC',
];

/**
 * 1.3. Global functions
 * 1.3.1. General functions
 */

/**
 * Number in range
 * @param {number} value
 * @param {number} start - range start
 * @param {number} end - range end
 * @return {number} - value inside range
 */
const toRange = (value, start, end) => {
  const length = end - start;
  value = value % length;
  return value < start ? value + length : value;
};

/**
 * Return parent value if value is 'inherit'
 * @param {any} value
 * @param {object} parent
 * @return {object}
 */
const inherit = (value, parent) => {
  return value === 'inherit' ? parent : value;
};

/**
 * Get scroll parent
 * @param {HTMLElement} parent
 * @return {HTMLElement|globalThis}
 */
const getScrollParent = (parent) => {
  return (parent === doc.documentElement) ? wnd : parent;
};

/**
 * Add the values of one array to the second
 * @param {array} arr1
 * @param {array} arr2
 */
const arrayAdd = (arr1, arr2) => {
  arr2.forEach((v, i) => arr1[i] += v);
};

/**
 * Remove item from array
 * @param {array} arr
 * @param {any} item
 */
const arrayRemove = (arr, item) => {
  const index = typeof item === 'function' ?
    arr.findIndex(item) : arr.indexOf(item);
  if (index >= 0) {
    arr.splice(index, 1);
  }
};

/**
 * Fill array four times
 * @param {array} arr
 * @param {any} a
 * @param {any} b
 * @param {any} c
 * @param {any} d
 */
const arrayFill = (arr, a, b, c, d) => {
  arr.push(a, b, c, d, a, b, c, d, a, b, c, d, a, b, c, d);
};

/**
 * Load images then callback
 * @param {array} list
 * @param {function} callback
 */
const loadImages = (list, callback) => {
  let count = 0;

  list.forEach((decor) => {
    if (!decor.url || decor.image) {
      return;
    }
    if (decor.url.width) {
      decor.image = decor.url;
    } else {
      count++;

      const image = new Image();
      image.onload = () => {
        decor.image = image;
        count--;
        count === 0 && callback(true);
      };
      image.onerror = () => {
        console.warn(`WGLTR. Image '${decor.url}' not found!`);
        count--;
        count === 0 && callback(true);
      };

      image.src = decor.url;
    }
  });

  if (count === 0) {
    callback(false);
  }
};

/**
 * 1.3.2. WebGL functions
 */

/**
 * Number to GLSL string
 * @param {number} value
 * @return {string}
 */
const glVal = (value) => {
  return (value.toFixed ? value.toFixed(4) : value) + '';
};

/**
 * Clamp GLSL value
 * @param {string} value
 * @param {number} min
 * @param {number} max
 * @return {string}
 */
const glClamp = (value, min = 0, max = 1) => {
  return `clamp(${value},${glVal(min)},${glVal(max)})`;
};

/**
 * Fit the size to the power of two
 * @param {number} value
 * @return {number}
 */
const glSize = (value) => pow(2, ceil(log2(value)));

/**
 * Angle to GLSL vector
 * @param {number} angle - angle in degrees
 * @return {string}
 */
const glAngle = (angle) => {
  angle = angle * PI / 180;
  const rx = sin(angle);
  const ry = cos(angle);
  const length = abs(rx) + abs(ry);

  return `(${ry < 0 ? '(1.-vUP.y)' : 'vUP.y'}*${glVal(abs(ry) / length)})+` +
    `(${rx < 0 ? '(1.-vUP.x)' : 'vUP.x'}*${glVal(abs(rx) / length)})`;
};

/**
 * Appear time to GLSL
 * @param {string} current
 * @param {number} start
 * @param {number} end
 * @return {string}
 */
const glTime = (current, start, end) => {
  if (start === 0 && end === 1) {
    return current;
  }
  if (start === 1 && end === 0) {
    return `(1.-${current})`;
  }
  return glClamp(`(${current}-${glVal(start)})/${glVal(end - start)}`);
};

/**
 * GLSL Discard if value is true
 * @param {string} value
 * @return {string}
 */
const glDiscard = (value) => `if(${value}){discard;return;}`;

/**
 * Get size index for fragment shader sizes
 * @param {string} size
 * @return {number}
 */
const glGetSizeIndex = (size) => {
  return max(0, ['canvas', 'glyph', 'word', 'line', 'wrap'].indexOf(size));
};

/**
 * GLSL Condition if variable is in range of integer values
 * @param {string} content
 * @param {string} variable
 * @param {[]} indices
 * @return {string}
 */
const glCondIndex = (content, variable, indices) => {
  // Combine indices if it is a series
  let prev = -2;
  indices = indices.reduce((result, index) => {
    if (prev + 1 === index) {
      result[result.length - 1][1] = index;
    } else {
      result.push([index, index]);
    }
    prev = index;
    return result;
  }, []);

  const condition = indices.reduce((result, [start, end], i) => {
    if (i !== 0) {
      result += '||';
    }
    return result + `(${variable}>${(start - .5).toFixed(1)}&&` +
      `${variable}<${(end + .5).toFixed(1)})`;
  }, '');
  return `if(${condition}){${content}}`;
};

/**
 * GLSL Gradient stops calculation as UV coordinates
 * @param {string} name - GLSL variable name
 * @param {array} stops
 * @return {string}
 */
const glStops = (name, stops) => {
  let result = `(${name}<${glVal(stops[0][0])})?${glVal(stops[0][1])}:`;

  for (let i = 0; i < stops.length - 1; i++) {
    const [[startPos, startVal], [endPos, endVal]] = [stops[i], stops[i+1]];
    const diff = `clamp((${glVal(endPos)}-${glVal(startPos)}),.00001,1.)`;

    result +=
      `(${name}<${glVal(endPos)})?` +
      `${glVal(endVal - startVal)}*` +
      `((${name}-${glVal(startPos)})*(1./(${diff})))+` +
      `${glVal(startVal)}:`;
  }
  return result + glVal(stops[stops.length-1][1]);
};

/**
 * Get Effects list with scale
 * @param {object|array} options
 * @param {string} [name] - effects category
 * @return {object} - effects and scale difference
 */
const getGLEffects = (options, name) => {
  if (!name) {
    return options.map((e) => ({args: e, scale: 1}));
  }

  const list = options[name];

  const size = options.node ? options.node.cssSize : 106;
  const presetScale = size / 106;
  const scale = size / (options.scaleFontSize || size);
  const result = [];

  list.forEach((effect) => {
    // Part preset
    const preset = defaultPresets[getPresetCategory(name)][effect[0]];
    if (preset) {
      if (preset.length && preset.length !== (effect.length - 1)) {
        console.warn(`WGLTR. Expected ${preset.length} arguments,` +
          ` found ${effect.length - 1}.` +
          ` Preset ${effect[0]} in ${name}.`);
      }
      result.push(...preset(...effect.slice(1)).map((effect) => {
        return {
          args: effect,
          scale: presetScale,
        };
      }));
    } else {
      result.push({
        args: effect,
        scale: scale,
      });
    }
  });
  return result;
};

/**
 * Effects category is appear
 * @param {string} category
 * @return {boolean}
 */
const isAppearEffects = (category) => {
  return category === 'appearEffects' ||
    category === 'disappearEffects' ||
    category === 'changeAppearEffects';
};

/**
 * Get preset category name
 * @param {string} name
 * @return {string}
 */
const getPresetCategory = (name) => {
  if (name === 'changeAppearEffects') {
    return 'appearEffects';
  }
  if (name === 'changeDisappearEffects') {
    return 'disappearEffects';
  }
  return name;
};

/**
 * Get Effect arguments array
 * @param {object} info
 * @param {string} category
 * @param {array} args
 * @return {array}
 */
const getEffectArgs = (info, category, args) => {
  const base = info.base && info.base[category];
  let result = [[], args];
  if (base !== undefined) {
    result = [args.slice(0, base), args.slice(base)];
  }
  return result;
};

/**
 * Get Effect info
 * @param {string} name - effect name
 * @param {string} type - category type
 * @return {object} - effect info
 */
const getEffectInfo = (name, type) => {
  if (!glEffects[name]) {
    console.warn(`WGLTR. Effect '${name}' in '${type}' not found!`);
    return;
  }
  [
    ['staticEffects', 'effects'],
    ['disappearEffects', 'appearEffects'],
    ['changeAppearEffects', 'appearEffects'],
    ['appearEffects', 'effects'],
    ['decorsEffects', 'effects'],
    ['scrollEffects', 'effects'],
    ['mouseEffects', 'effects'],
  ].forEach(([a, b]) => {
    if (type === a && !glEffects[name][type]) {
      type = b;
    }
  });
  return glEffects[name][type];
};


/**
 * Turn array of effects to GLSL effects string
 * @param {string} category
 * @param {string} type
 * @param {object} effects
 * @param {number} startIndex
 * @return {object}
 */
const glEffectsToGLSL = (category, type, effects, startIndex = 0) => {
  const req = [];
  let glsl = '';

  effects.forEach((effect, index) => {
    const [name, ...args] = effect.args;
    const info = getEffectInfo(name, category);
    if (!info) {
      return;
    }

    const fnc = info[type];
    if (fnc) {
      const infoArgs = getEffectArgs(info, category, args);
      const frags = info.args(category, ...infoArgs, effect.scale);
      frags[1] = frags[1].map((v) => glVal(v));
      req.push(...info.req);
      glsl += fnc(category, ...frags, ...infoArgs, startIndex + index);
    }
  });
  return {req, glsl};
};

/**
 * Decorations to GLSL
 * @param {object} options
 * @param {number} rows
 * @param {number} cols
 * @param {array} req
 * @return {string}
 */
const glDecorations = (options, rows, cols, req) => {
  let result = '';
  const glRows = glSize(rows);
  const glCols = glSize(cols);

  options.forEach((o) => {
    let glsl = '';

    let index = 0;
    const effects = getGLEffects(o, 'decors');

    effects.forEach((decor) => {
      const [name] = decor.args;

      if (name === 'dlinearGrad') {
        const [, operation, angle, stops] = decor.args;

        if (stops.length < 1) {
          return;
        }

        glsl += `gp=${glAngle(angle)};`;
        glsl += `tmp=${glStops('gp', stops)};`;

        switch (operation) {
          case 'decorsIn':
            glsl += `r=${glClamp('r*tmp')};`;
            break;
          case 'decorsOut':
            glsl += `ro=${glClamp('ro+(1.-ro)*tmp')};`;
            break;
          case 'out':
            glsl += `rt=${glClamp('rt*(1.-tmp)')};`;
            break;
          case 'in':
          default:
            glsl += `r=${glClamp('r+tmp')};`;
            break;
        }
      }

      if (name === 'dpattern' || name === 'dimage') {
        let [, operation, invert, opacity,,, effects] = decor.args;
        if (name === 'dimage') {
          [,, operation, invert, opacity,,,,,,,, effects] = decor.args;
        }
        const code = glEffectsToGLSL(
            'decorsEffects', 'frag', getGLEffects(effects));
        const x = index % rows;
        const y = floor(index / rows);

        const color = (invert ? '(1.-tD.w)*' : 'tD.w*') + glVal(opacity);

        req.push(...code.req);
        index++;

        glsl +=
          'uv=vDUV.xy;' +
          (code.glsl || '') +
          `tD=texture2D(uDecors,uv/` +
          `vec2(${glVal(glRows)},${glVal(glCols)})+` +
          `vec2(${glVal(x/glRows)},${glVal(y/glCols)}));` +
          (operation === 'out' ?
            `ro=${glClamp('ro-' + color)};` :
            `r=${glClamp('r+' + color)};`);
      }
    });

    result += glCondIndex(glsl, 'uE.x', [o.uniqueID]);
  });

  return result;
};

const extendByEffects = (arr, type, effects) => {
  effects.forEach((effect) => {
    const [name, ...args] = effect.args;
    const info = getEffectInfo(name, type);
    if (info && info.paddingFrag) {
      const infoArgs = getEffectArgs(info, type, args);
      const [x, y, w, h] = info.paddingFrag(type, ...infoArgs, effect.scale);
      arrayAdd(arr, [x * dpr, y * dpr, w * dpr, h * dpr]);
    }
  });
};


/**
 * 1.3.3. DOM functions
 */

/**
 * Proxy for add event listener
 * @param {HTMLElement} el
 * @param {string} ev
 * @param {function} fn
 * @return {void}
 */
const on = (el, ev, fn) => el.addEventListener(ev, fn, {passive: true});

/**
 * Proxy for remove event listener
 * @param {HTMLElement} el
 * @param {string} e
 * @param {function} fn
 * @return {void}
 */
const off = (el, e, fn) => el.removeEventListener(e, fn, {passive: true});

/**
 * Apply CSS to element
 * @param {HTMLElement} el
 * @param {object} styles
 */
const css = (el, styles) => {
  Object.keys(styles).forEach((key) => {
    el.style.setProperty(key, styles[key], 'important');
  });
};

/**
 * Node that don't contain any text inside
 * @param {Node} node
 * @return {boolean}
 */
const isInnerNode = (node) => {
  return node.nodeType === 1 &&
      ['BR', 'WBR', 'HR', 'IMG'].every((v) => v !== node.nodeName);
};

/**
 * Get element bounding client rect
 * @param {HTMLElement} el
 * @return {DOMRect}
 */
const rect = (el) => el.getBoundingClientRect();

/**
 * Get WebGL Context
 * @param {HTMLCanvasElement} canvas
 * @param {object} options
 * @return {WebGLRenderingContext|undefined}
 */
const getWebGLContext = (canvas, options = {}) => {
  let gl;
  const types = ['webgl2', 'webgl', 'experimental-webgl'];

  for (const type of types) {
    if ((gl = canvas.getContext(type, options))) {
      return gl;
    }
  }
};


/**
 * 1.3.4. Math functions
 */

/**
 * Get element bounding client rect with scroll
 * @param {DOMRect} rect
 * @return {DOMRect}
 */
const scrollRect = (rect) => {
  return new Rect(
      rect.x + wnd.scrollX,
      rect.y + wnd.scrollY,
      rect.width,
      rect.height,
  );
};

/**
 * Rect resized to screen DPR
 * @param {DOMRect} rect
 * @return {DOMRect}
 */
const dprRect = (rect) => {
  return new Rect(
      rect.x * dpr,
      rect.y * dpr,
      rect.width * dpr,
      rect.height * dpr,
  );
};

/**
 * Rect with maximum values
 * @return {DOMRect}
 */
const maxRect = () => {
  return new Rect(Infinity, Infinity, -Infinity, -Infinity);
};

/**
 * Rect with minimum values
 * @return {DOMRect}
 */
const minRect = () => {
  return new Rect(-Infinity, -Infinity, -Infinity, -Infinity);
};


/**
 * Expand rect
 * @param {DOMRect} rect1
 * @param {DOMRect} rect2
 */
const expandRect = (rect1, rect2) => {
  if (rect1.x === Infinity) {
    rect1.x = rect2.x;
    rect1.y = rect2.y;
    rect1.width = rect2.width;
    rect1.height = rect2.height;
  }

  let diff = max(0, rect1.x - rect2.x);
  rect1.x -= diff;
  rect1.width += diff;
  rect1.width += max(0, rect2.right - rect1.right);

  diff = max(0, rect1.y - rect2.y);
  rect1.y -= diff;
  rect1.height += diff;
  rect1.height += max(0, rect2.bottom - rect1.bottom);
};

/**
 * Extend rect size
 * @param {DOMRect} rect1
 * @param {DOMRect} rect2
 */
const expandRectSize = (rect1, rect2) => {
  rect1.width = max(rect1.width, rect2.width);
  rect1.height = max(rect1.height, rect2.height);
};

/**
 * Max rects values
 * @param {DOMRect} rect1
 * @param {DOMRect} rect2
 */
const maxRectValues = (rect1, rect2) => {
  rect1.x = max(rect1.x, rect2.x);
  rect1.y = max(rect1.y, rect2.y);
  rect1.width = max(rect1.width, rect2.width);
  rect1.height = max(rect1.height, rect2.height);
};

/**
 * Rect to array
 * @param {DOMRect} rect
 * @return {[number, number, number, number]}
 */
const rectToArray = (rect) => {
  return [rect.x, rect.y, rect.width, rect.height];
};

/**
 * Remove empty rects from list
 * @param {DOMRectList|DOMRect[]}rects
 * @return {DOMRect[]}
 */
const trimRects = (rects) => {
  const result = [];
  for (let i = 0; i < rects.length; i++) {
    const rect = rects[i];
    if (rect.width > .1 && rect.height > .1) {
      result.push(rect);
    }
  }
  return result;
};

/**
 * Random from min to max
 * @param {number} min
 * @param {number} max
 * @return {number}
 */
const randClamp = (min, max) => {
  return random() * (max - min) + min;
};

/**
 * Intersection point of two perpendicular vectors
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @param {number} angle
 * @return {[number, number]}
 */
const getIntersectionPoint = (x1, y1, x2, y2, angle) => {
  const mx = x1 + (x2 - x1) / 2;
  const my = y1 + (y2 - y1) / 2;
  const halfLength = sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2) / 2;
  const pointAngle = (PI - atan2(y2 - y1, x2 - x1)) + PI + angle * 2;

  return [
    mx + halfLength * cos(pointAngle),
    my + halfLength * sin(pointAngle),
  ];
};

/**
 * Get outer rect for gradient
 * @param {number} angle
 * @param {number} x
 * @param {number} y
 * @param {number} width
 * @param {number} height
 * @return {number[]}
 */
const getOuterGradRect = (angle, x, y, width, height) => {
  const sx = x - width / 2;
  const sy = y - height / 2;
  angle = toRange(angle, 0, 360);

  let nearest = [sx + width, sy];
  if (angle > 270) {
    nearest = [sx, sy];
  } else if (angle > 180) {
    nearest = [sx, sy + height];
  } else if (angle > 90) {
    nearest = [sx + width, sy + height];
  }

  const [ix, iy] = getIntersectionPoint(
      x, y, nearest[0], nearest[1], (angle - 90) / 180 * PI);

  return [ix, iy, x - (ix - x), y - (iy - y)];
};

/**
 * Perspective matrix
 * @param {number} fov
 * @param {number} aspect
 * @param {number} near
 * @param {number} far
 * @return {number[]}
 */
const perspective = (fov, aspect, near, far) => {
  const f = 1.0 / tan(fov / 2);
  const nf = 1 / (near - far);
  return [
    f / aspect, 0, 0, 0,
    0, f, 0, 0,
    0, 0, (far + near) * nf, -1,
    0, 0, 2 * far * near * nf, 0,
  ];
};


/**
 * 1.3.5. Parse functions
 */

/**
 * Split string with nesting
 * @param {string} str
 * @param {string} divider
 * @return {string[]}
 */
const splitWithNesting = (str, divider = ',') => {
  const regex = /[[\]]/gm;
  const nested = [];
  const brackets = [];
  let result = str;
  let bracket;
  let count = 0;

  while ((bracket = regex.exec(str))) {
    (count === 0) && brackets.push(bracket.index);
    count += bracket[0] === '[' ? 1 : -1;
    (count === 0) && brackets.push(bracket.index + 1);
  }

  for (let i = 0; i < brackets.length; i += 2) {
    const [start, end] = [brackets[i], brackets[i+1]];
    const piece = str.substring(start, end);
    result = result.replace(piece, nested.length + '$_$');
    nested.push(piece);
  }

  result.replace(/'[^']+?'/g, (item) => {
    nested.push(item);
    return nested.length + '$_$';
  });

  if (!result) {
    return [];
  }
  return result.split(divider).map((item) => {
    return item.replace(/\d+?\$_\$/g, (index) => nested[parseInt(index)]);
  });
};

/**
 * Parse rgb or rgba color
 * @param {string} str
 * @return {number[]}
 */
const parseColor = (str) => {
  const result = str
      .match(/\((.+?)\)/)[1]
      .split(',')
      .map((v) => parseFloat(v));

  for (let i = 0; i < 3; i++) {
    result[i] = result[i] / 255;
  }
  if (result.length === 3) {
    result.push(1);
  }
  return result;
};

/**
 * Color array to hex
 * @param {number[]} color
 * @return {string}
 */
const colorToHex = (color) => {
  return '#' + color.slice(0, 3).map((value) => {
    return floor(value * 255).toString(16).padStart(2, '0');
  }).join('');
};

/**
 * Parse hex
 * @param {string} str
 * @return {number[]}
 */
const parseHex = (str) => {
  if (str[0] !== '#') {
    return [0, 0, 0, 0];
  }
  str = str.substring(1);
  let rgba;
  if (str.length < 5) {
    rgba = str.split('').map((v) => parseInt(v, 16) * 16);
  } else {
    rgba = str.match(/.{1,2}/g).map((v) => parseInt(v, 16));
  }
  if (rgba.length === 3) {
    rgba.push(1);
  } else {
    rgba[3] = rgba[3] / 255;
  }
  return rgba;
};

/**
 * Parse hex to rgba
 * @param {string} str
 * @return {string}
 */
const hexToRGBA = (str) => {
  const rgba = parseHex(str);
  if (rgba) {
    return `rgba(${rgba.join(',')})`;
  }
  return str;
};

/**
 * Parse hex to vector
 * @param {string} str
 * @return {string}
 */
const hexToGL = (str) => {
  const r = parseHex(str);
  return `vec4(${r[0]/255},${r[1]/255},${r[2]/255},${r[3]})`;
};

/**
 * String align to coords
 * @param {string} str
 * @param {number} w - canvas width
 * @param {number} h - canvas heigaht
 * @param {number} bw - block width
 * @param {number} bh - block height
 * @return {object} - coords
 */
const alignByStr = (str, w, h, bw, bh) => {
  w -= bw;
  h -= bh;

  switch (str) {
    case 'center':
      return [w / 2, h / 2];
    case 'topcenter':
      return [w / 2, 0];
    case 'topright':
      return [0, h];
    case 'centerleft':
      return [0, h / 2];
    case 'centerright':
      return [w, h / 2];
    case 'bottomleft':
      return [0, h];
    case 'bottomcenter':
      return [w / 2, h];
    case 'bottomright':
      return [w, h];
    default:
  }
  return [0, 0];
};

/**
 * Parse ranges
 * @param {string[]} arr
 * @return {string[]}
 */
const parseRanges = (arr) => {
  return arr.reduce((result, item) => {
    const pair = (item + '').split('-');
    if (pair.length === 2) {
      let [v1, v2] = pair.map((v) => parseOptionValue(v));
      const isNumber = !isNaN(v1);
      if (!isNumber) {
        [v1, v2] = pair.map((v) => v.charCodeAt(0));
      } else {
        v2 += 1;
      }
      [v1, v2] = [min(v1, v2), max(v1, v2)];

      for (let i = v1; i < v2; i++) {
        result.push(isNumber ? i : String.fromCharCode(i));
      }
    } else {
      result.push(item);
    }
    return result;
  }, []);
};

/**
 * Parse attribute options string
 * @param {string} str
 * @return {object}
 */
const parseOptions = (str) => {
  const result = {};

  splitWithNesting(str, ',').forEach((option) => {
    if (!option || !option.trim()) {
      return;
    }

    let [prop, val] = option.split(/:/).map((v) => v.trim());
    val = parseOptionValue(val);
    result[prop] = val;
  });

  return result;
};

/**
 * Parse attribute options value
 * @param {string} val
 * @return {any}
 */
const parseOptionValue = (val) => {
  if (val === undefined) {
    return;
  } else if (val === 'true' || val === 'false') {
    return val === 'true';
  } else if (val.search(/[^\d.\-+]/) === -1 && val.search('-') < 1) {
    return parseFloat(val);
  } else if (val[0] === '[') {
    const values = splitWithNesting(val.substring(1, val.length - 1));
    const result = [];
    values.forEach((v) => {
      if (v.trim().length) {
        result.push(parseOptionValue(v.trim()));
      }
    });
    return result;
  }

  const result = /[("']+(.+?)'?"?\)?$/g.exec(val);
  return result ? result[1] : val;
};

/**
 * 2. GLSL Effects information
 * 2.1. Effects base count
 * @internal
 */
const glDefaultBase = {
  'effects': 7,
  'decorsEffects': 1,
  'staticEffects': 1,
  'appearEffects': 2,
  'disappearEffects': 2,
  'changeAppearEffects': 2,
  'mouseEffects': 2,
  'scrollEffects': 2,
};

/**
 * 2.2. WebGL effects arguments base
 * @internal
 */
const glBase = {
  effects(base) {
    const [time, dur, trans, type, offset, minS, maxS] = base;
    const partTime = dur * time;
    const transTime = trans * partTime;
    const transEnd = partTime - transTime;

    let result = `${_hfloat}cur=uT;`;

    if (offset !== 0) {
      const id = glBlockIDs[glGetSizeIndex(type)];
      result += `cur=cur+${glVal(time)}*(${id}*${glVal(offset)});`;
    }

    result += `${_float}total=mod(cur,${glVal(time)}),`;
    result += (dur >= 1) ? `t=1.;` : 't=0.;';

    if (dur < 1) {
      result += `if(total<${glVal(partTime)}){t=1.;`;
      if (trans > 0) {
        result +=
          `if(total<${glVal(transTime)}){` +
          `t=total/${glVal(transTime)};t=${glslEasing['inOutSine']};}` +
          `else if(total>${glVal(transEnd)}){` +
          `t=1.-((total-${glVal(transEnd)})/${glVal(transTime)});` +
          `t=${glslEasing['inOutSine']};}`;
      }
      result += `}`;
    }

    if (maxS < 1 || minS > 0) {
      const section = maxS - minS;
      result += `t=t*${glVal(section)}+${glVal(minS)};`;
    }

    return result;
  },
  appearEffects(base) {
    const [start, end] = base;
    return glTime('(1.-vCT)', end, start);
  },
  mouseEffects(base, scale) {
    let [length, strength] = base;
    length = max(length * scale, 1);

    return `${_float}l=${glVal(length)},s=${glVal(strength)},` +
      `d=min(mD/l,1.),t=max(0.,min(1.,1.-(mD/l)))*s;`;
  },
  scrollEffects(cat, index) {
    return (cat === 'scrollEffects') ? `t=abs(uSR${index});` : '';
  },

  process(cat, base, scale) {
    if (cat === 'effects') {
      return this.effects(base);
    } else if (cat === 'staticEffects') {
      return `${_mfloat}t=${glVal(base[0])};`;
    } else if (cat === 'mouseEffects') {
      return this.mouseEffects(base, scale);
    } else if (isAppearEffects(cat)) {
      return this.appearEffects(base);
    } else if (cat === 'decorsEffects') {
      return `${_mfloat}t=${glVal(base[0])};`;
    }
    return `${_float}t=1.;`;
  },
};

/**
 * 2.3. WebGL effects
 * @internal
 */
const glEffects = {
  'fdistortion': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, args, scale) => {
        base = glBase.process(cat, base, scale);
        const [lenX, lenY, ampX, ampY, offsetX, offsetY, speedX, speedY] = args;
        return [base, [
          max(lenX * scale / 2, 1), max(lenY * scale / 2, 1),
          ampX * scale, ampY * scale,
          offsetX, offsetY,
          (speedX === 0) ? 0 : PI * 2 * (1 / speedX),
          (speedY === 0) ? 0 : PI * 2 * (1 / speedY),
        ]];
      },
      'paddingFrag': (cat, base, [,, ampX, ampY], scale) => {
        return [
          ampX * scale,
          ampY * scale,
          ampX * scale,
          ampY * scale,
        ];
      },
      'req': ['dst'],
      'frag': (cat, base, args, rawBase, raw, index) => {
        args[2] += `*t`;
        args[3] += `*t`;
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}dst(uv,ep,${args.join()});}`;
      },
      'appearFrag': (cat, base, args) => {
        args[2] += '*' + base;
        args[3] += '*' + base;
        return `dst(uv,ep,${args.join()});`;
      },
    },
  },
  'fglitch': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, args, scale) => {
        base = glBase.process(cat, base, scale);
        const [distance, sharp, len, angle, speed, density] = args;
        const angleRad = angle * PI / 180;
        return [base, [
          distance * scale, sharp, len * dpr * scale,
          `vec2(${glVal(sin(angleRad))},${glVal(cos(angleRad))})`,
          glAngle(angle + 90),
          speed, density,
        ]];
      },
      'paddingFrag': (cat, base, [distance,,, angle], scale) => {
        const d = distance * scale;
        const asin = abs(sin(angle * PI / 180));
        const acos = abs(cos(angle * PI / 180));
        return [d * asin, d * acos, d * asin, d * acos];
      },
      'req': ['glitch'],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}glitch(uv,ep,t,${args.join()});}`;
      },
      'appearFrag': (cat, base, args) => {
        return `glitch(uv,ep,${base},${args.join()});`;
      },
    },
  },
  'fbubble': {
    'mouseEffects': {
      'base': glDefaultBase,
      'args': (cat, base, args, scale) => {
        base = glBase.process(cat, base, scale);
        return [base, []];
      },
      'paddingFrag': (cat, [length, size], args, scale) => {
        return Array(4).fill(length / 4 * abs(size) * scale);
      },
      'req': [],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        return `{${base}` +
          `uv.x-=d*(l*(1.-d)/2.)*s*mSin*ep.x;` +
          `uv.y-=d*(l*(1.-d)/2.)*s*mCos*ep.y;}`;
      },
    },
  },
  'vfollow': {
    'mouseEffects': {
      'args': (cat, base, [size, lx, ly, tx, ty], scale) => {
        return [base, [
          glBlockCoords[glGetSizeIndex(size)],
          lx * scale,
          ly * scale,
          max(tx, 0),
          max(ty, 0),
        ]];
      },
      'paddingVert': (cat, base, [, lx, ly, tx, ty], scale) => {
        [lx, ly] = [abs(lx) * scale, abs(ly) * scale];
        const [px, py] = [
          (tx < .5) ? (1 - tx) : (.5 * (.5 / tx)),
          (ty < .5) ? (1 - ty) : (.5 * (.5 / ty)),
        ];
        return {
          offset: [lx * px, ly * py, lx * px, ly * py],
        };
      },
      'req': ['follow'],
      'vert': (cat, base, args) => `follow(pos,${args.join()});`,
    },
  },
  'cglitch': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, args, scale) => {
        base = glBase.process(cat, base, scale);
        const [
          c1, c2, dist, angle, comb, alpha,
          shakeSpeed, shakeDist, shakeScale,
        ] = args;
        const angleRad = angle * PI / 180;
        return [base, [
          hexToGL(c1),
          hexToGL(c2),
          dist * scale,
          `vec2(${glVal(sin(angleRad))},${glVal(cos(angleRad))})`,
          glAngle(angle + 90),
          +comb, alpha, shakeSpeed * dpr,
          shakeDist, shakeScale * dpr * scale,
        ]];
      },
      'paddingFrag': (cat, base, [,, dist, angle,,,, shakeDist], scale) => {
        const d = dist * max(1, shakeDist) * scale;
        const asin = abs(sin(angle * PI / 180));
        const acos = abs(cos(angle * PI / 180));
        return [d * asin, d * acos, d * asin, d * acos];
      },
      'req': ['cglitch'],
      'fragColor': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}cglitch(uSampler,uv,ep,tO,tA,t,${args.join()});}`;
      },
      'appearFragColor': (cat, base, args) => {
        return `cglitch(uSampler,uv,ep,tO,tA,${base},${args.join()});`;
      },
    },
  },
  'falpha': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [alpha], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [1 - alpha, alpha]];
      },
      'req': [],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}tC.w*=${args[0]}+${args[1]}*(1.-t);}`;
      },
      'appearFrag': (cat, base, args) => {
        return `tC.w*=${args[0]}+${args[1]}*(1.-${base});`;
      },
    },
  },
  'falphaNoise': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [alpha, mx, my, sx, sy], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [
          alpha, mx * scale, my * scale,
          sx * dpr * scale, sy * dpr * scale,
        ]];
      },
      'req': ['noise'],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}tC.w-=noise(uv,ep,t,` +
          `${args.slice(1).join()})*${args[0]};}`;
      },
      'appearFrag': (cat, base, args) => {
        return `tC.w-=noise(uv,ep,${base},${args.slice(1).join()})*${args[0]};`;
      },
    },
  },
  'fnoiseDistortion': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [stx, sty, mx, my, sx, sy], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [
          stx * scale, sty * scale, mx * scale, my * scale,
          sx * dpr * scale, sy * dpr * scale,
        ]];
      },
      'paddingFrag': (cat, base, [stx, sty], scale) => {
        return [stx * scale, sty * scale, stx * scale, sty * scale];
      },
      'req': ['uvNoise'],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}uvNoise(uv,ep,t,${args.join()});}`;
      },
      'appearFrag': (cat, base, args) => {
        return `uvNoise(uv,ep,${base},${args.join()});`;
      },
    },
  },
  'vtranslate': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [x, y, z], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [x * scale, -y * scale, z * scale]];
      },
      'paddingVert': (cat, base, [x, y, z], scale) => ({
        trans: [x * scale, y * scale, z * scale],
      }),
      'req': [],
      'vert': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}pos.xyz+=toPos(vec3(${args.join()}))*t;}`;
      },
      'appearVert': (cat, base, args) => {
        return `pos.xyz+=toPos(vec3(${args.join()}))*${base};`;
      },
    },
  },
  'vscale': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [x, y], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [x, y]];
      },
      'paddingVert': (cat, base, [x, y]) => ({
        scale: [x, y],
      }),
      'req': [],
      'vert': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}pos*=vec4(vec2(${args.join()})*t+vec2(1.),1.,1.);}`;
      },
      'appearVert': (cat, base, args) => {
        return `pos*=vec4(vec2(${args.join()})*${base}+vec2(1.),1.,1.);`;
      },
    },
  },
  'vrotate': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, args, scale) => {
        base = glBase.process(cat, base, scale);
        return [base, args.map((v) => (v / 2) * PI / 180)];
      },
      'req': ['quat'],
      'paddingVert': () => ({rotate: true}),
      'vert': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}pos=quat(vec3(${args.join()})*t)*pos;}`;
      },
      'appearVert': (cat, base, args) => {
        return `pos=quat(vec3(${args.join()})*${base})*pos;`;
      },
    },
  },
  'fslide': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [angle], scale) => {
        return [glBase.process(cat, base, scale), [
          glStops('a', [[0, 0], ['t', 0], ['(t+.02)', 1]]),
          glAngle(angle),
        ]];
      },
      'req': [],
      'frag': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}${_float}a=${args[1]};tC.w=tC.w*(${args[0]});}`;
      },
      'appearFrag': (cat, base, args, rawBase, raw) => {
        return `{${_float}t=${base},a=${args[1]};tC.w=tC.w*(${args[0]});}`;
      },
    },
  },
  'fcolor': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [color], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [hexToGL(color)]];
      },
      'req': ['color'],
      'fragColor': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}color(tA,${args[0]},t);}`;
      },
      'appearFragColor': (cat, base, args) => {
        return `color(tA,${args[0]},${base});`;
      },
    },
  },
  'fcolorNoise': {
    'effects': {
      'base': glDefaultBase,
      'args': (cat, base, [color, mx, my, sx, sy], scale) => {
        base = glBase.process(cat, base, scale);
        return [base, [
          hexToGL(color),
          mx * scale, my * scale,
          sx * dpr * scale, sy * dpr * scale,
        ]];
      },
      'req': ['noise', 'color'],
      'fragColor': (cat, base, args, rawBase, rawArgs, index) => {
        const start = base + glBase.scrollEffects(cat, index);
        return `{${start}color(tA,${args[0]},noise` +
          `(uv,ep,t,${args.slice(1).join()}));}`;
      },
      'appearFragColor': (cat, base, args) => {
        return `color(tA,${args[0]},noise` +
          `(uv,ep,${base},${args.slice(1).join()}));`;
      },
    },
  },
};


/**
 * 3. Options
 * @internal
 */
const Options = {
  uniqueID: 0,

  /**
   * 3.1. Process raw options
   * @param {object} node - options node
   * @param {object} o - options
   * @param {object} parent - parent options
   * @return {object} - resulted options
   */
  process(node, o, parent) {
    Object.keys(o).forEach((key) => {
      if (!defaultOptions.hasOwnProperty(key)) {
        console.warn(`WGLTR. Option ${key} not found!`);
      }
    });

    o.uniqueID = this.uniqueID++;
    o.data = {};
    o.node = node;
    // List of childs options that inherit property
    o.childs = {};
    // List of inherited properties, defaultOptions doesn't count
    o.inherit = {};
    // List of owned properties
    o.own = {};
    o.id = {};
    o.gid = parent.gid || {};
    o.parent = parent;

    Object.keys(parent).forEach((key) => {
      o.childs[key] = [];

      if (defaultOptions.hasOwnProperty(key)) {
        this.setOption(o, key, o[key]);
      }
    });

    return o;
  },

  /**
   * 3.2. Set option
   * @param {object} options
   * @param {string} key
   * @param {any} value
   */
  setOption(options, key, value) {
    const p = nonInheritedOptions.some((v) => key === v) ?
      defaultOptions : options.parent;

    if (value === undefined) {
      options[key] = p[key];
      options.childs[key].forEach((child) => child[key] = p[key]);

      delete options.own[key];
      delete options.id[key];

      // is root options
      if (p !== defaultOptions) {
        options.inherit[key] = p.inherit[key] || p;
        // Transfer current options and all children options to parent
        options.inherit[key].childs[key].push(options, ...options.childs[key]);
        options.childs[key] = [];
      }
    } else {
      value = this.resolvePresets(options, key, value);
      options[key] = value;

      if (!options.own[key]) {
        options.id[key] = options.gid[key] = (options.gid[key] || 0) + 1;
        options.own[key] = true;

        // If value inherited before
        if (options.inherit[key]) {
          const parentChilds = options.inherit[key].childs[key];
          // Find all children related to current options
          options.childs[key] = parentChilds.filter((child) => {
            let parent = child.parent;
            while (parent && parent !== options) {
              parent = parent.parent;
            }
            return !!parent;
          });

          // Remove current options and related children from parent
          arrayRemove(parentChilds, options);
          options.childs[key].forEach((child) => {
            arrayRemove(parentChilds, child);
          });
          options.inherit[key] = undefined;
        }
      }

      // Set value for all inherited children
      options.childs[key].forEach((child) => child[key] = value);
    }
  },

  /**
   * 3.3. Resolve string presets to default values
   * @param {object} options
   * @param {string} key
   * @param {any} value
   * @return {any} - resolved value
   */
  resolvePresets(options, key, value) {
    const category = getPresetCategory(key);
    const presets = defaultSimplePresets[category];

    if (presets && typeof value === 'string') {
      value = [value];
    }
    if (!presets || !Array.isArray(value)) {
      return value;
    }

    const result = [];
    value.forEach((value) => {
      if (typeof value !== 'string') {
        result.push(value);
        return;
      }
      const preset = presets[value];
      if (!preset) {
        console.warn(`WGLTR. Preset '${value}' in '${key}' not found!`);
        return;
      }
      const effectsPresets = Array.isArray(preset) ? preset : preset.presets;
      result.push(...effectsPresets);

      Object.entries(preset.options || {}).forEach(([key, value]) => {
        this.setOption(options, key, value);
      });
    });

    return result;
  },

  /**
   * 3.4. Get array of IDs which are involved in the property
   * @param {object} o
   * @param {string} prop
   * @return {number[]}
   */
  getIDs(o, prop) {
    return [o.uniqueID, ...o.childs[prop].map((o) => o.uniqueID)];
  },

  /**
   * 3.5. Get parent value if current value not set
   * @param {string} prop
   * @param {object} options
   * @param {object} parentOptions
   * @param {boolean} useParent
   * @param {*} value
   * @return {*}
   */
  getNonInheritedValue(prop, options, parentOptions, useParent, value) {
    if (useParent && !options.own[prop]) {
      options = parentOptions;
    }
    if (options.own[prop] && options[prop] !== 'inherit') {
      return options[prop];
    }
    return value;
  },

  /**
   * 3.6. Calculate non inherited options taking into account change status
   * Change parent has only elements inside so options should be inherited
   * @param {string} type
   * @param {Node} node
   * @param {boolean} isAppear
   * @param {boolean} isDisappear
   * @param {*} value
   * @return {*}
   */
  getAppear(type, node, isAppear, isDisappear, value) {
    const changeParent = !!(node.parent && node.parent.change);
    const o = node.options;
    const po = changeParent ? node.parent.options : {};

    let result = Options.getNonInheritedValue(
        `appear${type}`, o, po, changeParent, value);

    if (isAppear || isDisappear) {
      result = Options.getNonInheritedValue(
          `changeAppear${type}`, o, po, changeParent, result);
    }
    if (isDisappear) {
      result = Options.getNonInheritedValue(
          `disappear${type}`, o, po, changeParent, result);
    }

    return result;
  },
};


/**
 * 4. Font Faces
 * @internal
 */
const FontFaces = {
  loaded: [],

  /**
   * 4.1. Add to loaded font faces with timestamp
   * @param {string} face
   * @return {number}
   */
  push(face) {
    const timeStamp = Date.now();
    const _face = this.loaded.find((f) => f === face);

    if (!_face) {
      face[prefix + 'timeStamp'] = timeStamp;
      this.loaded.push(face);
      return timeStamp;
    }

    return _face[prefix + 'timeStamp'];
  },

  /**
   * 4.2. Update existing font faces
   */
  update() {
    doc.fonts.forEach((face) => {
      if (face.status === 'loaded') {
        this.push(face);
      }
    });
  },

  /**
   * 4.3. Check availability
   * Check if font face is loaded, if not then load and call callback
   * @param {string} font
   * @param {string} text
   * @param {function} callback
   * @param {number} reqTime
   */
  check(font, text, callback, reqTime) {
    this.update();
    reqTime = reqTime || new Date().getTime();

    doc.fonts.load(font, text || 'a').then((faces) => {
      if (faces.length) {
        let isNew = false;

        faces.forEach((face) => {
          if (this.push(face) > reqTime) {
            isNew = true;
          }
        });

        isNew && callback();
      } else {
        // If the font face is not yet available in document.fonts list
        // then event can be called empty
        setTimeout(() => FontFaces.check(font, text, callback, reqTime), 1);
      }
    });
  },
};

/**
 * 5. Font Baseline
 * @internal
 */
const FontBaseline = {
  cache: {},

  /**
   * 5.1. Initialization
   */
  init() {
    this.el = create('div');
    this.baseline = create('span');
    this.text = create('span');

    css(this.el, {
      'position': 'absolute',
      'opacity': '0',
      'pointer-events': 'none',
      'top': '-20000px',
      'left': '-20000px',
      'z-index': '-9999',
      'display': 'flex',
      'flex-direction': 'row',
      'align-items': 'baseline',
    });
    css(this.baseline, {
      'width': '1px',
      'height': '1px',
      'display': 'block',
    });
    this.text.innerHTML = 'l';

    this.el.append(this.text, this.baseline);
    doc.body.appendChild(this.el);

    this.range = doc.createRange();
    this.range.setStart(this.text, 0);
    this.range.setEnd(this.text, 1);
  },

  /**
   * 5.2. Clear cache for specified family
   * @param {string} family
   */
  clear(family) {
    family = family.toLowerCase();

    Object.keys(this.cache).forEach((key) => {
      if (key.toLowerCase().indexOf(family) >= 0) {
        delete this.cache[key];
      }
    });
  },

  /**
   * 5.3. Find baseline offset
   * @param {string} weight
   * @param {string} family
   * @return {object}
   */
  get(weight, family) {
    const str = `${weight} 100px ${family}`;
    if (this.cache[str]) {
      return this.cache[str];
    }

    this.el.style.font = str;
    const baselineRect = rect(this.baseline);
    const elRect = this.range.getClientRects()[0];
    const offset = (baselineRect.bottom - elRect.top) / elRect.height;

    return this.cache[str] = {
      offset: offset,
      height: elRect.height,
    };
  },
};
FontBaseline.init();


/**
 * 6. Text Glyphs
 * Get one text glyph from few glyphs
 * One line text with big letter spacing
 * One symbol that have 4 symbols will be divided by 4 on webkit safari
 * Used only for Webkit browsers
 * @internal
 */
const TextGlyphs = {
  /**
   * 6.1. Place invisible block to process
   */
  init() {
    this.el = create('span');
    this.el.style = 'white-space:nowrap;' +
      'display:inline;' +
      'font-size:1px;' +
      'letter-spacing:100px;' +
      'position:absolute;' +
      'top:-2000px;' +
      'opacity:0;' +
      'pointer-events:none;';
    doc.body.append(this.el);
    this.range = doc.createRange();
  },

  /**
   * 6.2. Set node to process
   * @param {HTMLElement} textNode
   */
  setNode(textNode) {
    this.y = -100000;
    this.content = textNode.textContent || '';
    this.length = this.content.length;
    this.el.textContent = textNode.textContent;
    this.node = this.el.childNodes[0];
  },

  /**
   * 6.3. Get count of symbols that a glyph has
   * @param {number} index
   * @return {number}
   */
  get(index) {
    let left = Infinity;
    let right = -Infinity;
    let count = 0;

    for (let i = index; i < this.length; i++) {
      if (/[\s\n\r\t]/g.test(this.content[i])) {
        break;
      }

      this.range.setStart(this.node, i);
      this.range.setEnd(this.node, i + 1);
      const rects = trimRects(this.range.getClientRects());

      if (rects.length) {
        count += 1;

        if (right - left >= 100 &&
          (rects[0].left < left || rects[0].right > right)) {
          count--;
          break;
        }

        left = min(left, rects[0].left);
        right = max(right, rects[0].right);
      }
    }

    return max(count, 1);
  },
};
if (isWebkit) {
  TextGlyphs.init();
}


/**
 * 7. Canvas
 * @internal
 */
class Canvas {
  /**
   * 7.1. Initialization
   */
  constructor() {
    this.canvas = create('canvas');
    this.ctx = this.canvas.getContext('2d', {willReadFrequently: true});
  }

  /**
   * 7.2. Resize
   * @param {number} width
   * @param {number} height
   * @param {string} fill
   * @return {Canvas}
   */
  resize(width, height, fill = undefined) {
    this.canvas.width = max(width, 1);
    this.canvas.height = max(height, 1);
    fill && this.style(fill);
    return this;
  }

  /**
   * 7.3. Set global composite operation
   * @param {string} type
   */
  gco(type = 'source-over') {
    this.ctx.globalCompositeOperation = type;
  }

  /**
   * 7.4. Set style
   * @param {string} style
   * @return {string} - Parsed color by canvas
   */
  style(style) {
    return this.ctx.strokeStyle = this.ctx.fillStyle = style;
  }

  /**
   * 7.5. Fill rect
   * @param {string?} style - fill style
   */
  fill(style) {
    if (style) {
      this.ctx.fillStyle = style;
    }
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  /**
   * 7.6. Clear
   */
  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  /**
   * 7.7. Set shadow
   * @param {number} offsetX
   * @param {number} offsetY
   * @param {number} blur
   * @param {string} color
   */
  shadow(offsetX, offsetY, blur, color) {
    const {ctx} = this;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.shadowBlur = blur;
    ctx.shadowColor = color;
  }

  /**
   * 7.8. Create pattern
   * @param {string} repeat
   * @return {CanvasPattern}
   */
  createPattern(repeat = 'repeat') {
    return this.ctx.createPattern(this.canvas, repeat);
  }

  /**
   * 7.9. Draw linear gradient
   * @param {number} angle
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {array} stops
   */
  drawLinearGradient(angle, x, y, width, height, stops) {
    const [x1, y1, x2, y2] = getOuterGradRect(angle, x, y, width, height);
    const grad = this.ctx.createLinearGradient(x1, y1, x2, y2);
    this.drawGradient(x, y, width, height, grad, stops);
  }

  /**
   * 7.10. Draw radial gradient
   * @param {string} type
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {number} tx
   * @param {number} ty
   * @param {number} s
   * @param {array} stops
   */
  drawRadialGradient(type, x, y, width, height, tx, ty, s, stops) {
    let radius;

    switch (type) {
      case 'contain':
        radius = max(width, height) / 2;
        break;
      case 'circumscribed':
        radius = sqrt(pow(width, 2) + pow(height, 2)) / 2;
        break;
      default:
        radius = min(width, height) / 2;
        break;
    }
    const grad = this.ctx.createRadialGradient(
        tx * width, ty * height, 0, tx * width, ty * height, radius * s);
    this.drawGradient(x, y, width, height, grad, stops);
  }

  /**
   * 7.11. Draw gradient
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {object} grad
   * @param {array} stops
   */
  drawGradient(x, y, width, height, grad, stops) {
    let prevPos = 0;
    stops.forEach(([pos, color]) => {
      grad.addColorStop(max(pos, prevPos), hexToRGBA(color));
      prevPos = pos;
    });

    this.style(grad);
    this.ctx.fillRect(x - width / 2, y - height / 2, width, height);
  }


  /**
   * 7.12. Draw decorations
   * @param {array} list - list of decorations
   * @param {boolean} dprScale - device pixel ratio scale
   * @param {number} width - pattern width
   * @param {number} height - pattern height
   */
  drawDecorations(list, dprScale, width, height) {
    const ctx = this.ctx;

    list.forEach(([name, out, alpha, ...args]) => {
      midCanvas.gco(out ? 'destination-out' : undefined);
      if (!alpha.length) {
        ctx.globalAlpha = alpha;
      }

      if (name === 'fill') {
        ctx.fillRect(0, 0, width, height);
        return;
      }

      const pattern = CanvasPatterns.get(
          name, dprScale, 'repeat', width, height,
          alpha.length ? hexToRGBA(alpha) : '#000',
          ...args.map((arg) => arg));

      if (pattern) {
        ctx.save();
        ctx.fillStyle = pattern;
        ctx.imageSmoothingEnabled = false;
        if (name === 'noise') {
          ctx.imageSmoothingEnabled = !!args[1];
          let [,, sx, sy] = args;
          if (dprScale) {
            sx *= dpr;
            sy *= dpr;
          }
          ctx.fillStyle.setTransform(new DOMMatrix().scale(sx, sy));
        }
        ctx.fillRect(0, 0, width, height);
        ctx.restore();
      }
    });

    ctx.globalAlpha = 1;
    midCanvas.gco();
  }
}

/**
 * 7.13. Create canvases
 * @internal
 */
const tmpCanvas = new Canvas();
const midCanvas = new Canvas();


/**
 * 8. Canvas patterns
 * @internal
 */
const CanvasPatterns = {
  cache: {},
  cacheCount: 0,
  canvas: new Canvas(),

  /**
   * 8.1. Get
   * @param {string} type
   * @param {boolean} dprScale
   * @param {string} repeat
   * @param {number} width
   * @param {number} height
   * @param {string|number} color
   * @param {array} args
   * @return {CanvasPattern}
   */
  get(type, dprScale, repeat, width, height, color, ...args) {
    if (!this[type]) {
      return;
    }

    const id = `${type}_${width}_${height}_${color}` +
        `_${dprScale}_${dpr}_${args.join('-')}`;

    if (this.cache[id]) {
      return this.cache[id];
    }
    if (this.cacheCount > 50) {
      this.cache = {};
    }
    this.cacheCount++;

    return (this.cache[id] = this.createPattern(
        type, dprScale, repeat, width, height, color, ...args));
  },

  /**
   * 8.2. Create
   * @param {string} type
   * @param {number} dprScale
   * @param {string} repeat
   * @param {number} width
   * @param {number} height
   * @param {string} color
   * @param {any[]} args
   * @return {CanvasPattern}
   */
  createPattern(type, dprScale, repeat, width, height, color, ...args) {
    width = round(width * dpr);
    height = round(height * dpr);
    this.canvas.resize(width, height);
    this.canvas.style(color);
    this[type](width, height, dprScale, ...args);
    return this.canvas.createPattern(repeat);
  },

  /**
   * 8.3. Image
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {Image} image
   */
  'image'(width, height, dprScale, image) {
    const {ctx} = this.canvas;

    ctx.drawImage(image, 0, 0, width, height);
  },

  /**
   * 8.4. Lines
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {number} distance
   * @param {number} lineWidth
   * @param {number} angle
   */
  'lines'(width, height, dprScale, distance = 3, lineWidth = 1, angle = 0) {
    const {ctx} = this.canvas;

    if (dprScale) {
      lineWidth *= dpr;
      distance *= dpr;
    }
    distance = max(0.1, round(distance + lineWidth));
    angle *= PI / 180;

    const pAngle = angle - PI / 2;
    let [ax, ay] = [sin(angle), cos(angle)];
    const [pax, pay] = [sin(pAngle), cos(pAngle)];
    const [x, y] = [round(width / 2), round(height / 2)];
    const length = sqrt(width ** 2 + height ** 2);

    ax *= length;
    ay *= length;

    ctx.beginPath();
    for (let i = -length / 2; i < length / 2; i += distance) {
      ctx.moveTo(x - ax - i * pax, y - ay - i * pay);
      ctx.lineTo(x + ax - i * pax, y + ay - i * pay);
    }

    ctx.lineWidth = lineWidth;
    ctx.stroke();
  },

  /**
   * 8.5. Zigzag
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {number} distance
   * @param {number} lineWidth
   * @param {number} zWidth
   * @param {number} zHeight
   * @param {number} angle
   */
  'zigzag'(width, height, dprScale, distance = 3, lineWidth = 1,
      zWidth = 2, zHeight = 2, angle = 0) {
    const {ctx} = this.canvas;

    if (dprScale) {
      lineWidth *= dpr;
      distance *= dpr;
    }

    distance = max(0.1, round(distance + lineWidth + zHeight));
    angle *= PI / 180;

    const pAngle = angle - PI / 2;
    let [ax, ay] = [sin(angle), cos(angle)];
    const [pax, pay] = [sin(pAngle), cos(pAngle)];
    const [x, y] = [round(width / 2), round(height / 2)];
    const length = sqrt(width ** 2 + height ** 2);
    ax *= length;
    ay *= length;

    const zagPart = ceil(length / zWidth);
    const zagAngle = angle + PI / 2;
    const [zagX, zagY] = [sin(zagAngle) * zHeight, cos(zagAngle) * zHeight];

    ctx.beginPath();
    for (let i = -length / 2; i < length / 2; i += distance) {
      const startX = x - ax - i * pax;
      const startY = y - ay - i * pay;
      const endX = x + ax - i * pax;
      const endY = y + ay - i * pay;
      const partX = (endX - startX) / zagPart;
      const partY = (endY - startY) / zagPart;

      ctx.moveTo(startX, startY);
      for (let j = 0; j <= zagPart; ++j) {
        const tendX = startX + partX * j;
        const tendY = startY + partY * j;
        const tmiddleX = tendX - partX / 2;
        const tmiddleY = tendY - partY / 2;
        ctx.lineTo(tmiddleX - zagX, tmiddleY - zagY);
        ctx.lineTo(tendX, tendY);
      }
    }

    ctx.lineWidth = lineWidth;
    ctx.stroke();
  },

  /**
   * 8.6. Circles
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {boolean} fill
   * @param {number} lineWidth
   * @param {number} minRadius
   * @param {number} maxRadius
   * @param {number} dstX
   * @param {number} dstY
   * @param {number} randX
   * @param {number} randY
   */
  'circles'(width, height, dprScale, fill = false, lineWidth = 1, minRadius = 5,
      maxRadius = 5, dstX = 4, dstY = 4, randX = 0, randY = 0) {
    const {ctx} = this.canvas;

    if (dprScale) {
      lineWidth *= dpr;
      dstX *= dpr;
      dstY *= dpr;
      randX *= dpr;
      randY *= dpr;
      minRadius *= dpr;
      maxRadius *= dpr;
    }

    ctx.beginPath();
    for (let x = 0; x < width;) {
      let maxX = 0;
      for (let y = 0; y < height;) {
        ctx.moveTo(x, y);

        const radius = randClamp(minRadius, maxRadius);
        ctx.arc(x - radius, y, radius, 0, 360);

        y += radius * 2 + max(dstY, 0);
        y += random() * randY;
        maxX = max(maxX, radius);
      }
      x += maxX * 2 + max(dstX, 0);
      x += random() * randX;
    }

    ctx.lineWidth = lineWidth;
    fill ? ctx.fill() : ctx.stroke();
  },

  /**
   * 8.7. Triangles
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {boolean} fill
   * @param {number} lineWidth
   * @param {number} minRadius
   * @param {number} maxRadius
   * @param {number} dstX
   * @param {number} dstY
   * @param {number} randX
   * @param {number} randY
   */
  'triangles'(width, height, dprScale, fill = false, lineWidth = 1,
      minRadius = 5, maxRadius = 5, dstX = 4, dstY = 4, randX = 0, randY = 0) {
    const {ctx} = this.canvas;

    if (dprScale) {
      lineWidth *= dpr;
      dstX *= dpr;
      dstY *= dpr;
      randX *= dpr;
      randY *= dpr;
      minRadius *= dpr;
      maxRadius *= dpr;
    }

    ctx.beginPath();
    for (let x = 0; x < width;) {
      let maxX = 0;
      for (let y = 0; y < height;) {
        const radius = randClamp(minRadius, maxRadius);

        ctx.moveTo(x, y);
        ctx.lineTo(x + radius * 2, y);
        ctx.lineTo(x + radius, y + radius * 2);
        ctx.lineTo(x, y);

        y += radius * 2 + max(dstY, 0);
        y += random() * randY;
        maxX = max(maxX, radius);
      }
      x += maxX * 2 + max(dstX, 0);
      x += random() * randX;
    }

    ctx.lineWidth = lineWidth;
    fill ? ctx.fill() : ctx.stroke();
  },

  /**
   * 8.8. Noise
   * @param {number} width
   * @param {number} height
   * @param {number} dprScale
   * @param {number} density
   */
  'noise'(width, height, dprScale, density = .5) {
    const {ctx} = this.canvas;
    let size = width * height;
    density *= 2;

    let id = 0;
    const imageData = new ImageData(
        new Uint8ClampedArray(4 * size),
        width, height,
    );
    const data = imageData.data;
    const hDens = density > 1;
    hDens && (density -= 1);

    while (size--) {
      // data[id + 1] = 0;
      data[id + 3] = hDens ?
          (random() < density ? 255 : random() * 255) :
          (random() > density ? 0 : random() * 255);
      id += 4;
    }

    ctx.putImageData(imageData, 0, 0);
  },
};


/**
 * 9. Pictographic
 * @internal
 */
const Pictographic = {
  canvas: new Canvas(),
  /**
   * 9.1. Initialize canvas
   */
  init() {
    const {ctx} = this.canvas;
    this.canvas.resize(10, 10);
    ctx.fillStyle = '#000';
    ctx.textBaseline = 'middle';
    ctx.font = '10px sans-serif';
  },

  /**
   * 9.2. Is pictographic
   * @param {string} character
   * @return {boolean}
   */
  is(character) {
    const {ctx} = this.canvas;

    this.canvas.clear();
    ctx.fillText(character, 0, 5);

    const data = ctx.getImageData(0, 0, 10, 10).data;
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] > 0 || data[i + 1] > 0 || data[i + 2] > 0) {
        return true;
      }
    }
    return false;
  },
};
Pictographic.init();


/**
 * 10. HTML Text
 * Process and collect information about HTML text
 * @internal
 */
class HTMLText {
  /**
   * 10.1. Initialize
   * @param {WGLTR} wgltr
   * @param {Element} el
   */
  constructor(wgltr, el) {
    const elRect = rect(wgltr.el);

    this.el = el;
    this.wgltr = wgltr;
    this.range = doc.createRange();
    this.elRect = scrollRect(elRect);
    this.elRectStart = scrollRect(elRect);
    this.resizeElRect = this.elRect;
    this.prevElRect = this.elRect;
    this.dprRect = dprRect(elRect);
    this.resizeTime = 0;
    this.resizeTimer = 0;
    this.words = [];
    this.chars = [];

    this.nodes = [];
    this.rootNodes = [];
    this.options = [];

    if (wgltr.options.wrapOnly) {
      const options = Options.process(undefined, wgltr.options, defaultOptions);
      wgltr.rootWrappers.forEach((el) => this.process(el, options));
    } else {
      this.process(el);
    }

    this.nodes = this.nodes.sort((n1, n2) => n1.level - n2.level);
    this.changeNodes = this.nodes.filter((node) => !!node.change);
    this.refreshStyles();
  }

  /**
   * 10.2. Process text nodes
   * @param {Element} el - Element to process
   * @param {object} parentOptions - Parent options
   * @param {object} parent - Parent
   * @param {object} changeParent - Parent that has change option
   * @param {number} changeIndex - Change child index
   */
  process(el, parentOptions = defaultOptions, parent = undefined,
      changeParent = undefined, changeIndex = -1) {
    const wrap = create('span');
    const color = create('span');

    css(wrap, {
      'color': 'rgba(0,0,0,0)',
      'display': 'inline',
      'background': 'none',
      'font': 'inherit',
      'text-shadow': 'none',
    });
    css(color, {
      'position': 'absolute',
      'width': '0',
      'height': '0',
      'transition': 'color 1ms step-start',
      'color': 'inherit',
      'opacity': '0',
    });

    let options = el[prefixOptions];
    if (!options && parent && changeParent === parent) {
      options = {};
    }

    const hasOptions = !!options;

    const childNodes = Array.from(el.childNodes);
    const innerNodes = childNodes.reduce((arr, node) => {
      if (isInnerNode(node)) {
        arr.push(node);
      }
      return arr;
    }, []);

    const result = {
      level: parent ? parent.level + 1 : 0,
      changeParent,
      changeIndex,
      wrap, parent,
      node: el,
      thief: color,
      styles: wnd.getComputedStyle(el),
    };

    if (hasOptions) {
      options = Options.process(result, options, parentOptions);
      options.index = this.options.length;
      this.options.push(options);

      if (options.change) {
        result.change = {
          nodes: [],
          order: [],
          started: false,
          visible: 0,
          current: 0,
          previous: 0,
          time: 0,
        };
        changeParent = result;

        // If parent is inline then make the wrap to control the position
        if (result.styles.display === 'inline') {
          css(wrap, {
            'display': 'inline-block',
            'position': 'relative',
          });
        } else {
          if (result.styles.position === 'static') {
            css(el, {position: 'relative'});
          }
        }

        css(wrap, {'text-align': 'center'});
      }
    } else {
      options = parentOptions;
      result.inherited = true;
    }

    if (!options.customTextDecorationColor) {
      css(el, {
        'text-decoration-color': 'var(--wgltr-color)',
      });
    }
    if (!options.cssShadows) {
      css(wrap, {'text-shadow': ''});
    }

    result.options = options;

    if (parent && parent.change) {
      parent.change.nodes.push(result);
      css(el, {
        'text-align': inherit(options.changeAlign, result.styles.textAlign),
        'left': '0',
        'top': '0',
      });
      if (changeIndex > 0) {
        this.hideChangeEl(parent.change, changeIndex);
      }
    }

    innerNodes.forEach((node, i) => {
      if (result.change) {
        changeIndex = i;
      }
      this.process(node, result.options, result, changeParent, changeIndex);
    });

    // Append wrapper and color span
    childNodes.forEach((node) => wrap.appendChild(node));
    el.append(wrap, color);

    // Redirect the text properties to the wrapper
    ['innerHTML', 'innerText', 'textContent'].forEach((prop) => {
      Object.defineProperty(el, prop, {
        'configurable': true,
        set(value) {
          wrap[prop] = value;
        },
        get() {
          return wrap[prop];
        },
      });
    });
    el.insertAdjacentHTML = wrap.insertAdjacentHTML.bind(wrap);

    // Styles events
    on(color, 'transitionstart', () => this.transitionCallback());
    on(color, 'transitionend', () => this.transitionCallback());

    this.nodes.push(wrap[prefix + 'Info'] = result);
    if (!parent || !parent.wrap) {
      this.rootNodes.push(result);
    }
  }

  /**
   * 10.3. Transition callback
   */
  transitionCallback() {
    if (!this.wgltr.colorsBlocked) {
      this.wgltr.colorsChanged = true;
      this.wgltr.colorsChangedTimer = defaultOptions.colorsChangeDelay;
    }
  }

  /**
   * 10.4. Change child nodes visibility with the specified index
   * @param {object} change - Node change information
   * @param {number} index - Child index that should become visible
   */
  toggleChangeEl(change, index) {
    this.hideChangeEl(change, change.visible);
    const node = change.nodes[index];
    css(node.node, {
      'width': '',
      'position': 'relative',
      'visibility': 'visible',
      'display': '',
      'z-index': '',
      'user-select': '',
      'pointer-events': '',
    });
    change.visible = index;
  }

  /**
   * 10.5. Hide change node visibility
   * @param {object} change
   * @param {number} index
   */
  hideChangeEl(change, index) {
    css(change.nodes[index].node, {
      'width': '100%',
      'display': 'inline-block',
      'position': 'absolute',
      'left': '0',
      'z-index': '-1',
      'pointer-events': 'none',
      'user-select': 'none',
    });
  }

  /**
   * 10.6. Check if node is current
   * @param {object} node - processed node information
   * @return {boolean} - is current
   */
  isChangeCurrent(node) {
    if (node.changeParent) {
      return node.changeParent.change.current === node.changeIndex;
    }
    return true;
  }

  /**
   * 10.7. Check if node is visible on HTML
   * @param {object} node - processed node information
   * @return {boolean} - is visible
   */
  isVisible(node) {
    if (node.changeParent) {
      return node.changeParent.change.visible === node.changeIndex;
    }
    return true;
  }

  /**
   * 10.8. Check if node visible on screen
   * @param {object} node - processed node information
   * @return {boolean} - is visible
   */
  isScreenVisible(node) {
    if (node.changeParent) {
      const change = node.changeParent.change;
      return change.current === node.changeIndex ||
          (change.isDisappear && change.previous === node.changeIndex);
    }
    return true;
  }

  /**
   * 10.9. Refresh styles
   */
  refreshStyles() {
    this.useShadow = false;
    this.sizeChanged = false;

    this.nodes.forEach((item) => {
      const styles = item.styles;

      item.weight = styles.fontWeight;
      item.family = styles.fontFamily;
      item.kerning = styles.fontKerning.search('none') === -1;
      item.spacing = (parseFloat(styles.letterSpacing) || 0) * dpr;
      item.transform = styles.textTransform;

      item.cssSize = parseFloat(styles.fontSize);
      const size = item.cssSize * dpr;
      if (item.size !== undefined && item.size !== size) {
        this.sizeChanged = true;
      }
      item.size = size;

      const hypStr = styles.hyphenateCharacter ||
          styles.webkitHyphenateCharacter || '"-"';

      item.hyphensStr = (hypStr === 'auto') ? '-' :
          hypStr.substring(1, hypStr.length - 1);

      const fontVariant =
          styles.fontVariant.search('small-caps') !== -1 ?
              'small-caps' :
              'normal';

      item.font =
          fontVariant + ' ' +
          styles.fontStyle + ' ' +
          item.weight + ' ' +
          item.size + 'px ' +
          item.family;

      item.opacityChain = [];
      if (this.wgltr.options.wrapOnly && !item.parent) {
        let parent = item.node.parentNode;
        while (parent !== this.wgltr.el) {
          item.opacityChain.push(
              parseFloat(wnd.getComputedStyle(parent).opacity));
          parent = parent.parentNode;
        }
        item.opacityChain.reverse();
      }

      this.processColor(item, styles.color, styles.opacity);
      if (item.options.cssShadows) {
        this.processShadow(item, styles.textShadow);
      }

      if (item.shadow) {
        this.useShadow = true;
      }
    });
  }

  /**
   * 10.10. Refresh colors
   * @return {boolean} - true if colors changed
   */
  refreshColors() {
    this.nodes.forEach((item) => {
      this.processColor(item, item.styles.color, item.styles.opacity);
      if (this.useShadow && item.options.cssShadows) {
        this.useShadow && this.processShadow(item, item.styles.textShadow);
      }
    });
    return this.nodes.some((item) => {
      return item.colorChanged || item.shadowColorChanged;
    });
  }

  /**
   * 10.11. Process color
   * @param {object} item - Text node information
   * @param {string} color - Style color string
   * @param {string} opacity - Style opacity
   */
  processColor(item, color, opacity) {
    // Find color
    let parent = item.parent;
    while (parent && color === 'rgba(0, 0, 0, 0)') {
      color = parent.rawColor;
      parent = parent.parent;
    }

    // Calculate opacity
    item.opacity = parseFloat(opacity);
    const opacityChain = [item.opacity];

    parent = item.parent;
    while (parent) {
      opacityChain.push(parent.opacity);
      parent = parent.parent;
    }

    opacityChain.reverse();
    opacityChain.unshift(...item.opacityChain);
    opacity = opacityChain.reduce((r, o) => r * o, 1);

    item.colorChanged = item.rawColor !== color || item.rawOpacity !== opacity;
    item.rawColor = color;
    item.rawOpacity = opacity;
    item.color = parseColor(item.rawColor);
    item.color[3] = item.color[3] * opacity;
    item.node.style.setProperty('--wgltr-color', color);
  }

  /**
   * 10.12. Refresh shadows
   * @param {object} item - Text node information
   * @param {string} shadow - Style text shadow string
   */
  processShadow(item, shadow) {
    if (shadow !== 'none') {
      if (shadow !== item.prevShadow) {
        const shadowsInfo = shadow.split('x, ').map((shadow) => {
          const result = shadow.match(/(rgba?\(.+?\))\s(.+?)\s(.+?)\s(.+)/);
          return [
            parseColor(result[1]),
            parseFloat(result[2]),
            parseFloat(result[3]),
            parseFloat(result[4]),
          ];
        }).reverse();

        const prevShadows = item.prevShadows;
        if (!prevShadows) {
          item.shadowColorChanged = true;
          item.shadowChanged = true;
        } else {
          item.shadowColorChanged = !prevShadows.every((s, i) => {
            return s[0].every((c, j) => c === shadowsInfo[i][0][j]);
          });
          item.shadowChanged = !prevShadows.every((s, i) => {
            return s.every((c, j) => c === shadowsInfo[i][j] || j === 0);
          });
        }
        item.shadow = shadowsInfo;
        item.prevShadows = shadowsInfo;
      } else {
        item.shadowChanged = false;
        item.shadowColorChanged = false;
      }
      item.prevShadow = shadow;
    } else {
      item.shadowChanged = false;
      item.shadowColorChanged = false;
      if (item.parent) {
        item.shadow = item.parent.shadow;
      }
    }
  }

  /**
   * 10.13. Get text nodes in order
   * Get all text nodes with information
   * @param {Element} el - Current element
   * @param {array} start - Text node start elements
   * @param {array} end - Text node end elements
   * @return {object[]} - Text nodes information in order
   */
  getTextNodes(el, start, end) {
    const nodes = [];
    const childs = Array.from(el.childNodes).filter((node) => {
      return (isInnerNode(node) || node.nodeType === 3) &&
          node.textContent.trim() !== '';
    });

    childs.forEach((child, i) => {
      const first = i === 0;
      const last = i === childs.length - 1;

      if (child.nodeType === 3) {
        let info;
        let infoEl = el;

        // Get processed parent element if it's a new element
        while (!info) {
          info = infoEl[prefix + 'Info'];
          infoEl = infoEl.parentElement;
        }

        const result = {info, node: child};
        first && (result.start = start);
        last && (result.end = end);
        nodes.push(result);
      } else {
        const info = child.children.length &&
            child.children[0][prefix + 'Info'];
        const el = info ? child.children[0] : child;
        const uniqueOptions = info && !info.inherited;

        let _start = first ? start : undefined;
        let _end = last ? end : undefined;

        if (uniqueOptions) {
          _start = _start ? [..._start, info] : [info];
          _end = _end ? [..._end, info] : [info];
        }

        nodes.push(...this.getTextNodes(el, _start, _end));
      }
    });
    return nodes;
  }

  /**
   * 10.14. Calculate bounding box for all possible cases
   */
  calcSizes() {
    this.resizeElRect = scrollRect(rect(this.wgltr.el));

    this.textNodes.forEach((textNode) => (textNode.rects = []));
    this.calcNodesBoundingBox();
    if (this.changeNodes.length) {
      this.calcChangeBoundingBox(this.changeNodes, 0);
    }

    this.minNodeOffset = Infinity;
    this.textNodes.forEach((textNode) => {
      textNode.rect = maxRect();
      textNode.rects.forEach((rect) => {
        this.minNodeOffset = min(this.minNodeOffset, round(rect.x * dpr));
        expandRect(textNode.rect, dprRect(rect));
      });
    });
  }

  /**
   * 10.15. Calculate bounding box for current visible nodes
   */
  calcNodesBoundingBox() {
    const {range} = this;
    const elRect = scrollRect(rect(this.wgltr.el));

    this.textNodes.forEach((textNode) => {
      const {info, node} = textNode;
      const {parent} = info;
      const spacing = info.spacing / dpr;

      // Skip invisible change elements
      if (!this.isVisible(info)) {
        return;
      }

      // Calculate change child width for animation
      if (parent && parent.change) {
        info.changeWidth = 0;
        range.selectNode(info.node);
        const rects = range.getClientRects();
        for (let i = 0; i < rects.length; i++) {
          info.changeWidth += rects[i].width - spacing;
        }
      }

      // Select full text node
      range.setStart(node, max(node.textContent.search(/[^\s\n\r\t]/), 0));
      range.setEnd(node, node.length);
      const rects = Array.from(range.getClientRects());
      textNode.rects.push(...rects.map((rect) => {
        const result = scrollRect(rect);
        result.x -= elRect.x;
        result.y -= elRect.y;
        return result;
      }));
    });
  }

  /**
   * 10.16. Calculate bounding box for each change text
   * @param {array} arr - array of change elements
   * @param {number} index - current change index
   */
  calcChangeBoundingBox(arr, index) {
    const el = arr[index];
    const prevVisible = el.change.visible;

    el.change.nodes.forEach((node, nodeIndex) => {
      this.toggleChangeEl(el.change, nodeIndex);
      if (index + 1 >= arr.length) {
        this.calcNodesBoundingBox();
      } else {
        this.calcChangeBoundingBox(arr, index + 1);
      }
    });

    this.toggleChangeEl(el.change, prevVisible);
  }

  /**
   * 10.17. Resize
   */
  resize() {
    this.changeNodes.forEach(({change}) => {
      change.nodes.forEach((node) => {
        node.changeWidth = 0;
      });
    });

    this.refreshStyles();

    this.wgltr.removeTransform();
    this.textNodes = [];
    this.rootNodes.forEach((node) => {
      this.textNodes.push(...this.getTextNodes(node.wrap));
    });

    this.calcSizes();
    this.wgltr.restoreTransform();

    this.nodes.forEach((info) => {
      const {offset, height} = FontBaseline.get(info.weight, info.family);
      info.baselineOffset = offset;
      info.fontHeight = height * (info.size / 100);
      info.fontBaseline = info.fontHeight * offset;
    });
  }

  /**
   * 10.18. Get the position and text of nodes, taking into account line breaks
   * @param {boolean} savePositions - save previous positions for animation
   */
  refreshCoords(savePositions = false) {
    const {range} = this;

    this.wgltr.removeTransform();

    const elRect = rect(this.wgltr.el);
    this.elRect = scrollRect(elRect);
    this.elDprRect = dprRect(elRect);

    // Difference between start canvas position and current
    // Needed to seamless effects animations depended on position in px
    this.canvasPosDiff = this.elRect.x - this.elRectStart.x;

    this.reset();
    this.prevDprRect = this.dprRect;
    this.dprRect = maxRect();

    this.textNodes.forEach((textNode) => {
      const {info, node} = textNode;
      const textContent = node.textContent;
      const ligatures = info.options.ligatures.sort((a, b) => {
        return (a.length < b.length) ? 1 : -1;
      }).map((ligature) => ligature.split(''));

      // Check font load
      FontFaces.check(info.font, textContent, () => {
        this.wgltr.fontLoaded(info.family);
      });
      const isVisible = this.isVisible(info);
      const isScreenVisible = this.isScreenVisible(info);

      if (!(/[^\s\n\r\t]/g.test(textContent))) {
        return;
      }

      isWebkit && TextGlyphs.setNode(node);

      const spacing = info.spacing / dpr;
      const words = [];
      let start = 0;
      let word = {textNode, chars: [], isFirst: true, rect: maxRect()};
      let prevRect = maxRect();
      let isPrevHyphens = false;

      while (start < textContent.length) {
        const isSpace = /\s/g.test(textContent[start]);
        const isEnd = start === textContent.length - 1;
        const hasPrev = prevRect.x !== Infinity;
        let isBreak = /[\s\n\r\t]/g.test(textContent[start]);
        let glyphRect = new Rect();
        let isPrevGlyph = false;

        if (!isBreak) {
          const count = isWebkit ? TextGlyphs.get(start) : 1;
          range.setStart(node, start);
          range.setEnd(node, start + count);
          start += count - 1;

          const rawRects = range.getClientRects();
          const rects = trimRects(range.getClientRects());

          let rect = rawRects[0];
          if (rects.length > 0) {
            rect = rects[0];

            // Select rect for symbol not for previous hyphens
            if (isPrevHyphens && rects.length > 1) {
              rect = rects[1];
            }
          }

          glyphRect = dprRect(rect);
          glyphRect = new Rect(
              glyphRect.x - this.elDprRect.x,
              glyphRect.y - this.elDprRect.y,
              glyphRect.width,
              glyphRect.height,
          );

          if (isVisible) {
            this.dprRect.x = min(this.dprRect.x, glyphRect.x);
            this.dprRect.y = min(this.dprRect.y, glyphRect.y);
            this.dprRect.width = max(
                this.dprRect.width,
                glyphRect.x + glyphRect.width,
            );
          }

          if (!isWebkit) {
            // Check if its part of previous symbol
            if (glyphRect.x === prevRect.x &&
                glyphRect.y === prevRect.y) {
              isPrevGlyph = true;
            }
          } else if (isPrevHyphens) {
            // Previous hyphens on WebKit is wrong
            if (prevRect.y > glyphRect.y - 2 &&
              prevRect.y < glyphRect.y + 2) {
              const c = word.chars[word.chars.length-1];
              c.str = c.str.substring(
                  0, c.str.length - info.hyphensStr.length);
            }
          }

          let char = range.toString();

          const isHyphens = char.charCodeAt(0) === 173 && rects.length > 0;
          if (isHyphens) {
            if (!isWebkit) {
              const wordChar = word.chars[word.chars.length-1];
              wordChar.str += info.hyphensStr;
            }
            isPrevGlyph = false;
            isPrevHyphens = true;
          } else {
            isPrevHyphens = false;
          }

          if (isWebkit) {
            isPrevHyphens = !!char.match(/\u00ad/);
            char = char.replace(/\u00ad/g, info.hyphensStr);
          }

          // If it's part of previous glyph then add character to previous
          if (isPrevGlyph) {
            const wordChar = word.chars[word.chars.length - 1];
            wordChar.str += char;
            if (rects.length > 0) {
              wordChar.rect = glyphRect;
            }
          } else {
            const isRTL = /[\u0591-\u07FF]/.test(char);
            if (word.chars.length && hasPrev &&
              ((!isRTL && prevRect.x > glyphRect.x) ||
              (isRTL && prevRect.x < glyphRect.x))) {
              // If it's long word that breaks then split
              glyphRect = prevRect = maxRect();
              isBreak = true;
              start -= count;
            } else if (!isHyphens) {
              expandRect(word.rect, glyphRect);
              word.chars.push({
                word,
                str: char,
                rect: glyphRect,
              });
            }
          }
          prevRect = glyphRect;
        }

        // Add space size to canvas size
        if (isSpace && isVisible) {
          range.setStart(node, start);
          range.setEnd(node, start + 1);
          const rawRects = range.getClientRects();

          if (rawRects.length) {
            const rect = dprRect(rawRects[0]);
            this.dprRect.x = min(this.dprRect.x, rect.x - this.elDprRect.x);
            this.dprRect.y = min(this.dprRect.y, rect.y - this.elDprRect.y);
          }
        }

        if ((isBreak || isEnd) && word.chars.length) {
          this.processWord(info, word, spacing, ligatures, savePositions);

          if (isVisible) {
            this.processLine(this.nodes[0], word);
            word.line = this.nodes[0].curLine;

            // Save first change HTML visible word
            if (info.changeParent) {
              const change = info.changeParent.change;
              if (change.curLine === 0) {
                change.curLine = word.line;
              }
            }
          } else if (isScreenVisible && word.node.changeParent) {
            const change = word.node.changeParent.change;
            const node = change.nodes[word.node.changeIndex];
            this.processLine(node, word);
            word.line = node.curLine;
          }
          if (!savePositions) {
            const line = word.line;
            word.chars.forEach((c) => {
              c.line = line;
            });
          }

          words.push(word);
          word = {textNode, chars: [], rect: maxRect()};
        }

        start++;
      }

      words[0].chars[0].start = textNode.start;
      const last = words[words.length-1];
      last.chars[last.chars.length-1].end = textNode.end;
    });

    if (savePositions) {
      this.offset = (this.elRect.x - this.prevElRect.x) * dpr;
      this.restorePositions();
    }

    // Prevent canvas offset after change
    this.dprRect.x = round(this.dprRect.x);
    this.dprRect.y = round(this.dprRect.y);

    if (savePositions) {
      this.processSmoothSizes();
    } else {
      this.stopSmoothResize();
    }

    this.prevElRect = this.elRect;
    this.fixChangeLines();
    this.refreshStylesInfo();

    this.wgltr.restoreTransform();
  }

  /**
   * 10.19. Reset lines information
   */
  reset() {
    this.words = [];
    this.chars = [];

    this.nodes.forEach((node) => {
      node.prevChars = node.chars || [];
      node.chars = [];
      node.curLine = 0;
      node.curLineY = -Infinity;

      if (node.change) {
        node.change.curLine = 0;
      }
    });
  }

  /**
   * 10.20. Process line number
   * @param {object} node
   * @param {object} word
   */
  processLine(node, word) {
    const {rect} = word;
    if ((rect.top + rect.height / 2) > node.curLineY) {
      node.curLine++;
      node.curLineY = rect.bottom;
    }
  }

  /**
   * 10.21. Process and push word to list
   * @param {object} node
   * @param {object} word
   * @param {number} spacing
   * @param {array} ligatures
   * @param {boolean} restore
   */
  processWord(node, word, spacing, ligatures, restore) {
    word.isRTL = /[\u0591-\u07FF]/.test(word.chars[0].str);
    word.node = node;

    switch (node.options.split) {
      case 'words':
        const rect = word.chars.reduce((result, c) => {
          return (result.x < c.rect.x) ? result : c.rect;
        }, word.chars[0].rect);
        word.chars = [{
          word,
          rect,
          str: word.chars.reduce((result, c) => result + c.str, ''),
        }];
        break;

      default:
        if (ligatures.length) {
          const result = [];

          for (let i = 0; i < word.chars.length; i++) {
            const c = word.chars[i];

            const founded = ligatures.some((ligature) => {
              if (ligature[0] === c.str &&
                ligature.length <= word.chars.length - i) {
                let str = '';

                if (ligature.every((sym) => {
                  str += word.chars[i + str.length].str;
                  return sym === str[str.length - 1];
                })) {
                  c.str = str;
                  i += str.length - 1;
                  result.push(c);
                  return true;
                }
              }
              return false;
            });

            if (!founded) {
              result.push(c);
            }
          }
          word.chars = result;
        }
    }

    switch (node.transform) {
      case 'uppercase':
        word.chars.forEach((char) => {
          char.str = char.str.toUpperCase();
        });
        break;
      case 'lowercase':
        word.chars.forEach((char) => {
          char.str = char.str.toLowerCase();
        });
        break;
      default:
    }

    word.rect.width -= spacing;
    word.index = this.words.length;
    this.words.push(word);
    word.chars.forEach((c, i) => c.id = this.chars.length + i);
    this.chars.push(...word.chars);
    node.chars.push(...word.chars);

    // Restore old char props if text don't changed
    for (let i = 0; i < word.chars.length; i++) {
      if (node.prevChars.length === 0) {
        break;
      }
      const prevChar = node.prevChars.shift();
      const ch = word.chars[i];
      if (prevChar.str !== ch.str) {
        node.prevChars = [];
        break;
      }
      ch.oldPos = ch.pos;
      ch.id = prevChar.id;
      ch.pos = prevChar.pos;
      ch.line = prevChar.line;
      ch.visible = prevChar.visible;
      if (restore) {
        ch.asyncDelay = prevChar.asyncDelay;
      }
    }
  }

  /**
   * 10.22. Add change current line to all screen visible change nodes
   */
  fixChangeLines() {
    this.words.forEach((word) => {
      if (!this.isVisible(word.node) && this.isScreenVisible(word.node) &&
        word.node.changeParent) {
        word.line += word.node.changeParent.change.curLine - 1;
      }
    });
  }

  /**
   * 10.23. Restore positions after change for smooth animation
   */
  restorePositions() {
    this.chars.forEach((ch) => {
      // Save previous line for invisible HTML elements
      // for disappear animation
      ch.word.oldLine = ch.line;

      if (ch.pos) {
        let {x, y} = ch.pos;
        x -= this.offset;
        if (ch.word.node.options.roundPosition) {
          x = round(x);
        }
        ch.oldPos = {x, y};
      }
    });
  }

  /**
   * 10.24. Refresh styles unique id & styles paddings
   */
  refreshStylesInfo() {
    this.nodes.forEach((node) => {
      const o = node.options;

      node.id = `${node.font}`;
      [
        'stroke',
        'strokeWidth',
        'strokeBlur',
        'decors',
        'decorsStroke',
        'decorsStrokeWidth',
        'decorsStrokeBlur',
        'staticEffects',
      ].forEach((v) => {
        let id = o.id[v];
        if (id === undefined && o.inherit[v]) {
          id = o.inherit[v].id[v];
        }
        node.id += '_' + (id || 0);
      });

      const aP = o.glyphsPadding;
      const padding = [0, 0, 0, 0];
      const glPadding = [0, 0, 0, 0];
      const decorsPadding = [0, 0, 0, 0];

      // Stroke
      if (o.stroke && o.stroke !== 'in') {
        const width = ((o.strokeWidth + o.strokeBlur) * 1.5 / 2) * dpr;
        arrayAdd(padding, [width, width, width, width]);
      }

      // Effects
      extendByEffects(
          padding,
          'staticEffects',
          getGLEffects(o, 'staticEffects'));
      ['effects', 'mouseEffects', 'scrollEffects'].forEach((effect) => {
        extendByEffects(glPadding, effect, getGLEffects(o, effect));
      });

      const glDynamicPadding = glPadding.map((v) => v);
      extendByEffects(
          glDynamicPadding,
          'appearEffects',
          getGLEffects(o, 'appearEffects'));
      extendByEffects(
          glDynamicPadding,
          'disappearEffects',
          getGLEffects(o, 'disappearEffects'));

      // Shadow blur
      node.shadowPadding = [];
      node.shadow && node.shadow.forEach((shadow) => {
        const blur = shadow[3] * dpr;
        node.shadowPadding.push(padding.map((p) => {
          return round(p + blur + aP);
        }));
      });

      node.padding = padding.map((p) => round(p + aP));
      node.decorsPadding = decorsPadding.map((p) => round(p));
      node.glPadding = glPadding.map((p) => round(p));
      node.glDynamicPadding = glDynamicPadding.map((p) => round(p));

      if (o.reserved) {
        node.reserved = parseRanges(o.reserved);

        if (node.changeParent && node.reserved.length === 0) {
          node.reserved = node.changeParent.reserved;
        }
      }
    });
  }

  /**
   * 10.25. Detect if HTML block needs to be smooth resized
   */
  processSmoothSizes() {
    const o = this.options[0];

    const heightDiff = this.elRect.height - this.prevElRect.height;
    const widthDiff = this.elRect.width - this.prevElRect.width;
    const heightChanged = o.smoothHeight && (heightDiff > 1 || heightDiff < -1);
    const widthChanged = o.smoothWidth && (widthDiff > 1 || widthDiff < -1);

    if (heightChanged || widthChanged) {
      if (!widthChanged) {
        this.smoothSizeReduced = heightDiff < 0;
        this.resizeTime = o.smoothHeight;
      } else if (!heightChanged) {
        this.smoothSizeReduced = widthDiff < 0;
        this.resizeTime = o.smoothWidth;
      } else {
        this.smoothSizeReduced = heightDiff < 0 || widthDiff < 0;
        this.resizeTime = max(o.smoothHeight, o.smoothWidth);
      }
      this.smoothHeightChanged = heightChanged;
      this.smoothWidthChanged = widthChanged;
      this.resizeFrom = this.prevElRect;

      this.wgltr.startChanging();
      css(this.el, {
        width: this.smoothWidthChanged ? (this.prevElRect.width + 'px') : '',
        height: this.smoothHeightChanged ? (this.prevElRect.height + 'px') : '',
        // overflow: 'hidden',
      });

      if (!this.smoothSizeReduced) {
        this.startSmoothResize();
      } else {
        this.waitForSmoothResize = true;
      }
    }
  }

  /**
   * 10.26. Start HTML smooth resize
   */
  startSmoothResize() {
    this.waitForSmoothResize = false;
    this.smoothResizeStarted = true;
    this.resizeTimer = this.resizeTime;
  }

  /**
   * 10.27. Reset HTML smooth resize
   */
  stopSmoothResize() {
    if (this.smoothResizeStarted) {
      this.smoothResizeStarted = false;
      this.resizeTime = 0;
      this.resizeTimer = 0;
      css(this.el, {
        height: '',
        width: '',
        // overflow: '',
      });
      this.wgltr.stopChanging();
    }
  }

  /**
   * 10.28. Process HTML smooth resize
   * @param {number} et - elapsed time
   * @param {boolean} textMoved
   */
  processSmoothResize(et, textMoved) {
    const {elRect, resizeFrom} = this;

    if (this.waitForSmoothResize && textMoved) {
      this.startSmoothResize();
    }

    if (this.resizeTimer > 0) {
      this.resizeTimer = max(0, this.resizeTimer - et / 1000);

      if (this.resizeTimer <= 0) {
        this.stopSmoothResize();
      } else {
        let t = (1 - this.resizeTimer / this.resizeTime);
        t = (t < 0.5) ? (2 * t * t) : ((-2 * t * t) + (4 * t) - 1);

        if (this.smoothWidthChanged) {
          const width = resizeFrom.width +
            (elRect.width - resizeFrom.width) * t;
          css(this.el, {width: width + 'px'});
        }
        if (this.smoothHeightChanged) {
          const height = resizeFrom.height +
            (elRect.height - resizeFrom.height) * t;
          css(this.el, {height: height + 'px'});
        }
      }
    }
  }

  /**
   * 10.29. Destroy
   */
  destroy() {
    // Remove wrappers
    this.nodes.forEach((node) => {
      css(node.node, {
        '--wgltr-color': '',
        'text-decoration-color': '',
      });
      if (node.changeParent) {
        css(node.node, {
          'text-align': '',
          'width': '',
          'display': '',
          'z-index': '',
          'pointer-events': '',
          'user-select': '',
          'left': '',
          'right': '',
          'top': '',
          'position': '',
          'visibility': '',
        });
      }
      node.thief && node.thief.remove();
      node.wrap && node.wrap.replaceWith(...node.wrap.childNodes);
    });
  }
}


/**
 * 11. Font texture
 * @internal
 */
class FontTexture {
  /**
   * 11.1. Initialization
   * @param {WGLTR} wgltr
   */
  constructor(wgltr) {
    this.wgltr = wgltr;
    this.gl = wgltr.gl;
    this.prevGlyphs = [];
    this.glyphs = [];
    this.glyphsChanged = true;
    this.maxGlyphWidth = 1;
    this.maxGlyphHeight = 1;
    this.size = {
      width: 1,
      height: 1,
    };

    this.shader = new Shader(this.gl, {
      attrs: [
        [_vec4, 'C', 0],
        [_vec4, 'CC', 0],
        [_vec4, 'UVLI', 1],
        [_vec4, 'P', 1],
        [_vec4, 'AP', 1],
      ],
      uniforms: [
        [_sampler, 'Sampler', 0, 1],
        [_sampler, 'Noise', 0, 1],
        [_sampler, 'Decors', 0, 1],
        [_hfloat, 'T', 0, 1],
        [_vec4, 'ID', 0, 1],
        [_vec3, 'E', 0, 1],
        [_vec4, 'U', 1],
        [_vec2, 'NS', 1],
        [_vec2, 'CO', 0, 1],
        [_vec3, 'M', 0, 1],
        [_float, 'AR', 0],
      ],
      varyings: [
        [_float, 'CT', 1],
        [_vec2, 'UP', 1],
        [_vec4, 'VP', 1],
        [_vec2, 'DUV', 1],
      ],
    });

    this.calcDecors();
    this.loadDecors();
    this.updateShader();
  }

  /**
   * 11.2. Refresh texture if needed
   * @param {boolean} force - refresh texture even if glyphs not changed
   */
  refresh(force = false) {
    const {htmlText} = this.wgltr;
    if (htmlText.words.length === 0) {
      return;
    }

    this.measure(htmlText);
    if (this.glyphsChanged || force) {
      this.calcTextureSize();
      this.updateTexture();
      this.glyphsChanged = false;
    }
  }

  /**
   * 11.3. Get character unique ID
   * @param {object} node
   * @param {string} character
   * @return {string}
   */
  getID(node, character) {
    return `${node.id}_${dpr}_${character || ''}`;
  }

  /**
   * 11.4. Get Glyph by ID
   * @param {string} id - Glyph ID
   * @return {object} - Glyph
   */
  getGlyph(id) {
    const prevGlyph = this.prevGlyphs.find((glyph) => glyph.id === id);
    let glyph = this.glyphs.find((glyph) => glyph.id === id);

    if (!prevGlyph) {
      this.glyphsChanged = true;
    } else {
      if (!glyph) {
        this.glyphs.push(glyph = prevGlyph);
      }
    }
    return glyph;
  }

  /**
   * 11.5. Measure text and canvas size
   * @param {HTMLText} htmlText - HTML Text information
   */
  measure(htmlText) {
    const {ctx} = tmpCanvas;

    ctx.textBaseline = 'alphabetic';

    this.glyphs = [];

    // Save pictographic count to reserve place on texture
    // Because pictographic takes 4 layers (rgba)
    this.pictographicCount = 0;

    // Maximum glyph size
    this.maxGlyphWidth = 0;
    this.maxGlyphHeight = 0;

    // Collect unique glyphs and measure sizes
    htmlText.words.forEach(({node, chars}) => {
      ctx.font = node.font;
      chars.forEach((character) => {
        this.processGlyph(this.getID(node, character.str), character.str, node);
      });
    });
    htmlText.nodes.forEach((node) => {
      if (node.reserved) {
        ctx.font = node.font;
        node.reserved.forEach((character) => {
          this.processGlyph(
              this.getID(node, character + ''), character + '', node);
        });
      }
    });

    if (this.glyphsChanged) {
      // Sort by height to save space on the texture
      this.glyphs.sort((a, b) => b.height - a.height);
      // Pictographic first
      this.glyphs.sort((a) => a.isPictographic ? -1 : 1);
    }

    // Save previous glyphs to detect changes
    this.prevGlyphs = this.glyphs;
  }

  /**
   * 11.6. Process glyph
   * @param {string} id - Glyph ID
   * @param {string} character - Glyph character
   * @param {object} node - Text node information
   */
  processGlyph(id, character, node) {
    let glyph = this.getGlyph(id);

    if (!glyph) {
      const isPictographic = pictographicRegex.test(character) &&
          Pictographic.is(character);
      const glyphSize = tmpCanvas.ctx.measureText(character);

      const size = {
        rawWidth: glyphSize.width,
        left: glyphSize.actualBoundingBoxLeft,
        right: glyphSize.actualBoundingBoxRight,
        top: glyphSize.actualBoundingBoxAscent,
        bottom: glyphSize.actualBoundingBoxDescent,
      };

      glyph = {id, character, isPictographic, node, size};
      this.glyphs.push(glyph);

      if (glyph.isPictographic) {
        this.pictographicCount++;

        // Trying to fix wrong size for some pictographic glyphs on Gecko
        if (size.right < size.rawWidth / 2) {
          size.right = size.rawWidth;
          size.left = 0;
        }
        if (size.top < size.rawWidth / 2) {
          size.top = size.rawWidth;
          size.bottom = size.rawWidth / 4;
        }
      }
    }

    this.processShadowGlyphs(node.shadow, id,
        this.calcGlyphSizes(glyph, false));
  }

  /**
   * 11.7. Process shadow glyphs
   * @param {array} shadows - Shadows information
   * @param {string} id - Glyph ID
   * @param {object} glyph - Glyph
   * @return {array} - Processed shadow glyphs
   */
  processShadowGlyphs(shadows = [], id, glyph) {
    return shadows.map((shadow, i) => {
      const shadowID = `${id}_${shadow[3]}`;

      let shadowGlyph = this.getGlyph(shadowID);
      const options = {
        isPictographic: false,
        staticEffects: -1,
        decors: -1,
        id: shadowID,
        shadow: i,
      };

      if (!shadowGlyph) {
        shadowGlyph = {...glyph, ...options};
        this.glyphs.push(shadowGlyph);
      }

      return this.calcGlyphSizes(shadowGlyph, true, i);
    });
  }

  /**
   * 11.8. Calculate glyph sizes
   * @param {object} glyph
   * @param {boolean} isShadow
   * @param {number} shadowID
   * @return {object} glyph
   */
  calcGlyphSizes(glyph, isShadow, shadowID) {
    const {node} = glyph;

    const padding = isShadow ?
        node.shadowPadding[shadowID] : node.padding;

    glyph.size.width = glyph.size.left + glyph.size.right;
    glyph.size.height = glyph.size.top + glyph.size.bottom;
    const texSize = glyph.texSize = {
      padding,
      width: round(glyph.size.width) + padding[0] + padding[2],
      height: round(glyph.size.height) + padding[1] + padding[3],
    };

    // Save maximum size so don't recreate the texture for the shader
    this.maxGlyphWidth = max(this.maxGlyphWidth, texSize.width);
    this.maxGlyphHeight = max(this.maxGlyphHeight, texSize.height);

    return glyph;
  }

  /**
   * 11.9. Calculate maximum glyphs size
   * @param {object[]} glyphs
   * @return {DOMRect}
   */
  calcGlyphsMaxSize(glyphs) {
    return glyphs.reduce((result, glyph) => {
      expandRectSize(result, glyph.texSize);
      return result;
    }, minRect());
  }

  /**
   * 11.10. Calculate glyphs offset
   * @param {object[]} glyphs
   * @return {DOMRect}
   */
  calcGlyphsOffset(glyphs) {
    if (!glyphs.length) {
      return new Rect(0, 0, 0, 0);
    }
    return glyphs.reduce((result, glyph) => {
      const {node} = glyph;
      maxRectValues(result, new Rect(
          glyph.size.left,
          -(node.fontBaseline - glyph.size.top),
          glyph.size.right - glyph.size.rawWidth,
          (node.fontBaseline + glyph.size.bottom) - node.fontHeight,
      ));
      return result;
    }, new Rect(-Infinity, -Infinity, -Infinity, -Infinity));
  }

  /**
   * 11.11. Calculate texture size
   */
  calcTextureSize() {
    const size = {
      width: 128,
      height: 128,
    };
    let scaleDir = false;
    let fit = false;

    while (!fit) {
      fit = true;

      let usedPictographicCount = 0;
      let rowHeight = 0;
      let curLayer = 0;
      let curX = 0;
      let curY = 0;
      let startX = curX;
      let startY = curY;
      let startHeight = 0;

      for (let i = 0; i < this.glyphs.length; i++) {
        const glyph = this.glyphs[i];
        const padding = glyph.node.options.texturePadding;
        let {width, height} = glyph.texSize;
        width += padding;
        height += padding;

        // New row
        if ((curX + width) > size.width) {
          curX = 0;
          curY += rowHeight;
          rowHeight = height;

          if (width > size.height) {
            fit = false;
            break;
          }
        }

        // New layer
        if ((curY + height) > size.height) {
          if (curLayer < 2 &&
              height <= size.height &&
              !glyph.isPictographic) {
            curLayer++;
            curX = startX;
            curY = startY;
            rowHeight = startHeight;
            i--;
            continue;
          } else {
            fit = false;
            break;
          }
        }

        glyph.texPos = {x: curX + padding/2, y: curY + padding /2};
        glyph.layer = glyph.isPictographic ? 3 : curLayer;

        curX += width;
        rowHeight = max(rowHeight, height);

        // Pictographic rgba first reserved place for all layers
        if (glyph.isPictographic) {
          usedPictographicCount++;
          if (usedPictographicCount >= this.pictographicCount) {
            startX = curX;
            startY = curY;
            startHeight = rowHeight;
          }
        }
      }

      if (!fit) {
        if (!scaleDir) {
          size.width *= 2;
        } else {
          size.height *= 2;
        }
        scaleDir = !scaleDir;
      }
    }
    this.size = size;
  }

  /**
   * 11.12. Prepare texture sizes
   * @return {object}
   */
  prepareSizes() {
    let decorsPaddingX = 0;
    let decorsPaddingY = 0;

    this.wgltr.htmlText.nodes.forEach((node) => {
      const p = node.decorsPadding;
      decorsPaddingX = max(decorsPaddingX, p[0] + p[2]);
      decorsPaddingY = max(decorsPaddingY, p[1] + p[3]);
    });

    const maxWidth = glSize(this.maxGlyphWidth + decorsPaddingX);
    const maxHeight = glSize(this.maxGlyphHeight + decorsPaddingY);

    return {decorsPaddingX, decorsPaddingY, maxWidth, maxHeight};
  }

  /**
   * 11.13. Load decorations images
   */
  loadDecors() {
    loadImages(this.decorsList, (waited) => {
      if (waited) {
        this.updateTexture();
        this.gl.resize();
        this.wgltr.text.updateBuffers();
      }
    });
  }

  /**
   * 11.14. Calculate decorations texture sizes
   */
  calcDecors() {
    this.decorsList = this.wgltr.htmlText.options.reduce((result, options) => {
      const decors = getGLEffects(options, 'decors');

      !options.inherit.decors && decors.forEach(({args}) => {
        result.push({
          type: args[0],
          url: (args[0] === 'dimage') ? args[1] : '',
          args: args,
        });
      });
      return result;
    }, []);

    const count = sqrt(this.decorsList.reduce((result, decor) => {
      return result + (['dpattern', 'dimage'].indexOf(decor.type) >= 0 ? 1 : 0);
    }, 0));

    this.decorsSize = {
      count,
      rows: ceil(count),
      cols: round(count),
    };
  }

  /**
   * 11.15. Create decorations texture
   * @param {number} width
   * @param {number} height
   */
  updateDecorsTexture(width, height) {
    const {decorsList, decorsSize} = this;
    const {rows, cols} = decorsSize;
    if (decorsSize.count < 1) {
      return;
    }

    tmpCanvas.resize(glSize(rows * width), glSize(cols * height));
    midCanvas.resize(width, height);

    let count = -1;
    decorsList.forEach((decor) => {
      if (['dimage', 'dpattern'].indexOf(decor.type) >= 0) {
        count++;
      }
      if (decor.type === 'dimage' && !decor.image) {
        return;
      }

      const x = count % rows;
      const y = floor(count / rows);

      midCanvas.clear();
      if (decor.type === 'dimage') {
        const image = decor.image;
        let [,,,,, dprScale, align, repeat, sx, sy, tx, ty] = decor.args;

        tx = round(tx * dpr);
        ty = round(ty * dpr);

        if (!dprScale) {
          sx /= dpr;
          sy /= dpr;
        }

        const pattern = CanvasPatterns.createPattern('image', false, repeat,
            image.width * sx, image.height * sy, '#000', image);

        const [ax, ay] = alignByStr(
            align, width, height,
            image.width * dpr * sx,
            image.height * dpr * sy);

        tx += ax + x * width;
        ty += ay + y * height;

        tmpCanvas.ctx.save();
        tmpCanvas.ctx.translate(tx, ty);
        tmpCanvas.style(pattern);
        tmpCanvas.ctx.fillRect(x * width - tx, y * height - ty, width, height);
        tmpCanvas.style('#fff');
        tmpCanvas.ctx.restore();
      } else if (decor.type === 'dpattern') {
        const [,,,, dprScale] = decor.args;
        midCanvas.drawDecorations(decor.args[5], dprScale, width, height);
        tmpCanvas.ctx.drawImage(midCanvas.canvas, x * width, y * height);
      }
    });

    this.gl.createTexture(tmpCanvas.canvas, 2, this.gl.gl.RGBA);
  }

  /**
   * 11.16. Update WebGL texture
   */
  updateTexture() {
    const {maxWidth, maxHeight} = this.prepareSizes();
    const {size, gl, shader} = this;
    const ctx = tmpCanvas.ctx;
    const mctx = midCanvas.ctx;

    // WebGL 1px
    const wpx = 2 / size.width;
    const wpy = 2 / size.height;

    gl.use(shader.program);
    this.updateDecorsTexture(maxWidth, maxHeight);
    tmpCanvas.resize(maxWidth, maxHeight);
    midCanvas.resize(maxWidth, maxHeight);

    // Create glyph texture
    gl.createTexture(undefined, 0, gl.gl.RGBA, maxWidth, maxHeight);
    gl.prepareDecorations(size.width, size.height);

    for (let i = 0; i < this.glyphs.length; i++) {
      const glyph = this.glyphs[i];
      const {x, y} = glyph.texPos;

      // Draw glyph on texture
      const {character, node} = glyph;
      const {width, height, padding} = glyph.texSize;
      const options = node.options;
      let {left, top} = glyph.size;

      [left, top] = [
        round(left + padding[0]),
        round(top + padding[1]),
      ];

      // Set up canvas
      tmpCanvas.clear();
      ctx.textBaseline = 'alphabetic';
      mctx.font = ctx.font = node.font;

      // Red - glyph, green - decorations mask, blue - decorations
      // Pictographic has RGB color then draw apart from other glyphs
      const isPictographic = glyph.isPictographic && glyph.shadow === undefined;
      if (isPictographic) {
        ctx.fillText(character, left, top);
      } else {
        // Draw shadow without decorations and stroke
        if (glyph.shadow !== undefined) {
          tmpCanvas.fill('#000');
          tmpCanvas.shadow(
              0, 2000,
              node.shadow[glyph.shadow][3] * dpr,
              '#f00',
          );
          ctx.fillText(character, left, top - 2000);
          ctx.shadowColor = 'transparent';
        } else {
          const tc = options.textureColor;
          tmpCanvas.style(tc.toLowerCase() === 'currentcolor' ?
              colorToHex(node.color) : tc);

          if (options.stroke) {
            const type = options.stroke;
            const width = options.strokeWidth;
            const blur = options.strokeBlur;

            ctx.lineWidth = width * dpr;
            if (blur > 0) {
              tmpCanvas.shadow(0, 2000, blur * dpr, '#f00');
              ctx.strokeText(character, left, top - 2000);
              ctx.shadowColor = 'transparent';
            } else {
              ctx.strokeText(character, left, top);
            }

            // Glyph mask
            if (type === 'in' || type === 'out') {
              tmpCanvas.gco(`destination-${type}`);
              ctx.fillText(character, left, top);
            }
          } else {
            ctx.fillText(character, left, top);
          }

          tmpCanvas.gco('source-in');
          tmpCanvas.fill('#f00');
          tmpCanvas.gco('screen');
          tmpCanvas.fill('#000');

          if (options['decors'].length) {
            midCanvas.clear();
            midCanvas.style('#0f0');
            mctx.fillText(character, left, top);
            ctx.drawImage(midCanvas.canvas, 0, 0);

            if (options.decorsStroke) {
              const width = options.decorsStrokeWidth;
              const blur = options.decorsStrokeBlur;

              midCanvas.clear();
              midCanvas.style('#00f');
              mctx.fillText(character, left, top);

              midCanvas.gco('destination-out');
              mctx.lineWidth = width * dpr;
              if (blur > 0) {
                midCanvas.shadow(0, 2000, blur * dpr, '#f00');
                mctx.strokeText(character, left, top - 2000);
                mctx.shadowColor = 'transparent';
              } else {
                mctx.strokeText(character, left, top);
              }
              midCanvas.gco();
              ctx.drawImage(midCanvas.canvas, 0, 0);
            }
          }

          tmpCanvas.gco();
        }
      }

      // update shader texture
      gl.updateTexture(ctx.canvas);

      let mode = (!options.stroke || options.stroke === 'in') ? 1 : 0;

      switch (options.decorsStroke) {
        case 'all':
          mode = 2;
          break;
        case 'in':
          mode = 3;
          break;
        case 'out':
          mode = 4;
          break;
        default:
      }

      shader.uniform([
        ['T', Math.random() * 100],
        ['E', [options.uniqueID, mode, isPictographic ? 1 : 0]],
        ['NS', [
          maxWidth / this.wgltr.text.noiseWidth,
          maxHeight / this.wgltr.text.noiseHeight,
        ]],
        ['U', [
          1 / (maxWidth / dpr),
          1 / (maxHeight / dpr),
          size.width / dpr,
          size.height / dpr,
        ]],
      ]);

      const vx = x * wpx - 1;
      const vw = (x + width) * wpx - 1;
      const vy = y * wpy - 1;
      const vh = (y + height) * wpy - 1;

      const suvw = width / maxWidth;
      const suvh = height / maxHeight;
      const l = glyph.layer;

      // Decorations UV
      let duvx = ((1 - suvw) / 2);
      let duvy = ((1 - suvh) / 2);
      duvx -= duvx % (1 / maxWidth);
      duvy -= duvy % (1 / maxHeight);
      const duvw = duvx + suvw;
      const duvh = duvy + suvh;

      const [indices, aC, aCC, aUVLI, aP, aAP] =
        shader.getVbo('indices', 'C', 'CC', 'UVLI', 'P', 'AP');

      indices.push(0, 1, 2, 0, 2, 3);
      aCC.push(
          1, 1, 0, 0,
          0, 1, 0, 0,
          0, 0, 0, 0,
          1, 0, 0, 0,
      );
      aC.push(
          vw, vh, duvw, duvh,
          vx, vh, duvx, duvh,
          vx, vy, duvx, duvy,
          vw, vy, duvw, duvy);

      aUVLI.push(
          suvw, suvh, l, 0,
          0, suvh, l, i,
          0, 0, l, 0,
          suvw, 0, l, 0);

      arrayFill(aP, 0, 0, 0, 0);
      arrayFill(aAP, i, 0, width, height);
      shader.updateVbo();

      gl.processDecorations(shader);
    }

    this.texture = gl.decorTexture;
    shader.cleanVBO();
    gl.finishDecorations();
    gl.bindTexture(0, this.texture);
    gl.use(this.wgltr.text.shader.program);
  }

  /**
   * 11.17. Update texture shader
   */
  updateShader() {
    const {gl, shader} = this;
    const options = this.wgltr.htmlText.options;
    const {rows, cols} = this.decorsSize;

    let effects = '';
    const freq = [];
    const patternsGLSL = glDecorations(options, rows, cols, freq);

    options.forEach((o) => {
      if (o.inherit.staticEffects) {
        return;
      }
      const result = glEffectsToGLSL(
          'staticEffects', 'frag', getGLEffects(o, 'staticEffects'));
      effects += glCondIndex(
          result.glsl,
          'uE.x',
          Options.getIDs(o, 'backgrounds'));
      freq.push(...result.req);
    });

    const vert = '' +
        'gl_Position=vec4(aC.xy,0.,1.);' +
        'vVP=vec4(pxPos(aC.xy),pxPos(aC.zw));' +
        'vUP=aCC.xy;' +
        'vDUV=aC.zw;';

    const frag =
        `${_vec4}tC=vec4(1.);` +
        // One pixel on texture
        `${_vec2}ep=vU.xy,` +
        `uv=vUVLI.xy;` +
        (effects || '') +
        `${_vec4}tA=texture2D(uSampler,uv),_t;` +

        // If its pictographic
        // Then add neighbour pixel color for properly interpolation
        `if(uE.z>.5&&tA.w<=.01){` +
        `for(int x=-1;x<1;x++){for(int y=-1;y<1;y++){` +
        `_t=texture2D(uSampler,uv+vU.xy*vec2(x,y));` +
        `if(_t.w>=.01){tA.xyz=_t.xyz;}` +
        `}}` +

        // Fix pictographic glyphs edge background
        `_t=texture2D(uSampler,uv+vec2(vU.x,.0));` +
        `if(_t.w>=.01){tA.xyz=_t.xyz;}else{` +
        `_t=texture2D(uSampler,uv+vec2(-vU.x,.0));` +
        `if(_t.w>=.01){tA.xyz=_t.xyz;}else{` +
        `_t=texture2D(uSampler,uv+vec2(.0,vU.y));` +
        `if(_t.w>=.01){tA.xyz=_t.xyz;}else{` +
        `_t=texture2D(uSampler,uv+vec2(.0,-vU.y));` +
        `if(_t.w>=.01){tA.xyz=_t.xyz;}}}}}` +

        'uv=vDUV.xy;' +
        `${_float}rt=tA.x,ro=1.,r=.0,gp=.0,tmp=.0;` +
        `${_vec4}tD;` +

        patternsGLSL +

        // Layer
        'lowp int l=int(vUVLI.z);' +
        `${_vec4}m=vec4(1.);` +
        'if(l>=3){gl_FragColor=tA;return;}' +
        'if(l==0)m=vec4(1.,0.,0.,1.);' +
        'else if(l==1)m=vec4(0.,1.,0.,1.);' +
        'else if(l==2)m=vec4(0.,0.,1.,1.);' +

        // Decorations out
        'if((uE.y>1.5&&uE.y<2.5)||uE.y>3.5){' +
        'rt=min(rt,rt*(ro+(1.-tA.z)));}else{' +
        'rt=rt*ro;}' +

        // Decorations in
        `${_float}taz=((uE.y<1.5)||uE.y>3.5)?tA.y:tA.z;` +
        'rt=(uE.y<.5)?rt+r*taz:min(rt+r*taz,max(taz,rt));' +

        // Result
        'gl_FragColor=vec4(m.xyz*rt*tC.w,1.);';

    shader.refresh(this._vertexDone ? false : vert, [], frag, freq);

    gl.use(shader.program);
    shader.uniform([
      ['Sampler', 0],
      ['Noise', 1],
      ['Decors', 2],
      ['T', 1],
      ['AR', 1],
      ['CO', [0, 0]],
      ['M', [0, 0, 0]],
    ]);
    this._vertexDone = true;

    gl.use(this.wgltr.text.shader.program);
  }

  /**
   * 11.18. Destroy font texture
   */
  destroy() {
    this.gl.gl.deleteTexture(this.texture);
  }
}


/**
 * 12. WebGL Text
 * @internal
 */
class Text {
  /**
   * 12.1. Initialization
   * @param {WGLTR} wgltr
   */
  constructor(wgltr) {
    this.wgltr = wgltr;
    this.gl = wgltr.gl;
    this.backgrounds = [];
    this.time = 0;
    this.chars = [];
    this.rect = new Rect();

    this.isAppeared = false;
    this.isManualAppear = false;
    this.manualStartTime = Infinity;
    this.isManualDisappear = false;

    this.noiseWidth = 128;
    this.noiseHeight = 128;
    this.gl.createNoiseTexture(1, this.noiseWidth, this.noiseHeight);
    this.refreshBackgrounds(true);
    this.refreshScrolls();
    this.createShader();
    this.updateShader();
    this.updateMouseUniform(-10000, -10000, 0);
    this.updateBackgroundsUniforms();
    this.updateScrollUniform();
  }

  /**
   * 12.2. Refresh
   * @param {boolean} soft
   */
  refresh(soft = false) {
    this.refreshChars();
    this.refreshUV();
    this.calcSizes(soft);
    this.charsPxToPercent();
    this.setLineCoords();
  }

  /**
   * 12.3. Refresh chars position and delay
   */
  refreshChars() {
    const {htmlText, fontTexture} = this.wgltr;
    const {appearReverse, appearRandom} = this.wgltr.options;

    const shadowChars = [];
    const uniqueChars = [];

    const order = new Order();
    order.start(appearReverse, appearRandom, false);

    this.changedLines = [];
    let changedLine = -1;
    let changedWordLine = -1;

    htmlText.words.forEach((word) => word.glChars = []);
    htmlText.chars.forEach((c) => {
      const {rect, start, end, word, str} = c;
      const {node, line} = word;
      const glyph = fontTexture.getGlyph(fontTexture.getID(node, str));
      const {left, bottom} = glyph.size;
      const {baselineOffset, options} = node;
      const o = options;

      c.options = o;
      c.node = node;
      c.glyph = glyph;

      // Appear or disappear
      if (node.changeParent) {
        const change = node.changeParent.change;

        // If text is appeared and change starts
        if (change.previous !== change.current) {
          c.isAppear = change.current === node.changeIndex;
          c.isDisappear = change.previous === node.changeIndex;
        }
        if (!this.isAppeared || this.isManualDisappear) {
          c.isAppear = false;
          c.isDisappear = false;
        }
      }

      // Start new order
      start && start.forEach((node) => {
        // Skip change because of internal elements is changing
        if (node.change) {
          return;
        }

        const changeParent = !!(node.parent && node.parent.change);
        const keepOrder = node.changeParent &&
          node.changeParent.change.keepOrder;

        const random = Options.getAppear(
            'Random', node, c.isAppear, c.isDisappear);
        const reverse = Options.getAppear(
            'Reverse', node, c.isAppear, c.isDisappear);
        const reset = c.reset = Options.getAppear(
            'Reset', node, c.isAppear, c.isDisappear, c.reset);

        const skip = !reverse && !random && !reset &&
            !!node.options.inherit.appearCount && !changeParent;

        order.start(node.options, reverse, random, reset, skip, keepOrder);
      });

      // Calculate character position
      let x = rect.x - left;
      let y = rect.y + baselineOffset * rect.height + bottom;

      if (options.roundPosition) {
        [x, y] = [round(x), round(y)];
      }

      // Check line is changed for animation
      c.oldLineX = -10000;
      c.newLineX = -10000;

      if (c.line !== line && line > 0 && c.line > 0) {
        if (changedLine !== c.line || changedWordLine !== line) {
          this.changedLines.push([]);
          changedLine = c.line;
          changedWordLine = line;
        }
        this.changedLines[this.changedLines.length-1].push(c);
      }

      // Get appear options
      const isManual = o.appear === 'appeared' &&
          (this.isManualAppear || this.isManualDisappear);

      if (o.appear === true || o.appear === 'manual' || isManual ||
          c.isAppear || c.isDisappear) {
        [c.speed, c.count, c.delay, c.async] = [
          options.appearSpeed,
          options.appearCount,
          options.appearDelay,
          options.appearAsync,
        ];

        if (c.isAppear) {
          [c.speed, c.count, c.delay] = [
            inherit(o.changeAppearSpeed, c.speed),
            inherit(o.changeAppearCount, c.count),
            inherit(o.changeAppearDelay, c.delay),
            inherit(o.changeAppearAsync, c.async),
          ];
        }
        if (c.isDisappear) {
          [c.speed, c.count, c.delay] = [
            inherit(o.disappearSpeed, c.speed),
            inherit(o.disappearCount, c.count),
            inherit(o.disappearDelay, c.delay),
            inherit(o.disappearAsync, c.async),
          ];
        }
      } else {
        c.speed = -1;
        c.count = 0;
        c.delay = 0;
      }

      if (c.asyncDelay === undefined) {
        c.asyncDelay = c.async ? c.async * random() : 0;
      }

      // Define the type of effect: appear & disappear or changeAppear
      c.effectsType = 0;
      if (c.isDisappear && o.disappearEffects.length) {
        c.effectsType = 2;
      } else if ((c.isAppear || c.isDisappear) &&
          o.changeAppearEffects.length) {
        c.effectsType = 1;
      }

      // Calculate old position for animation
      const visible = c.visible || c.isDisappear;

      if (visible && c.oldPos &&
          c.oldPos.x - 2 < x && c.oldPos.x + 2 > x &&
          c.oldPos.y - 2 < y && c.oldPos.y + 2 > y) {
        c.pos = c.oldPos;
      } else {
        c.oldPos = visible ? (c.oldPos || {x, y}) : {x, y};
        c.pos = {x, y};
      }

      if (c.moved) {
        c.oldPos = c.pos;
      }

      c.moved = true;
      c.visible = htmlText.isChangeCurrent(node);
      c.oldLine = c.line;
      c.line = line;

      // Background id
      c.bgID = options.uniqueID;
      if (c.glyph.isPictographic) {
        c.bgID = -1;
      }

      // Speed
      const isChange = c.isAppear || c.isDisappear;
      if (!options.appear && !isChange && c.visible) {
        c.speed = -1;
      }
      if (!c.isDisappear && !c.visible) {
        c.speed = 1;
      }

      order.push(c);

      if (end) {
        order.process(end.reduce((count, node) => {
          return count + (node.change ? 0 : 1);
        }, 0));
      }

      node.shadow && node.shadow.forEach((shadow, i) => {
        const shadowChar = {
          parent: c,
          node: node,
          shadowID: i,
          glyph: fontTexture.getGlyph(
              fontTexture.getID(node, c.str) + '_' + shadow[3]),
        };
        shadowChars.push(shadowChar);
        word.glChars.push(shadowChar);
      });

      word.glChars.push(c);
      uniqueChars.push(c);
    });

    order.process(1);

    htmlText.changeNodes.forEach(({change}) => {
      change.appear = [];
      change.disappear = [];
      change.keepOrder = false;
    });

    this.totalTime = 0;
    this.calcCharsTimes(order.result());
    this.calcChangeTimes();

    // Shadow first on webgl canvas
    this.chars = [
      ...shadowChars.sort((a, b) => a.shadowID - b.shadowID),
      ...uniqueChars,
    ];
    this.refreshTime = this.time + htmlText.resizeTimer;
  }

  /**
   * 12.4. Calculate delays for each characters group
   * @param {array} groups
   */
  calcCharsTimes(groups) {
    let total;
    let totalTime;
    let changeTotal;
    let changeTime;
    let prevChar;
    let curChange;
    let curChangeIndex;

    groups.forEach((group, i) => {
      const {speed, delay, asyncDelay, options, visible, node} = group[0];
      const {start, reset} = group;
      const {isAppear, isDisappear} = group[0];

      if (!visible && !isDisappear) {
        return;
      }

      if (prevChar && prevChar.speed > 0) {
        const speedTime = prevChar.speed * (1 - delay);
        if (isAppear || isDisappear) {
          changeTime -= speedTime;
        } else {
          totalTime -= speedTime;
        }
      }

      const changeReset = reset ||
        curChange !== node.changeParent ||
        curChangeIndex !== node.changeIndex;

      curChange = node.changeParent;
      curChangeIndex = node.changeIndex;

      const funcReset = start && start.some(({options: o}) => {
        return !o.inherit.appearFunction || o.appearFunctionReset;
      });

      if (i === 0 || reset) {
        totalTime = options.appearStartDelay;
        if (options.appear === 'manual' ||
            this.isManualAppear ||this.isManualDisappear) {
          totalTime += this.manualStartTime;
        }
        total = {start: totalTime, end: 0};
      }

      if (changeReset || reset) {
        changeTime = curChange ? curChange.change.time : 0;
        changeTotal = {start: changeTime, end: 0};
      }

      if (funcReset) {
        total = {start: totalTime, end: 0};
        changeTotal = {start: changeTime, end: 0};
      }

      if (changeReset || reset || funcReset) {
        if (isAppear) {
          curChange.change.appear.push(changeTotal);
        }
        if (isDisappear) {
          curChange.change.disappear.push(changeTotal);
        }
      }

      group.forEach((c) => {
        c.total = total;
        c.delay = totalTime;
        c.changeTotal = changeTotal;
        c.changeDelay = changeTime;

        if (prevChar) {
          const asyncAdd = asyncDelay * speed;

          c.delay += asyncAdd;
          c.delay = max(c.delay, total.start);

          c.changeDelay += asyncAdd;
          c.changeDelay = max(c.changeDelay, changeTotal.start);

          if (asyncAdd > 0) {
            totalTime += asyncAdd;
            changeTime += asyncAdd;
          }
        }
      });

      if (isAppear || isDisappear) {
        changeTime += speed;
        changeTotal.end = max(changeTotal.end, changeTime);
      } else {
        speed > 0 && (totalTime += speed);
        total.end = max(total.end, totalTime);
      }

      if (total.end < Infinity) {
        this.totalTime = max(this.totalTime, total.end);
      }
      prevChar = group[0];
    });
  }

  /**
   * 12.5. Calculate change additive and total times
   */
  calcChangeTimes() {
    const {htmlText} = this.wgltr;
    htmlText.changeNodes.forEach(({change, options}) => {
      // Disappear wait for text is moved if next is wider
      change.disappearAdditive = change.isWider ? options.changeMove : 0;
      change.disappearAdditive += htmlText.resizeTimer;

      change.disappear.forEach((disappear) => {
        disappear.start += change.disappearAdditive;
        disappear.end += change.disappearAdditive;
      });

      // Max disappear time
      change.disappearEnd = max(
          change.disappearAdditive,
          change.disappear.reduce((result, disappear) => {
            return max(result, disappear.end || 0);
          }, 0));

      // Add disappear time to appear
      change.appearAdditive = max(
          change.disappearAdditive,
          change.disappearEnd - change.time + options.changeDelay);

      change.appear.forEach((appear) => {
        appear.start += change.appearAdditive;
        appear.end += change.appearAdditive;
      });

      change.appearStart = !change.appear.length ? 0 :
        change.appear.reduce((r, a) => min(r, a.start || 0), Infinity);
      change.appearEnd = change.appear.reduce((r, a) => max(r, a.end), 0);

      change.textResizeTime = (change.isWider ? 0 : change.disappearEnd);
      change.changeEnd = max(change.appearEnd, change.disappearEnd);

      if (change.started) {
        const timeEnd = change.changeEnd +
            (change.isWider ? 0 : options.changeMove);
        change.changeStart = max(timeEnd, timeEnd + options.changeTime);
      } else {
        change.changeStart = this.totalTime + options.changeStartTime;
      }
      if ((options.appear === 'manual' &&
          !this.isManualAppear) || this.isManualDisappear) {
        change.changeStart = Infinity;
      }
    });
  }

  /**
   * 12.6. Turn chars px position to WebGL percent
   */
  charsPxToPercent() {
    const {rect} = this;
    const aspect = rect.width / rect.height;

    this.chars.forEach((c) => {
      const {size, texSize} = c.glyph;
      const {x, y} = c.parent ? c.parent.pos : c.pos;
      const {x: ox, y: oy} = c.parent ? c.parent.oldPos : c.oldPos;
      const {width: cw, height: ch} = rect;
      const {width: tw, height: th} = texSize;
      const p = c.node.glPadding;
      const dp = c.node.glDynamicPadding;

      let xOffset = size.width / 2 + this.offsetX;
      let yOffset = size.height / 2 - this.offsetY;

      if (c.node.shadow && c.glyph.shadow !== undefined) {
        const [, shadowX, shadowY] = c.node.shadow[c.glyph.shadow];
        xOffset += shadowX * dpr;
        yOffset -= shadowY * dpr;
      }

      c.centerX = ((x + xOffset) / cw * 2 - 1) * aspect;
      c.centerY = -((y - yOffset) / ch * 2 - 1);

      c.oldCenterX = ((ox + xOffset) / cw * 2 - 1) * aspect;
      c.oldCenterY = -((oy - yOffset) / ch * 2 - 1);

      c.maxWidth = c.node.maxGlyphSize.width / cw * 2 * aspect;

      c.size = {
        x: ((p[2] / 2 - p[0] / 2) / cw * 2) * aspect,
        y: -((p[3] / 2 - p[1] / 2) / ch * 2),
        width: ((tw + p[0] + p[2]) / cw * 2) * aspect,
        height: (th + p[1] + p[3]) / ch * 2,
      };
      c.dynamicSize = {
        x: ((dp[2] / 2 - dp[0] / 2) / cw * 2) * aspect,
        y: -((dp[3] / 2 - dp[1] / 2) / ch * 2),
        width: ((tw + dp[0] + dp[2]) / cw * 2) * aspect,
        height: (th + dp[1] + dp[3]) / ch * 2,
      };
    });
  }

  /**
   * 12.7. Set changed lines coords for using in vertex shader
   */
  setLineCoords() {
    this.changedLines.forEach((chars) => {
      let oldX;
      let newX;
      let first = chars[0];
      let last = chars[chars.length-1];
      const isRTL = first.word.isRTL;

      if (isRTL) {
        [first, last] = [last, first];
      }

      if ((first.line > first.oldLine) ^ isRTL) {
        oldX = last.oldCenterX + last.maxWidth / 2;
        newX = first.centerX - first.maxWidth / 2;
      } else {
        oldX = first.oldCenterX - first.maxWidth / 2;
        newX = last.centerX + last.maxWidth / 2;
      }
      chars.forEach((c) => {
        c.oldLineX = oldX;
        c.newLineX = newX;
      });
    });
  }

  /**
   * 12.8. Calculate canvas, word, line and wrap sizes
   * @param {boolean} soft
   */
  calcSizes(soft = false) {
    const {htmlText} = this.wgltr;
    const {dprRect} = htmlText;

    let cP = this.wgltr.options.canvasPadding;
    if (!Array.isArray(cP)) {
      cP = [cP, cP, cP, cP];
    }
    cP = cP.map((v) => v * dpr);

    // Reset previous sizes
    htmlText.options.forEach(({data}) => {
      data.glyphRect = maxRect();
      data.wordRect = maxRect();
      data.lineRect = maxRect();
      data.wrapRect = maxRect();
    });
    htmlText.nodes.forEach((node) => {
      node.maxGlyphSize = new Rect(0, 0, 0, 0);
    });

    // Calculate canvas rect
    const prevRect = this.rect;
    this.rect = maxRect();
    const rawRect = maxRect();

    htmlText.words.forEach((word) => {
      const glyphs = word.chars.map((c) => c.glyph);
      word.offset = this.calcRectOffset(word.node, glyphs);
      const {full} = word.offset;
      const nrect = word.textNode.rect;

      const nodeRect = new Rect(
          nrect.x - full.x,
          nrect.y - full.y,
          nrect.width + full.x + full.width,
          nrect.height + full.y + full.height,
      );

      expandRect(rawRect, nrect);
      expandRect(this.rect, nodeRect);
    });

    this.rect.width += cP[1] + cP[3];
    this.rect.height += cP[0] + cP[2];
    this.rect.width = round(this.rect.width);
    this.rect.height = round(this.rect.height);

    // Change canvas position to contain all text when text moving animation
    if (soft && htmlText.prevDprRect.width >= dprRect.width) {
      const prevX = htmlText.prevDprRect.x - htmlText.offset;

      // Avoid wrong position after dynamica mutation
      if (prevRect.width <= this.rect.width) {
        dprRect.x = prevX;
      }
    }

    this.rect.x = dprRect.x - (rawRect.x - this.rect.x) - cP[3];
    this.rect.y = dprRect.y - (rawRect.y - this.rect.y) - cP[0];

    const diff = (htmlText.elRect.x - htmlText.resizeElRect.x) * dpr;
    this.canvasReservedWidth = dprRect.x - htmlText.minNodeOffset + diff;

    this.offsetX = -round(this.rect.x);
    this.offsetY = -round(this.rect.y);

    // Maximum [word, line, wrap] sizes
    const prevLinesRects = this.linesRects || [];
    this.wordsRects = [];
    this.linesRects = [];

    htmlText.words.forEach((word) => {
      const {fragment, padding} = word.offset;

      if (word.rect.width <= 0) {
        this.wordsRects.push([0, 0, 0, 0]);
      } else {
        const rect = new Rect(
            (word.rect.x - this.rect.x - fragment.x) / dpr,
            (word.rect.y - this.rect.y - fragment.y) / dpr,
            (word.rect.width + fragment.x + fragment.width) / dpr,
            (word.rect.height + fragment.y + fragment.height) / dpr,
        );

        let lineIndex = -1;
        if (word.line > 0) {
          lineIndex = word.line - 1;
          if (!this.linesRects[lineIndex]) {
            this.linesRects[lineIndex] = maxRect();
          }
          expandRect(this.linesRects[lineIndex], rect);
        }

        let options = word.node.options;
        const gsize = word.node.maxGlyphSize;

        while (options.node) {
          const {data} = options;

          expandRectSize(data.glyphRect, new Rect(0, 0,
              (gsize.width + padding[0] + padding[2]) / dpr,
              (gsize.height + padding[1] + padding[3]) / dpr));

          expandRectSize(data.wordRect, rect);
          if (lineIndex > -1) {
            expandRectSize(data.lineRect, this.linesRects[lineIndex]);
          }
          expandRect(data.wrapRect, rect);
          options = options.parent;
        }

        this.wordsRects.push(rectToArray(rect));
      }
    });

    this.linesRects = this.linesRects.map((rect) => rectToArray(rect));
    // Text can be on old line after change so restore previous last lines
    this.linesRects.push(
        ...prevLinesRects.slice(this.linesRects.length, prevLinesRects.length),
    );
  }

  /**
   * 12.9. Calculate rect offsets
   * @param {object} node - rect node information
   * @param {object[]} glyphs - glyphs inside rect
   * @return {object}
   */
  calcRectOffset(node, glyphs) {
    const fovScale = (40 / max(40, this.wgltr.options.fov));
    const o = node.options;
    const {padding: p, glDynamicPadding: dp} = node;
    const padding = dp.map((v, i) => v + p[i]);
    const maxGlyphsSize = this.wgltr.fontTexture.calcGlyphsMaxSize(glyphs);
    const glyphsOffset = this.wgltr.fontTexture.calcGlyphsOffset(glyphs);

    expandRectSize(node.maxGlyphSize, maxGlyphsSize);

    // Shadow offset
    if (node.shadow) {
      const shadowMax = [0, 0, 0, 0];
      node.shadow.forEach((shadow) => {
        let [, shadowX, shadowY, blur] = shadow;
        blur *= dpr;
        shadowMax[0] = max(shadowMax[0], -shadowX + blur);
        shadowMax[1] = max(shadowMax[1], -shadowY + blur);
        shadowMax[2] = max(shadowMax[2], shadowX + blur);
        shadowMax[3] = max(shadowMax[3], shadowY + blur);
      });
      arrayAdd(padding, shadowMax);
    }

    const effectsPadding = [0, 0, 0, 0];
    arrayAdd(effectsPadding, padding);

    // Glyphs offset
    arrayAdd(padding, rectToArray(glyphsOffset));
    const fragment = new Rect(...padding);
    const result = minRect();
    // Calculate vertex effects offset
    [
      'effects',
      'mouseEffects',
      'scrollEffects',
      'appearEffects',
      'changeAppearEffects',
      'disappearEffects',
    ].forEach((category) => {
      const offset = [0, 0, 0, 0];
      const scale = [1, 1];
      const zscale = [0, 0];
      const effectPadding = padding.map((v) => v);
      let translateUsed = false;
      let rotateUsed = false;
      let rotateAfterTranslate = false;

      const vertEffects = getGLEffects(o, category);
      vertEffects.forEach((effect, i) => {
        const [name, ...args] = effect.args;
        const info = getEffectInfo(name, category);
        const padding = info && info.paddingVert;
        if (padding) {
          const infoArgs = getEffectArgs(info, category, args);
          const result = padding(category, ...infoArgs, effect.scale);

          if (result.trans) {
            const [tx, ty, tz] = result.trans;
            arrayAdd(offset, [
              max(0, -tx),
              max(0, -ty),
              max(0, tx),
              max(0, ty),
            ]);

            if (tz) {
              zscale[0] += (tz * 2 * dpr) / fovScale;
              zscale[1] += (tz / 3 * dpr) / fovScale;
            }
            translateUsed = true;
          }
          if (result.scale) {
            const [sx, sy] = result.scale;
            scale[0] += scale[0] * sx;
            scale[1] += scale[1] * sy;
            arrayAdd(offset, [
              offset[0] * sx,
              offset[1] * sy,
              offset[2] * sx,
              offset[3] * sy,
            ]);
          }
          if (result.offset) {
            arrayAdd(offset, result.offset);
          }
          if (result.rotate) {
            rotateUsed = true;
            if (translateUsed) {
              rotateAfterTranslate = true;
            }
          }
        }
      });

      // Add additive size when rotating used
      if (rotateUsed) {
        let maxSize =
            max(maxGlyphsSize.width, maxGlyphsSize.height) * (.4 / fovScale);

        // If rotate after trans or scale then add additive size
        if (rotateAfterTranslate) {
          maxSize +=
              max(max(max(offset[0], offset[1]), offset[2]), offset[3]) / 2;
        }
        arrayAdd(effectPadding, [maxSize, maxSize, maxSize, maxSize]);
      }

      const scaleX =
          (maxGlyphsSize.width * (scale[0] - 1) / 2) + max(zscale[0], 0);
      const scaleY =
          (maxGlyphsSize.height * (scale[1] - 1) / 2) + max(zscale[1], 0);

      // Find maximum padding
      result.x = max(result.x,
          effectPadding[0] + (offset[0] * dpr) + scaleX);
      result.y = max(result.y,
          effectPadding[1] + (offset[1] * dpr) + scaleY);
      result.width = max(result.width,
          effectPadding[2] + (offset[2] * dpr) + scaleX);
      result.height = max(result.height,
          effectPadding[3] + (offset[3] * dpr) + scaleY);
    });

    return {
      glyphs: glyphsOffset,
      padding: effectsPadding,
      fragment: fragment,
      full: result,
    };
  }

  /**
   * 12.10. Calculate glyphs webgl uv coordinates
   */
  refreshUV() {
    const size = this.wgltr.fontTexture.size;

    this.chars.forEach((c) => {
      const {glPadding: p, glDynamicPadding: dp} = c.node;
      const {width: tw, height: th} = size;
      const {texPos, texSize} = c.glyph;
      const {x, y} = texPos;

      const uvx = x / tw;
      const uvy = y / th;
      const uvw = uvx + (texSize.width / tw);
      const uvh = uvy + (texSize.height / th);

      c.uvPaddings = [uvx, uvy, uvw, uvh];
      c.uv = [
        uvx - (p[0] / tw),
        uvy - (p[1] / th),
        uvw + (p[2] / tw),
        uvh + (p[3] / th),
      ];
      c.uvDynamic = [
        uvx - (dp[0] / tw),
        uvy - (dp[1] / th),
        uvw + (dp[2] / tw),
        uvh + (dp[3] / th),
      ];
    });
  }

  /**
   * 12.11. Refresh dynamic backgrounds
   * @param {boolean} isInit
   */
  refreshBackgrounds(isInit = false) {
    const {width, height} = this.rect;

    // Generate backgrounds
    this.backgrounds = [];
    this.wgltr.htmlText.options.forEach((options) => {
      const bgs = getGLEffects(options, 'backgrounds');
      !options.inherit.backgrounds && bgs.forEach(({args}) => {
        let [type, operation, opacity, size] = args;
        let fit = true;
        let invert = false;
        let dprScale = false;
        let align = 'topleft';
        let effects = [];

        switch (type) {
          case 'dradialGrad':
            [type, operation, opacity, size, fit, align] = args;
            break;
          case 'dpattern':
            [type, operation, opacity, size, fit, invert] = args;
            dprScale = args[6];
            effects = args[8];
            align = 'center';
            break;
          case 'dimage':
            [type,, operation, opacity, size, fit, invert] = args;
            dprScale = args[7];
            align = args[8];
            effects = args[14];
            break;
          default:
        }

        this.backgrounds.push({
          args, options, type, operation,
          opacity, fit, invert, align, dprScale,
          node: options.node,
          data: options.data,
          url: type === 'dimage' ? args[1] : '',
          effects,
          loaded: true,
          texture: undefined,
          size: glGetSizeIndex(size),
          usize: [1, 1],
          scale: [1, 1],
        });
      });
    });

    // Load backgrounds images
    loadImages(this.backgrounds, (waited) => {
      if (waited) {
        this.drawBackgrounds();
        this.updateBackgroundsUniforms();
      }
    });

    if (!isInit && width > 1 && height > 1) {
      this.drawBackgrounds();
    }
  }

  /**
   * 12.12. Create backgrounds textures
   */
  drawBackgrounds() {
    const {width, height} = this.rect;

    if (width < 1 || height < 1) {
      return;
    }

    this.backgrounds.forEach((bg, i) => {
      const {type, data, size, args} = bg;
      const sizes = [
        [width, height],
        [data.glyphRect.width * dpr, data.glyphRect.height * dpr],
        [data.wordRect.width * dpr, data.wordRect.height * dpr],
        [data.lineRect.width * dpr, data.lineRect.height * dpr],
        [data.wrapRect.width * dpr, data.wrapRect.height * dpr],
      ];
      let [bgWidth, bgHeight] = sizes[size];

      // If size is undefined because of change text is hidden
      // then set maximum glyph size
      if (!(bgWidth > 0 && bgHeight > 0)) {
        [bgWidth, bgHeight] = sizes[1];
      }

      // Make size as image
      if (type === 'dimage' && bg.image) {
        bgWidth = bg.image.width;
        bgHeight = bg.image.height;
      }

      const effectsPadding = [0, 0, 0, 0];

      // Add patterns effects padding
      if (type === 'dpattern' && bg.effects.length) {
        extendByEffects(effectsPadding, 'effects', getGLEffects(bg.effects));
        bgWidth += round(effectsPadding[0] + effectsPadding[2]);
        bgHeight += round(effectsPadding[1] + effectsPadding[3]);
      }

      // Calc canvas size
      const cwidth = glSize(bgWidth);
      const cheight = glSize(bgHeight);

      bg.scale = [
        (bgWidth / cwidth) - (1 / cwidth),
        (bgHeight / cheight) - (1 / cheight),
      ];
      bg.usize = [bgWidth / dpr, bgHeight / dpr];

      midCanvas.resize(cwidth, cheight);

      if (type === 'dlinearGrad') {
        const [,,,, angle, stops] = args;
        midCanvas.drawLinearGradient(angle,
            bgWidth / 2, bgHeight / 2,
            bgWidth, bgHeight, stops);
      }
      if (type === 'dradialGrad') {
        const [,,,,,, type, tx, ty, scale, stops] = args;
        midCanvas.drawRadialGradient(type,
            bgWidth / 2, bgHeight / 2,
            bgWidth, bgHeight, tx, ty, scale, stops);
      }
      if (type === 'dpattern') {
        bg.scaleX = 1 + (effectsPadding[0] + effectsPadding[2]) / bgWidth;
        bg.scaleY = 1 + (effectsPadding[1] + effectsPadding[3]) / bgHeight;
        midCanvas.drawDecorations(bg.args[7], bg.dprScale, bgWidth, bgHeight);
      }
      if (type === 'dimage' && bg.image) {
        bg.scaleX = args[10];
        bg.scaleY = args[11];

        bg.usize = [bg.image.width, bg.image.height];
        if (!bg.dprScale) {
          bg.usize[0] /= dpr;
          bg.usize[1] /= dpr;
        }
        midCanvas.ctx.drawImage(bg.image, 0, 0);
      }

      bg.texture = this.gl.createTexture(
          midCanvas.canvas, (2 + i), this.gl.gl.RGBA);
    });
  }

  /**
   * 12.13. Bind backgrounds textures
   */
  bindBackgrounds() {
    this.backgrounds.forEach((background, i) => {
      this.gl.bindTexture((2 + i), background.texture);
    });
  }

  /**
   * 12.14. Refresh scrolls values
   */
  refreshScrolls() {
    this.scrolls = [];
    let count = 0;
    this.wgltr.htmlText.options.forEach((options) => {
      if (options.inherit.scrollEffects) {
        return;
      }
      options.scrollEffects.forEach((a) => {
        this.scrolls.push({
          cur: 0,
          distance: a[1],
          fade: a[2],
        });
      });
      options.data.scrollEffects = count;
      count += options.scrollEffects.length;
    });
  }

  /**
   * 12.15. Process scrolls times
   * @param {number} et
   */
  processScrolls(et) {
    this.scrolls.forEach((scroll) => {
      if (scroll.cur !== 0) {
        scroll.cur -= et / scroll.fade * sign(scroll.cur);
        if (abs(scroll.cur) < (et / scroll.fade * 1.2)) {
          scroll.cur = 0;
        }
      }
    });
    this.updateScrollUniform();
  }

  /**
   * 12.16. Set scrolls strength
   * @param {number} strength
   */
  setScrollsStrength(strength) {
    this.scrolls.forEach((scroll) => {
      const s = min(1, max(-1, strength / scroll.distance));
      if (abs(s) > abs(scroll.cur)) {
        scroll.cur = s;
      }
    });
  }

  /**
   * 12.17. Create shader object
   */
  createShader() {
    this.shader = new Shader(this.gl, {
      attrs: [
        // Color
        [_vec4, 'Color', 1],
        // UV, Layer, Word
        [_vec4, 'UVLI', 0],
        // Coords
        [_vec4, 'C', 0],
        // Dynamic coords
        [_vec4, 'DCU', 0],
        // Paddings
        [_vec4, 'P', 1],
        // Appear: id, opacityOnly, glyph width in px, glyph height in px
        [_vec4, 'AP', 1],
        // delay, isPictographic, start & end time
        [_vec4, 'NP', 1],
        // Old coords for change animation
        [_vec4, 'OP', 0],
      ],
      uniforms: [
        // Glyphs Texture
        [_sampler, 'Sampler', 0, 1],
        // Noise Texture
        [_sampler, 'Noise', 0, 1],
        // Effects ids
        [_vec4, 'E', 0, 1],
        // Appear timing functions, smooth position & multiline
        [_vec4, 'ES', 0, 1],
        // Backgrounds ids
        [_float, 'BGID', 0, 1],
        // Time
        [_hfloat, 'T', 0, 1],
        // Word ID, Line ID, Wrap ID
        [_vec3, 'ID', 0, 1],
        // Aspect ratio
        [_float, 'AR', 0],
        // Last refresh time, opacity, opacityOnly, speed
        [_vec4, 'NP', 0, 1],
        // Max glyph width
        [_float, 'MW', 0],
        // Previous line x, Current line x
        [_vec2, 'CL', 0],
        // Projection matrix
        [_mat4, 'Prj', 0],
        // 1px in GL width coords
        // 1px in GL height coords
        // Canvas Width & Height
        [_vec4, 'U', 1],
        // Noise texture scale relative to main texture
        [_vec2, 'NS', 1],
        // Canvas x change offset,
        // Canvas unused width
        [_vec2, 'CO', 0, 1],
        // Word coords on canvas from 0 to 1
        [_vec4, 'WC', 0, 1],
        // Line coords on canvas from 0 to 1
        [_vec4, 'LC', 0, 1],
        // Wrap coords on canvas from 0 to 1
        [_vec4, 'WRC', 0, 1],
        // Mouse coords & speed
        [_vec3, 'M', 0, 1],
      ],
      varyings: [
        // Current time
        [_hfloat, 'CT', 1],
        // UV, Layer, Word
        [_vec4, 'UVLI', 1],
        // Glyph UV coords from 0 to 1
        [_vec2, 'UP', 1],
        // Glyphs on canvas coords from 0 to 1
        // Static glyphs on canvas coords in pixels
        [_vec4, 'CP', 1],
        // Glyphs on canvas coords in pixels
        [_vec4, 'VP', 1],
        // Multiline position
        [_float, 'CC', 1],
      ],
    });
  }

  /**
   * 12.18. Refresh shader code
   */
  updateShader() {
    const {gl} = this;
    const {htmlText} = this.wgltr;
    const shader = this.shader;

    // Generate effects
    let totalEasing = '';
    let easing = '';
    const vreq = [];
    const freq = [];

    let useChange = false;
    let useMouse = false;
    const types = [
      ['vert', vreq],
      ['frag', freq],
      ['fragColor', freq],
      ['appearVert', vreq],
      ['appearFrag', freq],
      ['appearFragColor', freq],
    ];
    const defaultTypes = ['vert', 'frag', 'fragColor'];
    const appearTypes = ['appearVert', 'appearFrag', 'appearFragColor'];
    const appearEffects = [
      'appearEffects',
      'changeAppearEffects',
      'disappearEffects',
    ];
    const dynamicEffects = [
      ['effects', defaultTypes],
      ['mouseEffects', defaultTypes],
      ['scrollEffects', defaultTypes],
      ['appearEffects', appearTypes],
      ['changeAppearEffects', appearTypes],
      ['disappearEffects', appearTypes],
    ];
    const glsl = types.reduce((result, [type]) => {
      result[type] = '';
      result[type + 'Alpha'] = '';
      return result;
    }, {});

    htmlText.options.forEach((o) => {
      types.forEach(([type, req]) => {
        dynamicEffects.forEach(([name, types]) => {
          if (o.inherit[name]) {
            return;
          }
          const effects = getGLEffects(o, name);
          const result = glEffectsToGLSL(
              name, type, getGLEffects(o, name), o.data[name] || 0);
          if (!result.glsl || types.indexOf(type) === -1) {
            return;
          }
          if (appearTypes.indexOf(type) >= 0) {
            // Set condition for appear & disappear
            result.glsl =
                glCondIndex(result.glsl, 'uE.w', [appearEffects.indexOf(name)]);

            if (o.appearShadowOpacityOnly) {
              const alpha = effects.filter((effect) => {
                return effect.args[0] === 'falpha';
              });
              const result = glEffectsToGLSL(
                  name, type, alpha, o.data[name] || 0);
              if (result.glsl) {
                result.glsl = glCondIndex(result.glsl,
                    'uE.w', [appearEffects.indexOf(name)]);
                glsl[type + 'Alpha'] += glCondIndex(
                    result.glsl,
                    'uE.x',
                    Options.getIDs(o, name));
              }
            }
          }
          req.push(...result.req);
          glsl[type] += glCondIndex(
              result.glsl,
              'uE.x',
              Options.getIDs(o, name));
        });
      });

      if (!o.inherit.appearFunction) {
        totalEasing += glCondIndex(
            `t=${glslEasing[o.appearFunction]};`,
            'uE.x', Options.getIDs(o, 'appearFunction'));
      }
      if (!o.inherit.appearPartFunction) {
        easing += glCondIndex(
            `cT=${glslEasing[o.appearPartFunction]};`,
            'uE.x', Options.getIDs(o, 'appearPartFunction'));
      }

      useChange = !!o.change || useChange;
      useMouse = !!o.mouseEffects || useMouse;
    });

    const backgrounds = this.generateBackgroundsGLSL();
    freq.push(...backgrounds.req);

    const frag =
        `${_vec4}tC=vec4(1.);` +
        `${_vec2}ep=vU.xy,` +
        `uv=vUVLI.xy;` +

        // Mouse variables
        (useMouse ?
        `${_vec2}mPos=uM.xy*vU.zw;` +
        `${_float}mA=atan(vVP.x-mPos.x,vVP.y-mPos.y),` +
        `mD=abs(sqrt(pow(vVP.x-mPos.x,2.)+pow(vVP.y-mPos.y,2.))),` +
        `mSin=sin(mA),` +
        `mCos=cos(mA);` : '') +

        (glsl['frag']) +
        (glsl['appearFrag'] ? `if(vCT>.001){if(vAP.y<.5){` +
            `${glsl['appearFrag']}}else{${glsl['appearFragAlpha']}}}` : '') +

        // Multiline
        (useChange ?
          glDiscard(`vCC>-10.&&(vCC>0.&&uv.x>(vP.x+(vP.z-vP.x)*(1.-vCC))` +
            `||vCC<.0&&uv.x<(vP.x+(vP.z-vP.x)*-vCC))`) : ``) +

        `${_vec4}tA=tex(uSampler,uv,vColor),tO=tA;` +

        (backgrounds.glsl ? (
          // If is not pictographic && pixel not transparent
          `if(vNP.y<.5&&tA.w>0.){` +
          `${_float}dO=tA.w,o=tA.w;` +
          `${_vec3}r=tA.xyz,dR=tA.xyz;` +
          `${_vec2}buv=uv,bep=ep;` +
          backgrounds.glsl +
          `uv=buv;ep=bep;tA=vec4(r,(vAP.y<.5)?o:tA.w);tO=tA;}`
        ) : '') +

        glsl['fragColor'] +
        (glsl['appearFragColor'] ?
          `if(vCT>.001){${glsl['appearFragColor']}}` : '') +
        `tA.w*=tC.w;` +

        `gl_FragColor=tA;`;

    const vert =
        `${_float}tmp,tt,t=1.;` +
        `if(uNP.w>0.){` +
        `bool eI=aNP.w<0.;` +
        `${_float}e=eI?-aNP.w:aNP.w;` +
        `t=clamp((uT-aNP.z)/(e-aNP.z),0.,1.);` +
        totalEasing + `t=(e-aNP.z)*t+aNP.z;` +
        `t=clamp(((t-aNP.x)/uNP.w),0.,1.);` +
        `if(eI){t=1.-t;}}` +

        `${_float}cT=t;` + easing +
        `if(cT<.00001){gl_Position=vec4(0);return;}` +
        `${_vec2}c=cT>=1.?aC.xy:aDCU.xy;` +
        `${_vec4}pos=vec4(c,0.,1.);` +
        `vUVLI=cT>=1.?aUVLI:vec4(aDCU.zw,aUVLI.zw);` +

        `${_vec2}cc=aC.zw;` +

        // Change position animation
        (useChange?
        `vCC=-20.;` +
        `t=clamp((uT-uNP.x)/uE.y,0.,1.);` +
        `t=${glslEasing['inOutQuad']};` +
        `if(t>=.0&&t<1.){` +
        `${_float}o=4./uU.z;` +
        `cc=aOP.xy+(aC.zw-aOP.xy)*t;` +

        // Change multiline position animation
        `if(uE.z>.5){` +
        `bool L=aOP.y-o>aC.w;` +
        `if(uCL.y>-5000.){` +
        `L=uNP.z>.5?!L:L;` +
        `${_float}h=aOP.z/2.,n=abs(uCL.x-aOP.x)+h,N=abs(aC.z-uCL.y)+h,` +
        `l=n+N,c=l*t,v=L?1.:-1.;` +
        // New Line
        `if(c>n){` +
        `if(c-h<n+h){vCC=(c-h-(n+h))/aOP.z*v;}` +
        `cc=vec2(aC.z+(L?-(l-c):(l-c)),aC.w);` +
        // Old line
        `}else{` +
        `if(c+h>n-h){vCC=(c+h-(n-h))/aOP.z*v;}` +
        `cc=vec2(aOP.x+(L?c:-c),aOP.y);` +
        `}}}}`:``) +

        `${_float}z=-uPrj[0][0];` +
        `${_vec4}zr=vec4(uAR,1.,1.,1.);` +

        // Current coordinates
        `${_vec4}vp=uPrj*(vec4(c+cc,z,1.)/zr),` +
        // Current static coordinates without change moving
        `vps=uPrj*(vec4(c+aC.zw,z,1.)/zr),` +
        // Current glyph center coordinates
        `vpc=uPrj*(vec4(cc,z,1.)/zr);` +

        // Current coordinates in pixels
        `${_vec2}px=pxPos(vp.xy/vp.w);` +
        // Current coordinates in pixels with offset
        // Current center coordinates in pixels
        'vVP=vec4(px,pxPos(vpc.xy/vpc.w));' +
        // Glyph UV coords from 0 to 1 for fragment effects
        'vUP=uvPos(aC.xy);' +
        // Glyph coords on canvas from 0 to 1
        // Glyph static coords in pixels without change moving
        'vCP=vec4((px+vec2(uCO.y,0.0))/uU.zw,pxPos(vps.xy/vps.w));' +

        (useMouse ?
          `${_vec2}mPos=uM.xy*vU.zw;` +
          `${_float}mD=abs(sqrt(pow(vVP.z-mPos.x,2.)+` +
          `pow(vVP.w-mPos.y,2.)));` : '') +

        'cT=1.-cT;' +
        `vCT=cT;${glsl['vert']}` +
        (glsl['appearVert'] ? `if(cT<1.){${glsl['appearVert']}}` : '') +

        `gl_Position=uPrj*((pos+vec4(cc,z,0.))/zr);`;

    shader.cleanDynamicUniforms();
    // Backgrounds uniforms
    this.backgrounds.forEach((_, index) => {
      // Texture
      shader.addDynamicUniform(_sampler, 'BG' + index);
      // Size
      shader.addDynamicUniform(_vec2, 'BGW' + index);
      // Scale
      shader.addDynamicUniform(_vec2, 'BGS' + index);
    });
    // Scroll effects uniforms
    this.scrolls.forEach((_, index) => {
      shader.addDynamicUniform(_float, 'SR' + index);
    });

    shader.refresh(vert, vreq, frag, freq);
    gl.use(shader.program);
    shader.uniform([
      ['Sampler', 0],
      ['Noise', 1],
      ...this.backgrounds.map((_, index) => ['BG' + index, index + 2]),
    ]);
    this.shader = shader;
  }

  /**
   * 12.19. Generate GLSL backgrounds code
   * @return {string}
   */
  generateBackgroundsGLSL() {
    if (this.backgrounds.length <= 0) {
      return {req: [], glsl: ''};
    }

    const startGLSL =
      `${_vec4}bg,tC=vec4(1.);` +
      `${_vec2}bgUV;`;

    const glsl = [];
    const req = [];
    let curID = -1;

    this.backgrounds.forEach((bg, index) => {
      if (!bg.loaded) {
        return;
      }

      const effects = glEffectsToGLSL(
          'effects', 'frag', getGLEffects(bg.effects));
      const effectsColor = glEffectsToGLSL(
          'effects', 'fragColor', getGLEffects(bg.effects));
      req.push(...effects.req, ...effectsColor.req);

      let operation = 'r=mix(r,bg.xyz,bg.w);o=min(dO,o+bg.w*(1.-o));';
      switch (bg.operation) {
        case 'out':
          operation = 'o=max(0.0,o-bg.w*o);';
          break;
        case 'bgIn':
          operation = 'r=mix(r,dR,bg.w);';
          break;
        case 'bgOut':
          operation = 'o=min(dO,o+bg.w*(1.-o));';
          break;
        default:
      }

      let scale = '';
      if (bg.scaleX) {
        scale = `vec2(${glVal(bg.scaleX)},${glVal(bg.scaleY)})`;
      }

      const uv = glUVSizes[bg.size];
      const blockSize = glBlockSizes[bg.size];

      let code = `bgUV=${uv};`;
      let sizeDiff = 'vec2(1.)';

      if (!bg.fit) {
        sizeDiff = `(${blockSize}/uBGW${index})`;
        code += `bgUV*=${sizeDiff};`;
      }

      if (scale) {
        code += `bgUV/=${scale};`;
        sizeDiff = `(${sizeDiff}/${scale})`;
      }

      if (bg.type === 'dimage') {
        // Translate
        const tx = bg.args[12];
        const ty = bg.args[13];

        if (tx !== 0 || ty !== 0) {
          code += `bgUV-=(vec2(1.)/${blockSize})*` +
              `vec2(${glVal(tx)},${glVal(ty)});`;
        }
      }

      // Align
      switch (bg.align) {
        case 'center':
          code += `bgUV+=vec2(.5)-${sizeDiff}/vec2(2.);`;
          break;
        case 'topcenter':
          code += `bgUV.x+=.5-${sizeDiff}.x/2.;`;
          break;
        case 'topright':
          code += `bgUV.x+=1.-${sizeDiff}.x;`;
          break;
        case 'centerleft':
          code += `bgUV.y+=.5-${sizeDiff}.y/2.;`;
          break;
        case 'centerright':
          code += `bgUV+=vec2(1.,.5)-${sizeDiff}/vec2(1.,2.);`;
          break;
        case 'bottomleft':
          code += `bgUV.y+=1.-${sizeDiff}.y;`;
          break;
        case 'bottomcenter':
          code += `bgUV+=vec2(.5,1.)-${sizeDiff}/vec2(2.,1.);`;
          break;
        case 'bottomright':
          code += `bgUV+=vec2(1.)-${sizeDiff};`;
          break;
        default:
      }

      // Make texture pixel variable fit background size
      if (effects.glsl || effectsColor.glsl) {
        code += `ep=vec2(1.)/(uBGW${index}/uBGS${index});`;
      }

      if (bg.type === 'dimage' || bg.type === 'dpattern') {
        if (bg.type === 'dimage') {
          // Repeat
          switch (bg.args[9]) {
            case 'no-repeat':
              code += 'if(bgUV.x>=.0&&bgUV.x<=1.&&bgUV.y>=.0&&bgUV.y<=1.){';
              break;
            case 'repeat-x':
              code += 'if(bgUV.y>=.0&&bgUV.y<=1.){';
              break;
            case 'repeat-y':
              code += 'if(bgUV.x>=.0&&bgUV.x<=1.){';
              break;
            default:
          }
        }

        if (effects.glsl) {
          // Make effects ignore background size
          code += 'uv=buv;';
          code += effects.glsl;
          code += `${_vec2}uvdiff=(uv-buv);`;
        }

        code += `uv=mod(bgUV,vec2(1.))*uBGS${index};`;

        if (effects.glsl) {
          code += `uv+=uvdiff;`;
        }
      } else {
        code += `uv=bgUV*uBGS${index};`;
      }

      code += `bg=texture2D(uBG${index},uv);`;

      if (bg.invert && bg.operation !== 'in') {
        code += `bg.w=1.-bg.w;`;
      }
      if (bg.opacity < 1) {
        code += `bg.w*=${glVal(bg.opacity)};`;
      }

      if (effectsColor.glsl) {
        code += `uv=buv;tA=bg;tO=bg;`;
        code += effectsColor.glsl.replace(/uSampler/g, `uBG${index}`);
        code += 'bg=tA;';
      }

      // Apply effects opacity and color
      code += 'bg*=tC;';
      code += operation;

      // Close repeat condition
      if (bg.type === 'dimage' && bg.args[9] !== 'repeat') {
        code += '}';
      }
      code = `{${code}}`;

      const {uniqueID} = bg.options;


      if (curID !== uniqueID) {
        curID = uniqueID;
        glsl.push({code: code, ids: Options.getIDs(bg.options, 'backgrounds')});
      } else {
        glsl[glsl.length-1].code += code;
      }
    });

    return {
      req: req,
      glsl: startGLSL + glsl.reduce((result, glsl) => {
        return result + glCondIndex(glsl.code, 'uBGID', glsl.ids);
      }, ''),
    };
  }

  /**
   * 12.19. Update WebGL Buffers
   */
  updateBuffers() {
    this.updateBufferData();
    this.updateBufferAppears();
    this.updateBufferPositions();
    this.updateBufferColors();
    this.updateUniforms();
    this.refreshDrawCount();
  }

  /**
   * 12.20. Update shader uniforms
   */
  updateUniforms() {
    const {shader} = this;
    const texSize = this.wgltr.fontTexture.size;
    const aspect = this.rect.width / this.rect.height;

    const fov = ((PI / 180 * this.wgltr.options.fov));
    const prj = perspective(fov, 1, 0.0001, 10000);

    shader.uniform([
      ['Prj', prj],
      ['AR', aspect],
      ['NS', [
        texSize.width / this.noiseWidth,
        texSize.height / this.noiseHeight,
      ]],
      ['U', [
        1 / (texSize.width / dpr),
        1 / (texSize.height / dpr),
        ceil(this.rect.width / dpr),
        ceil(this.rect.height / dpr),
      ]],
    ]);

    this.updateOffsetUniform();
    this.updateMouseUniform();
    this.updateScrollUniform();
  }

  /**
   * 12.21. Update uniforms for dynamic backgrounds
   */
  updateBackgroundsUniforms() {
    if (this.backgrounds.length <= 0) {
      return;
    }

    const uniforms = [];
    this.backgrounds.forEach((background, index) => {
      uniforms.push(['BGS' + index, background.scale]);
      uniforms.push(['BGW' + index, background.usize]);
    });

    this.shader.uniform(uniforms);
  }

  /**
   * 12.22. Update mouse uniform
   * @param {number?} x
   * @param {number?} y
   * @param {number?} speed
   */
  updateMouseUniform(x, y, speed) {
    const elRect = this.wgltr.htmlText.elRect;

    if (x === undefined) {
      [x, y] = this.mousePos;
    } else {
      this.mousePos = [x, y];
    }

    x = x - (elRect.x + this.rect.x / dpr);
    x /= this.rect.width / dpr;

    y = y - (elRect.y + this.rect.y / dpr);
    y /= this.rect.height / dpr;

    this.shader.uniform([['M', [x, y, speed || 0]]]);
  }

  /**
   * 12.23. Update Scroll uniform
   */
  updateScrollUniform() {
    this.shader.uniform(this.scrolls.map((scroll, i) => {
      return [
        'SR' + i,
        (-cos(abs(scroll.cur) * (PI / 2)) + 1) * sign(scroll.cur),
      ];
    }));
  }

  /**
   * 12.24. Update canvas offset uniform
   * Happening after possible canvas shift
   */
  updateOffsetUniform() {
    const {shader} = this;
    const {canvasPosDiff, dprRect} = this.wgltr.htmlText;

    shader.uniform([['CO', [
      canvasPosDiff + dprRect.x / dpr,
      ceil(this.canvasReservedWidth / dpr),
    ]]]);
  }

  /**
   * 12.25. Refresh offsets for vertex buffers
   */
  refreshDrawCount() {
    let curCount = 0;
    this.wgltr.htmlText.words.forEach((word) => {
      word.vboStart = curCount * 6;
      curCount += word.glChars.length;
      word.vboCount = curCount * 6 - word.vboStart;
    });
  }

  /**
   * 12.26. Update VBO general data
   */
  updateBufferData() {
    const {shader} = this;

    const [indices, uvli, paddings] =
      shader.getVbo('indices', 'UVLI', 'P');

    let curIndex = 0;

    this.wgltr.htmlText.words.forEach((word) => {
      word.glChars.forEach((c) => {
        const {glyph} = c;

        const [uvx, uvy, uvw, uvh] = c.uv;
        const [px, py, pw, ph] = c.uvPaddings;
        const layer = glyph.layer;

        uvli.push(
            uvw, uvy, layer, 0,
            uvx, uvy, layer, 0,
            uvx, uvh, layer, 0,
            uvw, uvh, layer, 0,
        );
        arrayFill(paddings, px, py, pw, ph);

        curIndex = indices.length / 6 * 4;
        indices.push(
            curIndex,
            curIndex + 1,
            curIndex + 2,
            curIndex,
            curIndex + 2,
            curIndex + 3,
        );
      });
    });

    shader.updateVbo();
  }

  /**
   * 12.27. Update Appears VBO
   */
  updateBufferAppears() {
    const {shader} = this;

    const [ap, np] =
      shader.getVbo('AP', 'NP');

    this.wgltr.htmlText.words.forEach((word) => {
      const {options: o} = word.node;

      word.glChars.forEach((c) => {
        const {node, parent, glyph} = c;

        c = parent ? parent : c;
        const isChange = c.isAppear || c.isDisappear;
        const change = isChange ? node.changeParent.change : undefined;

        const {total, changeTotal} = c;
        let {start, end} = (isChange ? changeTotal : total) ||
        {start: 0, end: 0};
        let delay = isChange ? c.changeDelay : c.delay;

        if (c.isAppear) {
          delay += change.appearAdditive;
        }
        if (c.isDisappear) {
          delay += change.disappearAdditive;
          end = -end;
        } else if (!c.visible) {
          start = Infinity;
          end = Infinity;
          delay = Infinity;
        }

        if (this.isManualDisappear) {
          end = -end;
        }

        const opacityOnly = +!!(parent && o.appearShadowOpacityOnly);
        const dp = c.node.glDynamicPadding;
        const glyphWidth = (glyph.texSize.width + dp[0] + dp[2]) / dpr;
        const glyphHeight = (glyph.texSize.height + dp[1] + dp[3]) / dpr;

        arrayFill(ap, c.id, opacityOnly, glyphWidth, glyphHeight);
        arrayFill(np, delay, !!c.glyph.isPictographic, start, end);
      });
    });

    shader.updateVbo();
  }

  /**
   * 12.28. Update positions VBO
   */
  updateBufferPositions() {
    const {shader} = this;

    const [coords, oldCoords, dynamicCoords] =
      shader.getVbo('C', 'OP', 'DCU');

    this.wgltr.htmlText.words.forEach((word) => {
      word.glChars.forEach((c) => {
        const {size, dynamicSize, centerX, centerY} = c;
        const {x, y} = size;
        const {x: dx, y: dy} = dynamicSize;

        const w = size.width / 2;
        const h = size.height / 2;
        const dw = dynamicSize.width / 2;
        const dh = dynamicSize.height / 2;
        const [uvx, uvy, uvw, uvh] = c.uvDynamic;

        coords.push(
            w + x, h + y, centerX, centerY,
            -w + x, h + y, centerX, centerY,
            -w + x, -h + y, centerX, centerY,
            w + x, -h + y, centerX, centerY,
        );
        dynamicCoords.push(
            dw + dx, dh + dy, uvw, uvy,
            -dw + dx, dh + dy, uvx, uvy,
            -dw + dx, -dh + dy, uvx, uvh,
            dw + dx, -dh + dy, uvw, uvh,
        );
        arrayFill(
            oldCoords,
            c.oldCenterX,
            c.oldCenterY,
            c.size.width,
            c.size.height);
      });
    });

    shader.updateVbo();
  }

  /**
   * 12.29. Update colors VBO
   */
  updateBufferColors() {
    const {shader} = this;
    const [colors] = shader.getVbo('Color');

    this.wgltr.htmlText.words.forEach((word) => {
      word.glChars.forEach((c) => {
        let color = c.node.color;
        if (c.shadowID >= 0) {
          color = c.node.shadow[c.shadowID][0];
        }
        for (let i = 0; i < 4; i++) {
          colors.push(...color);
        }
      });
    });

    shader.updateVbo();
  }

  /**
   * 12.30. Draw text
   * @param {number} et - elapsed time
   */
  draw(et) {
    const {shader, wgltr} = this;
    const gl = this.gl.gl;
    const {fontTexture, htmlText} = wgltr;

    if (!fontTexture.texture) {
      return;
    }

    this.time += et / 1000;

    if (!this.isAppeared && this.time > this.totalTime) {
      this.isAppeared = true;

      if (this.totalTime > 0) {
        if (wgltr.options.appearDestroy) {
          this.wgltr.destroy();
          return;
        }
        this.wgltr.dispatchEvent(
            this.isManualDisappear ? 'disappeared' : 'appeared');
      }
    }

    // If resize is planned then no any process because of wrong values
    if (this.wgltr.resizeTimeout < 0) {
      this.processChange();
      this.processScrolls(et / 1000);
    }

    let maxMoveTime = 0;

    shader.uniform([['T', this.time]]);
    htmlText.words.forEach((word, index) => {
      if (!htmlText.isScreenVisible(word.node)) {
        return;
      }
      const {vboStart, vboCount} = word;
      const {options} = word.node;
      const disableMove = !!htmlText.resizeTimer;
      const moveTime = disableMove ? 0 : max(0, options.changeMove);
      maxMoveTime = max(maxMoveTime, moveTime);

      const c = word.chars[0];

      const multiline = disableMove ? 0 : (options.changeMultiline ? 1 : 0);
      const uniforms = [
        ['E', [options.uniqueID, moveTime, multiline, c.effectsType]],
        ['BGID', c.bgID],
        ['NP', [this.refreshTime, 0, word.isRTL, c.speed]],
        ['MW', c.maxWidth],
        ['CL', [c.oldLineX, c.newLineX]],
      ];

      const {data} =
          options.inherit.backgrounds ||
          options.inherit.effects ||
          options;

      const line = (word.line || word.oldLine || 1) - 1;
      uniforms.push(
          ['ID', [index, word.line || 0, options.index]],
          ['WC', this.wordsRects[index]],
          ['LC', this.linesRects[line]],
          ['WRC', rectToArray(data.wrapRect || new Rect(0, 0, 0, 0))],
      );

      shader.uniform(uniforms);
      gl.drawElements(
          gl.TRIANGLES, vboCount, gl.UNSIGNED_SHORT, vboStart * 2);
    });

    const textMoved = this.time > (this.refreshTime + maxMoveTime);
    htmlText.processSmoothResize(et, textMoved);
  }

  /**
   * 12.31. Process change elements
   */
  processChange() {
    const {wgltr} = this;
    const {htmlText} = wgltr;
    const isSmoothResizing =
        htmlText.resizeTimer <= 0 || htmlText.waitForSmoothResize;

    if (!this.isAppeared || this.isManualDisappear) {
      return;
    }

    let disappeared = false;
    let changed = false;
    let mutated = false;

    htmlText.changeNodes.forEach((node) => {
      const {options, change} = node;

      if (change.done) {
        return;
      }

      // Change
      if (change.waitToResize && this.time >= change.textResizeTime) {
        changed = true;
        change.waitToResize = false;
        change.toggle = true;
        change.keepOrder = true;
      }

      if (change.isDisappear && this.time >= change.disappearEnd) {
        change.isDisappear = false;
        change.isAppear = true;
        this.wgltr.dispatchEvent('changeDisappeared', node.node);
      } else if (change.isAppear && this.time >= change.changeEnd) {
        change.isAppear = false;
        this.wgltr.dispatchEvent('changeAppeared', node.node);
        this.wgltr.dispatchEvent('changed', node.node);
      } else if (this.time >= change.changeStart && isSmoothResizing) {
        change.started = true;

        if (options.change !== 'manual' && !options.changeLoop &&
          !change.order.length && change.current !== 0) {
          change.done = true;
          return;
        }
        if (!change.order.length && options.change !== 'manual') {
          change.order =
            [...Array(change.nodes.length).keys()].map((v) => ({index: v}));
          if (change.current === 0 || options.changeSkipFirst) {
            change.order.splice(0, 1);
          }
          if (options.changeRandom) {
            change.order = change.order.sort(() => random() * 2 - 1);
          }
        }
        if (change.order.length) {
          const {index, str} = change.order.shift();
          change.isAppear = false;
          change.isDisappear = true;
          change.waitToResize = true;
          change.toggle = false;
          change.time = this.time;
          change.previous = change.current;
          change.current = toRange(index, 0, change.nodes.length);
          disappeared = true;

          if (str) {
            change.str = str;
            mutated = true;
          }
          this.wgltr.dispatchEvent('changeStarted', node.node);
        }
      }
    });

    if (disappeared || changed || mutated) {
      // HTML text need to be changed
      if (changed || mutated) {
        wgltr.startChanging();

        htmlText.changeNodes.forEach(({change}) => {
          if (change.str) {
            change.nodes[change.current].node.innerHTML = change.str;
            change.str = '';
          }
          if (change.toggle) {
            htmlText.toggleChangeEl(change, change.current);
            change.toggle = false;
          }
        });

        mutated && htmlText.resize();
        htmlText.refreshCoords(true);
        mutated && wgltr.fontTexture.refresh();
      } else {
        htmlText.refreshCoords(true);
      }

      // Check if node is wider to know when text need to be moved
      htmlText.changeNodes.forEach(({change}) => {
        const {nodes, previous, current} = change;
        change.isWider = nodes[current].changeWidth >
          nodes[previous].changeWidth;
      });

      this.refresh(true);
      this.updateUniforms();
      this.updateBufferAppears();
      this.updateBufferPositions();
      this.refreshDrawCount();

      if (changed || mutated) {
        if (mutated) {
          this.updateBuffers();
          this.bindBackgrounds();
          wgltr.refreshCanvasPosition();
          wgltr.refreshCanvasSize();
        } else {
          wgltr.refreshCanvasPosition();
        }
        wgltr.stopChanging();
      }
    }
  }

  /**
   * 12.32. Manual Appear
   */
  appear() {
    if (this.isManualAppear) {
      return;
    }
    this.isAppeared = false;
    this.isManualAppear = true;
    this.isManualDisappear = false;
    this.manualStartTime = this.time;
    this.refresh();
    this.updateBuffers();
  }

  /**
   * 12.33. Manual Disappear
   */
  disappear() {
    if (this.isManualDisappear) {
      return;
    }
    this.isAppeared = false;
    this.isManualAppear = false;
    this.isManualDisappear = true;
    this.manualStartTime = this.time;
    this.refresh();
    this.updateBuffers();
  }
}


/**
 * 13. Text Order
 * Collect and sort characters for appear
 */
class Order {
  /**
   * 13.1. Start
   * @param {object} options - node options
   * @param {boolean} reverse - appear reverse
   * @param {boolean} random - appear random
   * @param {boolean} reset - appear reset
   * @param {boolean} skip - no order reset
   * @param {boolean} keepOrder - use previous sort
   */
  start(options, reverse, random, reset, skip, keepOrder) {
    const result = {
      options,
      reverse,
      random,
      reset,
      skip,
      keepOrder,
      chars: [],
      parent: this.cur,
    };
    !this.first && (this.first = result);
    this.cur && this.cur.chars.push(result);
    this.cur = result;
  }

  /**
   * 13.2. Push character to current operation
   * @param {object} character - character information
   */
  push(character) {
    this.cur.chars.push(character);
  }

  /**
   * 13.3. Process operations
   * @param {number} count - operations level count
   */
  process(count) {
    while (count--) {
      const {cur} = this;

      if (cur.skip) {
        cur.parent.chars.splice(-1, 1, ...cur.chars);
      } else {
        if (cur.reverse === true) {
          let lineChars = [];
          let curLine = cur.chars.length ? cur.chars[0].line : 0;

          // Reverse
          cur.chars = cur.chars.reduce((result, c, i) => {
            const last = i === cur.chars.length - 1;
            last && lineChars.push(c);

            if (curLine !== c.line || last) {
              curLine = c.line;
              result.push(...lineChars.reverse());
              lineChars = [];
            }
            lineChars.push(c);
            return result;
          }, []);
        }

        // Count
        let prev = {};
        let group = [];

        cur.chars = cur.chars.reduce((result, c) => {
          let reset = false;

          if (!c.chars) {
            if (!c.visible && !c.isDisappear) {
              return result;
            }

            reset = c.reset ||
                (c.count === 'word' && prev.word !== c.word) ||
                (c.count === 'line' && prev.line !== c.line) ||
                (typeof c.count === 'number' && group.length >= c.count);

            prev = c;
          }

          if (reset || c.chars) {
            if (group.length) {
              result.push(group);
              group = [];
            }
            if (c.chars) {
              result.push(c);
            }
          }

          if (!c.chars) {
            group.push(c);
            group.start = group[0].start;
          }
          return result;
        }, []);

        group.length && cur.chars.push(group);

        // Sorting
        if (cur.chars.length > 0) {
          const start = cur.chars[0].length ? cur.chars[0][0].start : 0;

          if (cur.random === true) {
            if (!cur.keepOrder || !cur.options.orderRandom) {
              cur.options.orderRandom = [...cur.chars.keys()].sort(() => {
                return random() > .5 ? -1 : 1;
              });
            }
            cur.chars = cur.options.orderRandom.reduce((result, index) => {
              result.push(cur.chars[index]);
              return result;
            }, []);
          }

          if (start) {
            cur.chars[0].start = start;
          }
        }

        // Spread inners
        cur.chars = cur.chars.reduce((result, c) => {
          c.chars ? result.push(...c.chars) : result.push(c);
          return result;
        }, []);

        if (cur.reset && cur.chars.length) {
          cur.chars[0].reset = cur.reset;
        }
      }

      this.cur = cur.parent;
    }
  }

  /**
   * 13.4. Create array of character groups
   * @return {array} - Array of characters groups
   */
  result() {
    return this.first.chars;
  }
}


/**
 * 14. WebGL renderer
 */
class GL {
  /**
   * 14.1. Initialization
   * @param {Element} canvas
   * @param {WGLTR} wgltr
   */
  constructor(canvas, wgltr) {
    this.canvas = canvas || create('canvas');
    this.wgltr = wgltr;

    this.gl = getWebGLContext(this.canvas, {
      alpha: true,
      depth: false,
      stencil: false,
      antialias: false,
    });

    const gl = this.gl;
    gl.enable(gl.BLEND);
    gl.clearColor(0, 0, 0, 0);
    this.decorBuffer = gl.createFramebuffer();
  }

  /**
   * 14.2. Use shader program
   * @param {WebGLProgram} shader
   */
  use(shader) {
    this.gl.useProgram(shader);
  }

  /**
   * 14.3. Resize WebGL renderer
   * @param {number} width
   * @param {number} height
   */
  resize(width = this.canvas.width, height = this.canvas.height) {
    this.gl.viewport(0, 0, width, height);
  }

  /**
   * 14.4. Drawing glyphs with shader
   * @param {number} et - elapsed time
   */
  draw(et) {
    const {gl, wgltr} = this;
    gl.clear(gl.COLOR_BUFFER_BIT);
    wgltr.draw(et);
  }

  /**
   * 14.4. Create noise texture
   * @param {number} layer - texture layer
   * @param {number} width
   * @param {number} height
   * @return {WebGLTexture}
   */
  createNoiseTexture(layer, width, height) {
    if (!this.noiseTexture) {
      let size = width * height;
      let id = 0;
      const data = new Uint8Array(size * 3);

      while (size--) {
        data[id++] = random() * 255;
        data[id++] = random() * 255;
        data[id++] = random() * 255;
      }
      this.noiseTexture = this.createTexture(
          data, layer, this.gl.RGB, width, height);
    }
    return this.noiseTexture;
  }

  /**
   * 14.5. Prepare to drawing font texture
   * @param {number} width - texture width
   * @param {number} height - texture height
   */
  prepareDecorations(width, height) {
    const {gl} = this;

    this.resize(width, height);

    this.decorTexture = this.createTexture(
        undefined, 3, gl.RGBA, width, height);

    gl.bindFramebuffer(gl.FRAMEBUFFER, this.decorBuffer);
    gl.framebufferTexture2D(
        gl.FRAMEBUFFER,
        gl.COLOR_ATTACHMENT0,
        gl.TEXTURE_2D,
        this.decorTexture,
        0);

    gl.blendFunc(gl.ONE, gl.ONE);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    gl.activeTexture(gl.TEXTURE0);
  }

  /**
   * 14.6. Finish drawing font texture
   */
  finishDecorations() {
    const {gl} = this;
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    this.restoreBlending();
  }

  /**
   * 14.7. Restore blending function
   */
  restoreBlending() {
    const {gl} = this;
    gl.blendFuncSeparate(
        gl.SRC_ALPHA,
        gl.ONE_MINUS_SRC_ALPHA,
        gl.ONE,
        gl.ONE_MINUS_SRC_ALPHA);
  }

  /**
   * 14.8. Draw glyph on font texture
   */
  processDecorations() {
    const {gl} = this;
    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
  }

  /**
   * 14.9. Create WebGL Texture
   * @param {(Element|array|Uint8Array)} canvas
   * @param {number} layer
   * @param {number} type
   * @param {number} width
   * @param {number} height
   * @return {WebGLTexture}
   */
  createTexture(canvas, layer, type, width, height) {
    const {gl} = this;
    const texture = this.gl.createTexture();

    gl.activeTexture(gl.TEXTURE0 + layer);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    try {
      if (canvas instanceof Element) {
        gl.texImage2D(
            gl.TEXTURE_2D,
            0, type, type,
            gl.UNSIGNED_BYTE,
            canvas);
      } else {
        gl.texImage2D(
            gl.TEXTURE_2D,
            0, type,
            width, height,
            0, type,
            gl.UNSIGNED_BYTE,
            canvas);
      }
    } catch (e) {
      console.warn('WGLTR. Can\'t create WebGL texture!');
    }
    return texture;
  }

  /**
   * 14.10. Bind WebGL Texture
   * @param {number} num - Active texture number
   * @param {WebGLTexture} texture - Texture to bind
   */
  bindTexture(num, texture) {
    const {gl} = this;
    gl.activeTexture(gl.TEXTURE0 + num);
    gl.bindTexture(gl.TEXTURE_2D, texture);
  }

  /**
   * 14.11. Update WebGL Texture
   * @param {Element|array} data
   */
  updateTexture(data) {
    const {gl} = this;

    gl.texSubImage2D(
        gl.TEXTURE_2D,
        0, 0, 0, gl.RGBA,
        gl.UNSIGNED_BYTE, data);
  }

  /**
   * 14.12. Destroy WebGL Renderer
   */
  destroy() {
    const ext = this.gl.getExtension('WEBGL_lose_context');
    ext && ext.loseContext && ext.loseContext();
  }
}


/**
 * 15. Shaders global variables
 */
const _sampler = 'sampler2D ';
const _mfloat = 'lowp float ';
const _hfloat = 'highp float ';
const _float = 'lowp float ';
const _vec2 = 'lowp vec2 ';
const _vec3 = 'lowp vec3 ';
const _vec4 = 'lowp vec4 ';
const _mat4 = 'lowp mat4 ';
const glslEasing = {
  'linear': 't',
  'inQuad': 'pow(t,2.)',
  'outQuad': '-(t*(t-2.))',
  'inOutQuad': 't<0.5?(2.*t*t):((-2.*t*t)+(4.*t)-1.)',
  'inCubic': 'pow(t,3.)',
  'outCubic': 'pow(t-1.,3.)+1.',
  'inOutCubic': 't<0.5?4.*t*t*t:(tmp=(2.*t)-2.,0.5*tmp*tmp*tmp+1.)',
  'inExpo': 't==0.?0.:pow(2.,(10.*(t-1.)))',
  'outExpo': 't==1.?1.:-(pow(2.,(-10.*t)))+1.',
  'inOutExpo': 't==0.?0.:(t==1.?1.:(t<0.5?pow(2.,20.*t-10.)/2.:' +
      '(2.-pow(2.,-20.*t+10.))/2.))',
  'inSine': `-cos(t*(${PI}/2.))+1.`,
  'outSine': `sin(t*(${PI}/2.))`,
  'inOutSine': `-0.5*(cos(${PI}*t)-1.)`,
  'inCirc': '-(sqrt(1.-(t*t))-1.)',
  'outCirc': 'sqrt(1.-(pow((t-1.),2.)))',
  'inOutCirc': 't<0.5?(1.-sqrt(1.-pow(2.*t,2.)))/2.:' +
      '(sqrt(1.-pow(-2.*t+2.,2.))+1.)/2.',
  'inQuart': 'pow(t,4.)',
  'outQuart': '1.-pow(1.-t,4.)',
  'inOutQuart': 't<0.5?8.*t*t*t*t:1.-pow(-2.*t+2.,4.)/2.',
};
const glVertexFunctions = {
  'pxPos':
      `${_vec2}pxPos(${_vec2}p){return ` +
      `vec2((p.x+1.)/2.,(2.-(p.y+1.))/2.)*uU.zw;}`,
  'uvPos':
    `${_vec2}uvPos(${_vec2}c){return ` +
    `vec2(c.x>0.?1.:0.,c.y>0.?0.:1.);}`,
  'toPos':
      `${_vec3}toPos(${_vec3}p){return ` +
      `vec3(p/vec3(uU.zw/2.,uU.w/2.)*vec3(uAR,1.,1.));}`,

  'quat':
      `mat4 quat(${_vec3}q){` +
      `${_float}a=sin(q.x),b=cos(q.x),` +
      'c=sin(q.y),d=cos(q.y),' +
      'e=sin(q.z),f=cos(q.z),' +
      `j=a*d*f-b*c*e,k=b*c*f+a*d*e,` +
      `l=b*d*e-a*c*f,z=b*d*f+a*c*e,` +
      'm=j+j,v=k+k,n=l+l,w=j*m,t=k*m,y=k*v,' +
      'i=l*m,o=l*v,p=l*n,s=z*m,g=z*v,h=z*n;' +
      'return mat4(' +
      '1.-y-p,t+h,i-g,0.,' +
      't-h,1.-w-p,o+s,0.,' +
      'i+g,o-s,1.-w-y,0.,' +
      '0.,0.,0.,1);}',

  'follow':
      `void follow(inout ${_vec4}pos,${_vec4}us,` +
      `${_float}lx,${_float}ly,${_float}dx,${_float}dy){` +
      `${_vec2}m=uM.xy*uU.zw;` +
      `us+=vec4(-abs(lx),-abs(ly),abs(lx)*2.,abs(ly)*2.);` +
      `${_vec2}lv=(m-(us.xy+us.zw/vec2(2.)))/(us.zw/vec2(2.));` +
      `if(abs(lv.x)>1.||abs(lv.y)>1.){return;}` +
      `${_vec2}pw=abs(lv);` +
      `lv*=min(vec2(1.),vec2(1.-max(pw.x,pw.y))/(vec2(dx,dy)));` +
      `lv*=abs(vec2(lx,ly));` +
      `${_vec3}p=toPos(vec3(lv,.0));` +
      `pos.x+=lx<.0?-p.x:p.x;` +
      `pos.y-=ly<.0?-p.y:p.y;}`,

};
const glFragmentFunctions = {
  'tex':
      `${_vec4}tex(${_sampler}S,${_vec2}uv,${_vec4}C){` +
      `if(uv.y<vP.y||uv.x<vP.x||uv.y>vP.w||uv.x>vP.z){return vec4(C.xyz,0.);}` +
      `${_vec4}c=texture2D(S,uv);` +
      `${_vec4}a=vec4(C.xyz,c.r*C.w);` +
      `if(vUVLI.z>2.5){a=c;}` +
      `else if(vUVLI.z>1.5){a.w=c.b*C.w;}` +
      `else if(vUVLI.z>0.5){a.w=c.g*C.w;}` +
      `return a;}`,
  'overlay':
      `${_vec4}overlay(${_vec4}f,${_vec4}s){` +
      `${_float}a=s.w+f.w-f.w*s.w;` +
      `return vec4((f.rgb*f.w*(1.-s.w)+s.rgb*s.w)/a,a);}`,

  'dst':
      `void dst(inout ${_vec2}uv,${_vec2}ep,${_hfloat}x,` +
      `${_hfloat}y,${_float}ax,${_float}ay,` +
      `${_float}ox,${_float}oy,${_float}tx,${_float}ty){` +
      // (px * PI / length) + (curTime * time) * (amplitude * uv pixel)
      `uv.x+=sin((vVP.y*${PI}/x)+(uT*tx)+(vAP.x*${PI}*ox))*(ax*ep.x);` +
      `uv.y+=sin(((vVP.x+uCO.x)*${PI}/y)+(uT*ty)+(vAP.x*${PI}*oy))*(ay*ep.y);}`,

  'glitch':
      `void glitch(inout ${_vec2}uv,${_vec2}ep,${_float}ct,${_float}d,` +
      `${_float}s,${_float}l,${_vec2}a,${_float}ag,${_float}ss,${_float}de){` +
      `${_float}gd=length(vAP.zw*a);` +
      `if(s<.5){l*=2.;}` +
      `${_vec4}tN=texture2D(uNoise,(vec2(ag,0.0)*(gd/l)+uT/ss)*ep*vNS);` +
      `if(tN.x<=de){` +
      `${_float}t=tN.x/de;` +
      `if(s>.5){d=tN.y>.5?d:-d;}else{` +
      // 0 - 1 - 0
      `t=(t>.5?1.-t:t)*2.;` +
      // Zigzag timeline
      `if(t>${glVal(1/3)}){` +
      `t=(t-${glVal(1/3)})/${glVal(1-(1/3))}*2.-1.;` +
      `t=clamp(t,0.,1.);` +
      `}else{t=t*3.;d=-d;}` +
      `t=${glslEasing['inOutCirc']};}` +
      `uv+=a*t*ep*d*ct;}}`,

  'cglitch':
      `void cglitch(${_sampler}S,inout ${_vec2}uv,${_vec2}ep,${_vec4}tO,` +
      `inout ${_vec4}tA,${_float}ct,${_vec4}c1,${_vec4}c2,${_float}d,` +
      `${_vec2}a,${_float}ag,${_float}co,${_float}al,${_float}ss,${_float}sd,` +
      `${_float}sy){` +
      `if(ct<=0.){return;}` +
      `${_float}gd=length(vAP.zw*vec2(a.y,a.x))/sy,` +
      `vd=d*ct;` +
      `${_vec2}D=a*vd*ep;` +
      `${_float}tN=texture2D(uNoise,(vec2(ag,.0)*gd+uT/ss)*ep*vNS` +
      `+vec2(uID.x*.1,0.)).x,` +
      `aw=tA.w,ow=tO.w,tOw=tO.w;` +
      `{${_float}t=tN;t=${glslEasing['inOutSine']};tN=t*sd;}` +
      `D-=D*tN;` +
      `al=(1.-(vd-vd*tN)/d*al);` +
      `c1.w*=al;c2.w*=al;` +
      `${_vec4}f=tex(S,uv+D,vec4(c1.xyz,1.)),` +
      `s=tex(S,uv-D,vec4(c2.xyz,1.)),` +
      `fc=vec4(c1.xyz,f.w*c1.w),sc=vec4(c2.xyz,s.w*c2.w),` +
      `m=overlay(fc,sc);` +
      `tA=overlay(m,(co>.5)?` +
      `((f.w>.01&&s.w>.01)?vec4(tA.rgb,min(s.w,f.w)*tA.w):vec4(0.)):tA);}`,

  'noise':
      `${_float}noise(${_vec2}uv,${_vec2}ep,${_float}p,` +
      `${_float}mx,${_float}my,${_float}sx,${_float}sy){` +
      `${_vec4}tN=texture2D(uNoise,` +
      // (UV + Move * UVpx * Time * UVNoiseScale) / Scale
      `((uv+vec2(mx,my)*ep*uT)*vNS)/vec2(sx,sy));` +
      `${_float}ns=.3/(min(sx,sy)/2.);` +
      `return clamp(max(0.,p*(1.+ns)-tN.y)/ns,0.,1.);}`,

  'color':
    `void color(inout ${_vec4}tA,${_vec4}c,${_float}p){` +
    `tA.xyz=mix(tA.xyz,c.xyz,p*c.w);}`,

  'uvNoise':
      `void uvNoise(inout ${_vec2}uv,${_vec2}ep,${_float}t,${_float}stx,` +
      `${_float}sty,${_float}mx,${_float}my,${_float}sx,${_float}sy){` +
      `${_vec4}tN=texture2D(uNoise,` +
      `((uv+vec2(vAP.x)+vec2(mx,my)*ep*uT)*vNS)/vec2(sx,sy));` +
      `{${_float}t=tN.x;t=${glslEasing['inOutSine']};tN.x=t;}` +
      `{${_float}t=tN.y;t=${glslEasing['inOutSine']};tN.y=t;}` +
      `uv+=(tN.xy*2.-1.)*ep*(vec2(stx,sty)*t);}`,
};

/**
 * 16. Shader
 */
class Shader {
  /**
   * 16.1. Initialization
   * @param {GL} gl
   * @param {object} options
   */
  constructor(gl, options) {
    this.gl = gl.gl;
    this.options = options;

    this.indices = {};
    this.attrs = {};
    this.uniforms = {};
    this.dynamicUniforms = [];
    this.vboList = {
      'indices': {data: []},
    };
    this.program = this.gl.createProgram();
  }

  /**
   * 16.2. Refresh shader code
   * @param {string} vert - Vertex shader code
   * @param {array} reqVert - Vertex shader changed
   * @param {string} frag - Fragment shader code
   * @param {array} reqFrag - Fragment shader changed
   */
  refresh(vert, reqVert, frag, reqFrag) {
    const {gl, program} = this;
    [vert ? this.vert : false, frag ? this.frag : false].forEach((shader) => {
      if (shader) {
        gl.detachShader(program, shader);
        gl.deleteShader(shader);
      }
    });

    if (vert) {
      this.vert = this.compile(
          gl.VERTEX_SHADER,
          this.generate(true, reqVert, vert));
    }
    if (frag) {
      this.frag = this.compile(
          gl.FRAGMENT_SHADER,
          this.generate(false, reqFrag, frag));
    }
    this.link(!!vert, !!frag);
  }

  /**
   * 16.3. Link
   * @param {boolean} vertChanged - Vertex shader changed
   * @param {boolean} fragChanged - Fragment shader changed
   */
  link(vertChanged, fragChanged) {
    const {program, options, gl} = this;

    vertChanged && gl.attachShader(program, this.vert);
    fragChanged && gl.attachShader(program, this.frag);
    gl.linkProgram(program);

    if (vertChanged) {
      options.attrs.forEach(([, name]) => {
        this.attrs[name] = gl.getAttribLocation(program, 'a' + name);
        (!this.vboList[name] && (this.vboList[name] = {data: []}));
      });
    }
    options.uniforms.forEach(([type, name]) => {
      this.uniforms[name] = {
        type: 'uniform' + {
          [_sampler]: '1i',
          [_hfloat]: '1f',
          [_mfloat]: '1f',
          [_float]: '1f',
          [_vec2]: '2fv',
          [_vec3]: '3fv',
          [_vec4]: '4fv',
        }[type],
        isMatrix: type === _mat4,
        loc: gl.getUniformLocation(program, 'u' + name),
      };
    });
  }

  /**
   * 16.4. Compile
   * @param {number} type
   * @param {string} source - Shader source code
   * @return {WebGLShader}
   */
  compile(type, source) {
    const {gl} = this;
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    // const message = gl.getShaderInfoLog(shader);
    // if (message.length > 0) {
    //   console.log(message);
    // }
    return shader;
  }

  /**
   * 16.5. Generate code
   * @param {boolean} isVertex - Is vertex or fragment shader
   * @param {array} req - Array of requested functions
   * @param {string} content - Shader content code
   * @return {string} - generated shader code
   */
  generate(isVertex, req, content) {
    const {attrs, uniforms, varyings} = this.options;
    let result = '';
    let varyingContent = '';

    [attrs, uniforms, varyings].forEach((arr, i) => {
      const [arrType, sym] = [['attribute', 'a'], ['uniform', 'u'], []][i];

      arr.forEach(([type, name, varying, inFragment]) => {
        if ((isVertex || inFragment) && sym) {
          result += `${arrType} ${type} ${sym}${name};`;
        }
        if (varying) {
          result += `varying ${type} v${name};`;
          if (isVertex && sym) {
            varyingContent += `v${name} = ${sym}${name};`;
          }
        }
      });
    });

    req = req.filter((v, i) => req.indexOf(v) === i);

    const funcs = [glVertexFunctions, glFragmentFunctions][+!isVertex];
    let reqStr = '';
    ['pxPos', 'toPos', 'uvPos', 'tex', 'overlay', ...req].forEach((name) => {
      if (funcs[name]) {
        reqStr += funcs[name];
      }
    });

    return result + reqStr + `void main(){${varyingContent}${content}}`;
  }

  /**
   * 16.6. Get Vertex Buffers
   * @param {string[]} names
   * @return {array}
   */
  getVbo(...names) {
    this.curAttrs = names;
    return names.map((name) => this.vboList[name].data);
  }

  /**
   * 16.7. Update Vertex Buffers
   */
  updateVbo() {
    const {gl} = this;

    this.curAttrs.forEach((name) => {
      const isIndices = name === 'indices';
      const loc = this.attrs[name];
      const vbo = this.vboList[name];
      vbo.type = isIndices ? gl.ELEMENT_ARRAY_BUFFER : gl.ARRAY_BUFFER;
      vbo.count = vbo.data.length;

      const data = isIndices ?
        new Uint16Array(vbo.data) :
        new Float32Array(vbo.data);

      if (vbo.buffer) {
        gl.bindBuffer(vbo.type, vbo.buffer);
        gl.bufferData(vbo.type, data, gl.STATIC_DRAW);
      } else {
        vbo.buffer = gl.createBuffer();
        gl.bindBuffer(vbo.type, vbo.buffer);
        gl.bufferData(vbo.type, data, gl.STATIC_DRAW);
      }

      if (!isIndices && loc >= 0) {
        gl.enableVertexAttribArray(loc);
        gl.vertexAttribPointer(loc, 4, gl.FLOAT, false, 0, 0);
      }
      vbo.data = [];
    });
  }

  /**
   * 16.8. Clear Vertex Buffers
   */
  cleanVBO() {
    Object.values(this.vboList).forEach((value) => {
      this.gl.deleteBuffer(value.buffer);
      value.buffer = undefined;
    });
  }

  /**
   * 16.9. Load Uniform
   * @param {array} arr
   */
  uniform(arr) {
    const {gl, uniforms} = this;

    arr.forEach(([name, value]) => {
      const {type, isMatrix, loc} = uniforms[name];

      if (isMatrix) {
        gl.uniformMatrix4fv(loc, false, value);
      } else {
        gl[type](loc, value);
      }
    });
  }

  /**
   * 16.10. Clean all dynamic uniforms
   */
  cleanDynamicUniforms() {
    let i = this.dynamicUniforms.length;
    while (i--) {
      const name = this.dynamicUniforms[i];
      arrayRemove(this.options.uniforms, (uniform) => {
        return uniform[1] === name;
      });
    }
    this.dynamicUniforms = [];
  }

  /**
   * 16.11. Add dynamic uniform
   * @param {string} type - uniform type
   * @param {string} name - uniform name
   */
  addDynamicUniform(type, name) {
    this.options.uniforms.push([type, name, 0, 1]);
    this.dynamicUniforms.push(name);
  }

  /**
   * 16.12. Destroy
   */
  destroy() {
    const {gl, program, vert, frag} = this;
    this.cleanVBO();
    gl.detachShader(program, vert);
    gl.detachShader(program, frag);
    gl.deleteShader(vert);
    gl.deleteShader(frag);
    gl.deleteProgram(program);
  }
}


/**
 * 17. Animation manager
 */
const AnimationManager = {
  paused: false,
  enabled: false,
  requested: false,
  toDraw: [],
  time: 0,

  /**
   * 17.1. Change drawing status
   * @param {WGLTR} el
   */
  change(el) {
    if (el.visible) {
      this.toDraw.push(el);
    } else {
      arrayRemove(this.toDraw, el);
    }

    if (!this.enabled && this.toDraw.length > 0) {
      this.enabled = true;
      this.request();
    } else if (this.toDraw.length === 0) {
      this.enabled = false;
      this.requested = false;
      wnd.cancelAnimationFrame(this.rafID);
    }
  },

  /**
   * 17.2. Draw loop
   * @param {number} et - Elapsed time
   */
  draw(et) {
    const _et = min(et - this.time, 50);
    this.toDraw.forEach((item) => item.gl.draw(_et));
    this.time = et;

    if (this.enabled && !this.paused && !this.requested) {
      this.requested = true;
      this.rafID = raf((et) => {
        this.requested = false;
        this.draw(et);
      });
    }
  },

  /**
   * 17.3. Request animation frame
   * @param {boolean} force - request one animation frame even if paused
   */
  request(force = false) {
    if (!this.requested && (!this.paused || force)) {
      this.requested = true;

      this.rafID = raf((et) => {
        this.requested = false;
        this.time = et;

        this.draw(et);
      });
    }
  },

  /**
   * 17.4. Pause drawing for all items.
   */
  pause() {
    this.paused = true;
    this.requested = false;
    wnd.cancelAnimationFrame(this.rafID);
  },

  /**
   * 17.5. Resume drawing
   */
  resume() {
    this.paused = false;
    this.request();
  },
};


/**
 * 18. WebGL Text Renderer
 */
export class WGLTR {
  /**
   * 18.1. Initialization
   * @param {Element} el - target element
   * @param {WGLTROptions} options - main options
   * @param {WGLTROptions[]} innerOptions - options for inner elements
   * @return {WGLTR}
   */
  constructor(el, options, ...innerOptions) {
    if (WGLTR.notSupported) {
      el.classList.add(prefix, prefix + '-not-supported');
      return false;
    }
    if (el[prefix]) {
      return el[prefix];
    }

    this.el = el;
    this.options = el[prefixOptions] = options;

    // Process inner elements
    const childs = Array.from(this.el.querySelectorAll(`[${prefixInnerAttr}]`));
    childs.forEach((el) => {
      const o = parseOptions(el.getAttribute(prefixInnerAttr));
      el[prefixOptions] = o;
    });
    innerOptions.forEach((options) => {
      options['el'][prefixOptions] = options;
      childs.push(options.el);
      delete options.el;
    });
    if (options.wrapOnly) {
      this.rootWrappers = childs.filter((child) => {
        return !childs.some((c) => c !== child && c.contains(child));
      });
      Object.entries(options).forEach(([key, value]) => {
        this.rootWrappers.forEach((wrap) => {
          const o = wrap[prefixOptions];
          if (o[key] === undefined) {
            o[key] = value;
          }
        });
      });
    }

    this.resizeTimeout = -1;
    this.colorsBlocked = true;
    this.colorsChangedTimer = 0;
    this.changingCount = 0;

    this.canvas = create('canvas');
    css(this.canvas, {
      'background': 'rgba(0,0,0,0)',
      'border-radius': '0',
      'display': 'block',
      'position': 'absolute',
      'padding': '0',
      'margin': '0',
      'transform': '0',
      'top': '0',
      'left': '0',
      'z-index': '-1',
      'pointer-events': 'none',
      'image-rendering': 'pixelated',
      'will-change': 'transform',
    });
    this.contextLostCallback = () => {
      this.destroy();
    };
    this.canvas.addEventListener('webglcontextlost', this.contextLostCallback);

    this.styles = wnd.getComputedStyle(el);
    if (this.styles.position === 'static') {
      css(el, {'position': 'relative'});
    }
    if (this.styles.zIndex === 'auto') {
      css(el, {'z-index': '0'});
    }
    if (this.styles.transform !== 'none') {
      this.usesTransform = true;
    }

    this.htmlText = new HTMLText(this, this.el);
    this.gl = new GL(this.canvas, this);
    this.text = new Text(this);
    this.fontTexture = new FontTexture(this);

    this.el.prepend(this.canvas);
    WGLTR.list.push(el[prefix] = this);

    this.events = {};
    this.addEvents();

    IOToggle.observe(this.el);
    IOVisible.observe(this.el);
    RO && ROV.observe(this.el);

    if (MO) {
      this.MO = new MO(() => {
        this.resize(true);
      });
      this.moObserve();
    }

    this.scrollX = 0;
    this.scrollY = 0;
    this.mouseX = 0;
    this.mouseY = 0;

    this.scrollCallback = () => this.scroll();
    on(getScrollParent(this.options.scrollParent),
        'scroll', this.scrollCallback);

    el.classList.add(prefix);
  }

  /**
   * 18.2. MutationObserver callback
   */
  moObserve() {
    MO && this.MO.observe(this.el, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  /**
   * 18.3. Start changing
   * For smooth animation resize shouldn't happen
   */
  startChanging() {
    if (this.changingCount === 0) {
      RO && ROV.unobserve(this.el);
      MO && this.MO.disconnect();
      this.isChanging = true;
    }
    this.changingCount++;
  }

  /**
   * 18.4. Stop changing
   */
  stopChanging() {
    this.changingCount--;
    if (this.changingCount === 0) {
      MO && this.moObserve();
      RO && ROV.observe(this.el);
    }
  }

  /**
   * 18.5. Font loaded callback
   * @param {string} family
   */
  fontLoaded(family) {
    this.fontTexture.prevGlyphs = [];
    this.fontTexture.glyphs = [];
    this.fontTexture.glyphsChanged = true;

    FontBaseline.clear(family);
    this.resize();
  }

  /**
   * 18.6. Mouse move callback
   * @param {number?} x - screen x
   * @param {number?} y - screen y
   */
  'mousemove'(x = this.mouseX, y = this.mouseY) {
    const parent = this.options.scrollParent;

    this.mouseX = x;
    this.mouseY = y;
    this.text.updateMouseUniform(
        x + parent.scrollLeft, y + parent.scrollTop, 0);
  }

  /**
   * 18.7. Scroll callback
   */
  'scroll'() {
    const parent = this.options.scrollParent;
    const speedX = this.scrollX - parent.scrollLeft;
    const speedY = this.scrollY - parent.scrollTop;

    this.scrollX = parent.scrollLeft;
    this.scrollY = parent.scrollTop;

    this.text.setScrollsStrength(abs(speedY) > abs(speedX) ? speedY : speedX);
    this.mousemove();
  }

  /**
   * 18.8. Resize callback
   * @param {boolean} force - Resize immediately
   * @param {boolean} soft - Do not recalculate text positions
   */
  'resize'(force = false, soft = false) {
    if (this.isDestroyed) {
      return;
    }

    if (force) {
      this.resizeTimeout = -1;
      const {htmlText, text, fontTexture} = this;

      if (soft) {
        // If soft then refresh only styles ids & paddings
        htmlText.refreshStylesInfo();
      } else {
        // If hard then update all text elements and positions
        htmlText.resize();
        htmlText.refreshCoords();

        if (htmlText.sizeChanged) {
          htmlText.sizeChanged = false;
          fontTexture.glyphsChanged = true;
          fontTexture.updateShader();
          text.updateShader();
        }
      }

      fontTexture.refresh();
      text.refresh();
      text.drawBackgrounds();
      text.updateBackgroundsUniforms();

      this.refreshCanvasPosition();
      this.refreshCanvasSize();
      text.updateBuffers();
      this.colorsBlocked = false;

      // Call redraw even if unfocused
      this.gl.draw(0);
    } else {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.resize(true);
      }, 1);
    }
  }

  /**
   * 18.9. Remove CSS transform
   */
  removeTransform() {
    if (this.usesTransform) {
      this.transform = this.styles.transform;
      css(this.el, {transform: 'none'});
    }
  }

  /**
   * 18.10. Restore CSS transform
   */
  restoreTransform() {
    if (this.usesTransform) {
      css(this.el, {transform: this.transform});
    }
  }

  /**
   * 18.11. Refresh canvas position
   */
  refreshCanvasPosition() {
    const {canvas, text} = this;

    css(canvas, {
      left: `${text.rect.x / dpr}px`,
      top: `${text.rect.y / dpr}px`,
    });
  }

  /**
   * 18.12. Refresh canvas size
   */
  refreshCanvasSize() {
    const {canvas, text} = this;

    css(canvas, {width: `${text.rect.width / dpr}px`});
    canvas.width = text.rect.width;
    canvas.height = text.rect.height;
    this.gl.resize();
  }

  /**
   * 18.13. Draw text
   * @param {number} et - elapsed time
   */
  draw(et) {
    if (this.colorsChanged || this.colorsChangedTimer > 0) {
      this.colorsChanged = this.htmlText.refreshColors();
      this.text.updateBufferColors();

      if (this.colorsChanged) {
        this.colorsChangedTimer = defaultOptions.colorsChangeDelay;
      } else {
        this.colorsChangedTimer--;
      }

      if (this.htmlText.useShadow &&
          this.htmlText.nodes.some((item) => item.shadowChanged)) {
        this.text.charsPxToPercent();
        this.text.updateBufferPositions();
      }
    }

    this.text.draw(et);
  }

  /**
   * 18.14. Set options
   * @param {WGLTROptions} options - New options
   */
  'setOptions'(...options) {
    if (this.isDestroyed) {
      return;
    }

    const r = optionsRefresh;
    const list = Object.keys(optionsRefresh);
    const need = list.reduce((result, key) => {
      result[key] = false;
      return result;
    }, {});

    options.forEach((newOptions) => {
      let options = this.options;
      if (newOptions.el) {
        options = this.htmlText.nodes.find((node) => {
          return node.node === newOptions.el;
        });
        options = options ? options.options : this.options;
        delete newOptions.el;
      }
      Object.entries(newOptions).forEach(([key, value]) => {
        Options.setOption(options, key, value);
      });

      list.forEach((key) => {
        need[key] = need[key] || r[key].some((v) => v in newOptions);
      });
    });

    // if (this.htmlText.chars.length === 0) {
    //   return;
    // }

    if (need.textureHard) {
      this.fontTexture.calcDecors();
      this.fontTexture.updateShader();
      this.fontTexture.loadDecors();
    }
    if (need.textureSoft) {
      this.fontTexture.glyphsChanged = true;
    }

    if (need.shadersHard) {
      this.text.refreshBackgrounds();
      this.text.refreshScrolls();
      this.text.updateShader();
      this.text.updateBackgroundsUniforms();
      this.text.updateBuffers();
    }
    if (need.shadersSoft) {
      this.text.updateUniforms();
    }

    if (need.resizeHard) {
      this.resize(true, false);
    } else if (need.resizeSoft) {
      this.resize(true, true);
    }
  }

  /**
   * 18.15. Change text with animation
   * @param {HTMLElement} el
   * @param {string} str
   */
  'change'(el = this.el, str = '') {
    const nodes = this.htmlText.nodes;
    const node = !el ? nodes[0] : nodes.find((node) => {
      return node.node === el;
    });

    const {change, options} = node;
    if (change) {
      const last = change.order.length ?
          change.order[change.order.length-1].index : change.current;
      let index = last + 1;

      if (options.changeRandom) {
        index = last;
        while (index === last) {
          index = round(random() * change.nodes.length);

          if (options.changeSkipFirst && index === 0) {
            index = last;
          }
        }
      }

      change.order.push({str, index});
    } else {
      console.warn('WGLTR. Change node not found!');
    }
  }

  /**
   * 18.15.1. Is change ready
   * @param {HTMLElement} [el]
   * @return {boolean}
   */
  'isChangeReady'(el) {
    if (el === undefined) {
      return this.htmlText.changeNodes.every((node) => {
        return this.isChangeReady(node.node);
      });
    }

    const node = this.htmlText.changeNodes.find((node) => node.node === el);
    if (node) {
      const inProgress = node.change.isAppear || node.change.isDisappear;
      return !(node.change.order.length > 0 || inProgress);
    }

    console.warn('WGLTR. Change node not found!');
    return true;
  }

  /**
   * 18.16. Is text appeared
   * @return {boolean}
   */
  'isAppeared'() {
    return !!this.text.isAppeared && !this.text.isManualDisappear;
  }
  /**
   * 18.17. Is text manual appeared
   * @return {boolean}
   */
  'isManualAppeared'() {
    return !!this.text.isAppeared && this.text.isManualAppear;
  }
  /**
   * 18.18. Is text disappeared
   * @return {boolean}
   */
  'isDisappeared'() {
    return this.text.isManualDisappear && !!this.text.isAppeared;
  }

  /**
   * 18.19. Reset text appear and change
   */
  'reset'() {
    this.htmlText.changeNodes.forEach((node) => {
      const {change} = node;
      change.previous = 0;
      change.current = 0;
      change.isDisappear = false;
      change.isAppear = false;
      change.toggle = false;
      change.order = [];
      change.time = 0;
    });

    this.text.manualStartTime = Infinity;
    this.text.isManualAppear = false;
    this.text.isManualDisappear = false;
    this.text.isAppeared = false;
    this.text.time = 0;

    this.resize(true, false);
  }

  /**
   * 18.20. Manual Appear
   */
  'appear'() {
    this.text.appear();
  }

  /**
   * 18.21. Manual Disappear
   */
  'disappear'() {
    this.text.disappear();
  }

  /**
   * 18.23. Dispatch WGLTR event
   * @param {Event} event
   * @param {HTMLElement} node
   */
  dispatchEvent(event, node) {
    Object.defineProperty(WGLTR.events[event], 'target', {
      configurable: true,
      value: node || this.el,
    });
    this.el.dispatchEvent(WGLTR.events[event]);
  }

  /**
   * 18.24. Add WGLTR events
   */
  addEvents() {
    Object.entries(WGLTR.events).forEach(([key, event]) => {
      this.events[key] = (e) => {
        this.options[key] && this.options[key](e);
      };
      on(this.el, event.type, this.events[key]);
    });
  }

  /**
   * 18.25. Remove WGLTR events
   */
  removeEvents() {
    Object.entries(WGLTR.events).forEach(([key, event]) => {
      off(this.el, event.type, this.events[key]);
    });
  }

  /**
   * 18.26. Destroy
   */
  'destroy'() {
    clearTimeout(this.resizeTimeout);
    this.canvas.removeEventListener('webglcontextlost',
        this.contextLostCallback);

    this.removeEvents();
    this.htmlText.destroy();
    this.canvas.remove();

    off(getScrollParent(this.options.scrollParent),
        'scroll', this.scrollCallback);
    IOToggle.unobserve(this.el);
    IOVisible.unobserve(this.el);
    RO && ROV.unobserve(this.el);
    MO && this.MO.disconnect();

    this.visible = false;
    AnimationManager.change(this);

    this.fontTexture.destroy();
    // Lose context cause white canvas bg, so 100ms delay
    setTimeout(() => this.gl.destroy(), 100);

    css(this.el, {'position': '', 'z-index': ''});

    arrayRemove(WGLTR.list, this);
    this.el[prefix] = undefined;
    this.isDestroyed = true;
  }
}


/**
 * 19. WebGL Text Renderer global variables
 */
WGLTR['events'] = {
  'appeared': new Event(prefix + 'Appeared'),
  'disappeared': new Event(prefix + 'Disappeared'),
  'changed': new Event(prefix + 'Changed'),
  'changeStarted': new Event(prefix + 'ChangeStarted'),
  'changeAppeared': new Event(prefix + 'ChangeAppeared'),
  'changeDisappeared': new Event(prefix + 'ChangeDisappeared'),
};
WGLTR['defaultOptions'] = defaultOptions;
WGLTR['presets'] = defaultPresets;
WGLTR['simplePresets'] = defaultSimplePresets;
/** @type {WGLTR[]} */
WGLTR['list'] = [];

/**
 * 20. WebGL Text Renderer global functions
 * 20.1. Refresh new elements by attribute
 * @return {array} - list of new founded WGLTR elements
 */
WGLTR['refresh'] = function() {
  const result = [];
  q(`[${prefixAttr}]`).forEach((el) => {
    result.push(new WGLTR(
        el,
        parseOptions(el.getAttribute(`${prefixAttr}`)),
    ));
  });
  return result;
};

/**
 * 20.2. Resize all WGLTR instances
 * @param {boolean} force
 * @param {boolean} soft
 */
WGLTR['resize'] = function(force, soft) {
  this.list.forEach((wgltr) => wgltr.resize(force, soft));
};

/**
 * 20.3. Destroy all WGLTR instances
 */
WGLTR['destroy'] = function() {
  let i = this.list.length;
  while (i--) {
    this.list[i].destroy();
  }
};

/**
 * 20.4. Set default options
 * @param {WGLTROptions} options - New options
 */
WGLTR['setDefaultOptions'] = function(options) {
  Object.assign(defaultOptions, options);
  this['refreshIO']();
};


/**
 * 20.5. Refresh Intersection Observer
 */
WGLTR['refreshIO'] = function() {
  if (IOToggle) {
    WGLTR.list.forEach((item) => IOToggle.unobserve(item.el));
  }
  IOToggle = new IO((entries) => entries.forEach((entry) => {
    const wgltr = entry.target[prefix];
    wgltr.visible = entry.isIntersecting;
    AnimationManager.change(wgltr);
  }), {
    rootMargin: defaultOptions.appearMargin + 'px',
    threshold: [0],
  });
  WGLTR.list.forEach((item) => IOToggle.observe(item.el));
};


/**
 * 21. Compatibility check
 */
if (!(wnd.WebGLRenderingContext && getWebGLContext(create('canvas'))) ||
  !IO) {
  console.warn('WGLTR. Failed to get WebGL context!');
  WGLTR.notSupported = true;
}

/**
 * 22. Global events
 */
/**
 * 22.1. Intersection Observer
 */
let IOToggle;
const IOVisible = new IO((entries) => entries.forEach((entry) => {
  if (entry.isIntersecting) {
    IOVisible.unobserve(entry.target);
  }
}), {
  rootMargin: `-50px 0px -50px 0px`,
});
WGLTR.refreshIO();

/**
 * 22.2. Resize Observer
 */
const ROV = wnd.ResizeObserver && new wnd.ResizeObserver((entries) => {
  dpr = wnd.devicePixelRatio;

  entries.forEach((entry) => {
    const wgltr = entry.target[prefix];
    if (wgltr.isChanging) {
      wgltr.isChanging = false;
    } else {
      wgltr.resize(false, false, true);
    }
  });
});

/**
 * 22.3. Window events
 */
on(wnd, 'load', () => {
  WGLTR.refresh();
  if (!WGLTR.notSupported) {
    !RO && WGLTR.resize();
  }
});
on(wnd, 'resize', () => {
  dpr = wnd.devicePixelRatio;
  WGLTR.resize();
});
on(wnd, 'mousemove', (e) => {
  WGLTR.list.forEach((wgltr) => {
    wgltr.mousemove(e.clientX, e.clientY);
  });
});
on(wnd, 'blur', () => {
  if (defaultOptions.pauseOnBlur) {
    AnimationManager.pause();
  }
});
on(wnd, 'focus', () => {
  if (defaultOptions.pauseOnBlur) {
    AnimationManager.resume();
  }
});
