import React, { useEffect, useRef } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  BrowserPreviewWidget,
  Cell,
  Layout,
  Page,
  Image,
  Box,
  Button,
  Text,
  TableActionCell,
  MultiSelect,
  FormField,
  TextButton,
  Card,
  Table,
  TableToolbar,
  Thumbnail,
  SidePanel,
  ColorInput,
  ComposerSidebar,
  ComposerHeader,
  AddItem,
  Stepper,
  WixDesignSystemProvider,
  MobilePreviewWidget,
  SegmentedToggle,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  IconButton,
  CardGalleryItem,
  accordionItemBuilder,
  Accordion,
  Tooltip,
  FloatingHelper,
  Badge,
  PulseAnimation,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { WGLTR } from "./wgltr.js";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

import TextIcon from "./Text.png";
import TextFlag from "./text-flag.png";
import "./App.css";

const PLAN_LIMIT: any = {
  starter: 1,
  lite: 5,
  pro: 15,
  advanced: 1000,
};

const VIEW_TYPE = {
  desktopView: "desktopView",
  mobileView: "mobileView",
};

const SELECTION_TOOL_STATE = {
  idle: "idle",
  selecting: "selecting",
  pending: "pending",
};

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = ["Everything in Lite +", "One Classic Pro Widgets", "WIP"];

const INTERVAL = 4000;

function App() {
  ReactTagManager.init(tagManagerArgs);

  const reloadTimeoutRef = useRef(null);
  const messageReceivedFlag = useRef(false);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [effects, setEffects] = React.useState([] as any);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);

  const [isOldExperience, setIsOldExperience] = React.useState(true);

  const [options, setOptions] = React.useState({
    effects: "",
    staticEffects: "",
    compId: null,
  } as any);

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedSidebar, setSelectedSidebar] = React.useState(0 as any);

  const [selectionToolState, setSelectionToolState] = React.useState(
    SELECTION_TOOL_STATE.pending
  );

  const [isFirstPublish, setIsFirstPublish] = React.useState(true);

  const iframeRef = useRef(null);

  const [siteUrl, setSiteUrl] = React.useState("https://www.wixstud.io/");
  const [zoomLevel, setZoomLevel] = React.useState(1);

  const [viewType, setViewType] = React.useState(VIEW_TYPE.desktopView);

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    // Function to handle messages received from the iframe
    const handleMessage = (event: any) => {
      // Handle the received message
      console.log("Message received in React:", event.data);

      // Add your custom logic to handle the message as needed
      if (event.data.action === "elementClicked") {
        var compId = event.data.compId;
        if (compId) {
          setOptions({
            ...options,
            compId,
          });
        }
        // @ts-ignore
        iframeRef.current.contentWindow.postMessage(
          {
            action: "hideSelectionTool",
          },
          "*"
        );
        setSelectionToolState(SELECTION_TOOL_STATE.idle);
      }

      if (event.data.action === "selectorReady") {
        setSelectionToolState(SELECTION_TOOL_STATE.idle);
        messageReceivedFlag.current = true;
      }
      // @ts-ignore
      reloadTimeoutRef.current = setTimeout(() => {
        if (!messageReceivedFlag.current && canUseNewExperience) {
          // Trigger reload logic here
          window.location.reload();
        }
      }, 10000); // 10 seconds
    };

    // Add event listener for messages
    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("message", handleMessage);
      // @ts-ignore
      clearTimeout(reloadTimeoutRef.current);
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/text/_functions", // Replace with your API base URL
      applicationID: "eb222f2f-c938-4174-83cf-54d4918d3c51", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, (value: boolean) => {
      setIsUpgraded(value);
      setIsUpgradeBannerOpen(!value);
    });
    fetch("https://certifiedcode.wixsite.com/text/_functions/script", {
      headers: {
        Authorization: instance!,
      },
    });
    listEffects();
  }, []);

  useEffect(() => {
    console.log(options);
    if (options.compId) {
      // @ts-ignore
      iframeRef.current.contentWindow.postMessage(
        {
          action: "selectElement",
          compId: options.compId,
          attributes: {
            "data-wgltr":
              // join in key: value and seperate with ,
              Object.keys(options)
                .filter(
                  (k) =>
                    Object.keys(WGLTR.defaultOptions).includes(k) && options[k]
                )
                .map((k) => `${k}:${options[k]}`)
                .join(","),
          },
        },
        "*"
      );
    }
  }, [options]);

  useEffect(() => {
    setSiteUrl((instanceData as any)?.site?.url);
    // if (
    //   instanceData?.instance?.instanceId ===
    //   "1b0ae663-cdc1-4708-b3bb-9853c9212673"
    // ) {
    //   setIsOldExperience(false);
    // }
  }, [instanceData]);

  useEffect(() => {
    if (effects?.length === 1 && options?._id) {
      setIsFirstPublish(true);
    }
  }, [effects]);

  const EFFECTS_BASE_URL =
    "https://certifiedcode.wixsite.com/text/_functions/effects";

  function listEffects() {
    fetch(EFFECTS_BASE_URL, {
      method: "GET",
      headers: {
        Authorization: instance!,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEffects(data);
      })
      .catch((error) => {});
  }

  function upsertEffect() {
    if (options?._id) {
      fetch(`${EFFECTS_BASE_URL}/${options._id}`, {
        method: "PUT",
        headers: {
          Authorization: instance!,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          listEffects();
          setSelectedSidebar(2);
        })
        .catch((error) => {});
    } else {
      fetch(EFFECTS_BASE_URL, {
        method: "POST",
        headers: {
          Authorization: instance!,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          listEffects();
          setSelectedSidebar(2);
        })
        .catch((error) => {});
    }
  }

  function deleteEffect() {
    if (options?._id) {
      fetch(`${EFFECTS_BASE_URL}/${options._id}`, {
        method: "DELETE",
        headers: {
          Authorization: instance!,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setOptions(null);
          setSelectedSidebar(0);
          listEffects();
        })
        .catch((error) => {});
    }
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=eb222f2f-c938-4174-83cf-54d4918d3c51&redirectUrl=https://certifiedcode.wixsite.com/text/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const items = [
    {
      id: 0,
      label: "Basic",
      icon: <Icons.Add />,
    },
    {
      id: 1,
      label: "Advanced",
      icon: <Icons.Background />,
    },
    ...(effects?.length > 0
      ? [
          {
            id: 2,
            label: "All Effects",
            icon: <Icons.List />,
          },
        ]
      : []),
  ];

  const handleNextClick = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBackClick = () => {
    if (activeStep === 0) {
      setIsOldExperience(true);
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const handleCloseSidePanel = () => setSelectedSidebar(null);

  const upgradeUrl = `https://www.wix.com/apps/upgrade/eb222f2f-c938-4174-83cf-54d4918d3c51?appInstanceId=${
    (instanceData as any)["instance"]["instanceId"]
  }`;

  const renderPublishPage = () => {
    return (
      <Page>
        <Page.Header title="Publish & Share on Social" />
        <Page.Content>
          <Layout>
            <Cell span={6}>
              <BrowserPreviewWidget
                backgroundColor="D70"
                skin="custom"
                browserBarSize="size18"
              >
                <Image
                  width="450px"
                  height="200px"
                  src="example.jpg"
                  borderRadius={0}
                />
              </BrowserPreviewWidget>
            </Cell>
            <Cell span={6}>
              <Box paddingBottom="30px" paddingTop="24px">
                <Text>
                  Once you publish a campaign online, you can share it on social
                  to reach more people. To send it as an email, go back and add
                  recipients.
                  <br />
                  <br />
                  <b>Note:</b> Publishing a campaign without sending it first
                  will count as one of your monthly campaigns.
                </Text>
              </Box>
              <Box align="right" gap={1}>
                <Button priority="secondary">Cancel</Button>
                <Button>Publish</Button>
              </Box>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    );
  };

  const renderRecipientsPage = () => {
    const columns = [
      {
        title: "Name",
        render: (row: any) => row.name,
        width: "40%",
      },
      {
        title: "Email",
        render: (row: any) => row.email,
        width: "30%",
      },
      {
        title: "Phone",
        render: (row: any) => row.phone,
        width: "20%",
      },
      {
        render: (row: any) => (
          <TableActionCell
            popoverMenuProps={{ appendTo: "window" }}
            secondaryActions={[
              {
                icon: <Icons.Delete />,
                text: "Delete",
                onClick: () => {},
              },
            ]}
          />
        ),
        width: "10%",
      },
    ];

    const records = [
      { name: "Yuki Tsunoda", email: "yukif1@mail.com", phone: "214-399-0638" },
      {
        name: "Ben Simons",
        email: "ben.simons@mail.com",
        phone: "269-267-2573",
      },
      {
        name: "Alex Halifax",
        email: "ahalifaxalex@mail.com",
        phone: "317-900-9252",
      },
      {
        name: "Walter Jenning",
        email: "walterjen@mail.com",
        phone: "440-263-0433",
      },
    ];
    return (
      <Page>
        <Page.Header
          title="Add Recipients"
          subtitle="Type your recipients below or select from your contacts, labels, or filters."
        />
        <Page.Content>
          <Layout>
            <Cell>
              <FormField label="To:">
                <MultiSelect
                  popoverProps={{ appendTo: "window" }}
                  tags={[{ id: "0", label: "Alex Halifax" }]}
                  options={[]}
                  customSuffix={
                    <Box>
                      <TextButton prefixIcon={<Icons.Add />}>
                        Add Contacts
                      </TextButton>
                    </Box>
                  }
                />
              </FormField>
            </Cell>
            <Cell>
              <Card>
                {/* @ts-ignore */}

                <Table data={records} columns={columns} showSelection>
                  <Page.Sticky>
                    <Card>
                      <TableToolbar>
                        <TableToolbar.ItemGroup position="start">
                          <TableToolbar.Item>
                            <TableToolbar.Title>Contacts</TableToolbar.Title>
                          </TableToolbar.Item>
                        </TableToolbar.ItemGroup>
                      </TableToolbar>
                      <Table.Titlebar />
                    </Card>
                  </Page.Sticky>
                  <Card>
                    <Table.Content titleBarVisible={false} />
                  </Card>
                </Table>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    );
  };

  const renderThumbnail = (title: string | null, type: string) => (
    <Thumbnail
      selected={options[type] === title}
      title={
        title ? (
          <Text size="small">
            {
              // auto convert camelCase to space and capitalize first letter
              title
                .replace(/([A-Z])/g, " $1")
                .replace(/^./, function (str: string) {
                  return str.toUpperCase();
                })
            }
          </Text>
        ) : (
          <Icons.Block />
        )
      }
      // image={!title && <Icons.Block />}
      onClick={() => {
        if (type === "appearEffects") {
          setOptions({ ...options, [type]: title, appear: !!title });
          return;
        }
        setOptions({ ...options, [type]: title });
      }}
      // backgroundImage={isBackground ? <Image height="114px" /> : undefined}
      // height="114px"
      // width="180px"
    />
  );

  const renderCreateStepSidePanel = () => (
    <SidePanel width="320px" onCloseButtonClick={handleCloseSidePanel}>
      <SidePanel.Header title="Design text" showDivider={false} />
      <SidePanel.Content>
        <Layout>
          <Cell span={12}>
            <PulseAnimation
              color="B10"
              borderRadius={18}
              active={selectionToolState === SELECTION_TOOL_STATE.idle}
            >
              <Button
                skin={
                  options?.compId &&
                  selectionToolState === SELECTION_TOOL_STATE.idle
                    ? "standard"
                    : "inverted"
                }
                disabled={selectionToolState !== SELECTION_TOOL_STATE.idle}
                prefixIcon={<Icons.SelectionTool />}
                onClick={() => {
                  console.log(iframeRef);
                  // @ts-ignore
                  iframeRef.current.contentWindow.postMessage(
                    {
                      action: "showSelectionTool",
                    },
                    "*"
                  );
                  setSelectionToolState(SELECTION_TOOL_STATE.selecting);
                }}
              >
                {selectionToolState === SELECTION_TOOL_STATE.pending ? (
                  <Loader size="tiny" />
                ) : options?.compId ? (
                  "Change Element"
                ) : (
                  "Choose Element"
                )}
              </Button>
            </PulseAnimation>
          </Cell>
          {Object.keys(WGLTR.presets.effects).map((v) => {
            <Cell span={6}>{renderThumbnail(null, "effects")}</Cell>;
            return <Cell span={6}>{renderThumbnail(v, "effects")}</Cell>;
          })}
        </Layout>
      </SidePanel.Content>
    </SidePanel>
  );

  const renderListSidePanel = () => (
    <SidePanel width="320px" onCloseButtonClick={handleCloseSidePanel}>
      <FloatingHelper
        target={<SidePanel.Header title="Manage effects" showDivider={false} />}
        opened={isFirstPublish}
        onClose={() => setIsFirstPublish(false)}
        content=<FloatingHelper.Content
          title="Congratulations 🎉 You have published your first effect."
          body="You can manage all your effects here."
        />
        placement="right"
      ></FloatingHelper>
      <SidePanel.Content noPadding>
        {/* <Box width={320}> */}
        <Accordion
          items={effects.map((effect: any) =>
            accordionItemBuilder({
              subtitle: effect?.compId,
              icon:
                options?._id === effect?._id ? (
                  <Icons.Check />
                ) : (
                  <Icons.CircleLarge />
                ),
              onToggle: () => {
                setOptions(effect);
              },
              buttonType: "node",
              disabled: options?._id === effect?._id,
            })
          )}
        />
        {/* </Box> */}
      </SidePanel.Content>
    </SidePanel>
  );

  const renderOtherEffectsSidePanel = () => {
    return (
      <SidePanel width="320px" onCloseButtonClick={handleCloseSidePanel}>
        <SidePanel.Header title="Change effects" showDivider={false} />
        <SidePanel.Content noPadding>
          <Box
            direction="vertical"
            gap="24px"
            width="252px"
            padding="0 24px 24px 24px"
          >
            <FormField
              suffix={<Icons.CircleDashed />}
              label="Static Effects"
              infoContent="Static effects are effects that are applied at the texture creation stage to each glyph. Effects can contain any number of effects of different types and in any order."
            />
            <Layout>
              <Cell span={6}>{renderThumbnail(null, "staticEffects")}</Cell>
              {Object.keys(WGLTR.presets.staticEffects).map((v) => {
                return (
                  <Cell span={6}>{renderThumbnail(v, "staticEffects")}</Cell>
                );
              })}
            </Layout>

            <FormField
              suffix={<Icons.Enter />}
              label="Appear Effects"
              infoContent="Appear effects are dynamic effects that are applied when playing an appearance animation or text change animation. Effects can contain any number of effects of different types and in any order."
            />
            <Layout>
              <Cell span={6}>{renderThumbnail(null, "appearEffects")}</Cell>
              {Object.keys(WGLTR.presets.appearEffects).map((v) => {
                return (
                  <Cell span={6}>{renderThumbnail(v, "appearEffects")}</Cell>
                );
              })}
            </Layout>

            {/* <FormField
              suffix={<Icons.Exit />}
              label="Disappear Effects"
              infoContent="Disappear effects contain the same effects as appearEffects but are played in reverse order."
            />
            <Layout>
              {Object.keys(WGLTR.presets.appearEffects).map((v) => {
                return (
                  <Cell span={6}>{renderThumbnail(v, "disappearEffects")}</Cell>
                );
              })}
            </Layout> */}

            <FormField
              suffix={<Icons.SeatSelectionTool />}
              label="Mouse Effects"
              infoContent="Mouse effects are dynamic effects that are applied depending on the cursor position. Effects can contain any number of effects of different types and in any order."
            />
            <Layout>
              <Cell span={6}>{renderThumbnail(null, "mouseEffects")}</Cell>
              {Object.keys(WGLTR.presets.mouseEffects).map((v) => {
                return (
                  <Cell span={6}>{renderThumbnail(v, "mouseEffects")}</Cell>
                );
              })}
            </Layout>

            <FormField
              suffix={<Icons.PaddingVertical />}
              label="Scroll Effects"
              infoContent="Scroll effects are dynamic effects that are applied depending on your scroll speed. Effects can contain any number of effects of different types and in any order."
            />
            <Layout>
              <Cell span={6}>{renderThumbnail(null, "scrollEffects")}</Cell>
              {Object.keys(WGLTR.presets.scrollEffects).map((v) => {
                return (
                  <Cell span={6}>{renderThumbnail(v, "scrollEffects")}</Cell>
                );
              })}
            </Layout>
          </Box>
        </SidePanel.Content>
      </SidePanel>
    );
  };

  const canUseNewExperience = (
    instanceData?.instance?.permissions || []
  ).includes("APPS.MANAGE_EMBEDDED_SCRIPT");

  const changeViewType = (_: any, value: any) => setViewType(value);

  const zoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const resetZoom = () => {
    setZoomLevel(1);
  };

  const desktopView = () => (
    <BrowserPreviewWidget browserBarSize="size24" skin="gradient">
      <iframe
        ref={iframeRef}
        sandbox="allow-scripts"
        style={{
          border: "none",
          transform: `scale(${zoomLevel})`,
        }}
        width={"980px"}
        height={"500px"}
        src={`${siteUrl}?useselector=true`}
      />
    </BrowserPreviewWidget>
  );

  const mobileView = () => (
    <MobilePreviewWidget skin="gradient" height="718px">
      <iframe
        ref={iframeRef}
        sandbox="allow-scripts"
        style={{
          border: "none",
          transform: `scale(${zoomLevel})`,
        }}
        width={"100%"}
        height={"100%"}
        src={`${siteUrl}?useselector=true`}
      />
    </MobilePreviewWidget>
  );

  const renderCreateStep = () => (
    <>
      <ComposerSidebar
        labelPlacement="bottom"
        items={items}
        selectedId={selectedSidebar}
        // @ts-ignore
        onClick={(_: any, { id }: any) => setSelectedSidebar(id)}
      />
      {selectedSidebar === 0 && renderCreateStepSidePanel()}
      {selectedSidebar === 1 && renderOtherEffectsSidePanel()}
      {selectedSidebar === 2 && renderListSidePanel()}

      <Box backgroundColor="D70" width="100%">
        {/* <Box width="100%" padding={4}> */}
        {viewType === VIEW_TYPE.desktopView ? desktopView() : mobileView()}
        {/* </Box> */}
      </Box>
    </>
  );
  const styles = {
    card: {
      maxWidth: 345,
    },
    gridFour: {
      maxWidth: 345,
    },
    media: {
      // ⚠️ object-fit is not supported by IE11.
      objectFit: "cover",
    },
  };

  if (isOldExperience) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Card>
          <MarketingPageLayout
            removeImageHorizontalPadding
            removeImageVerticalPadding
            content={
              <Box height="840px" verticalAlign="middle">
                <MarketingPageLayoutContent
                  title="Text: Pro Effects & Animations"
                  content="Design stunning text effects for your website in a few clicks."
                  actions={
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.Edit />}
                        as="a"
                        href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                        target="_blank"
                      >
                        Edit Site
                      </Button>
                      {canUseNewExperience ? (
                        <Button
                          // disabled={true}
                          skin="inverted"
                          suffixIcon={<Icons.ArrowRight />}
                          onClick={() => {
                            setIsOldExperience(false);
                          }}
                        >
                          {/* Coming Soon */}
                          Switch to new experience
                        </Button>
                      ) : (
                        <Button
                          // disabled={true}
                          skin="inverted"
                          suffixIcon={<Icons.ArrowRight />}
                          // suffixIcon={<Icons.ExternalLink />}
                          as="a"
                          href={`https://go.certifiedcode.global/get/eb222f2f-c938-4174-83cf-54d4918d3c51/${instanceData?.site?.siteId}`}
                          target="_blank"
                        >
                          {/* Coming Soon */}
                          Switch to new experience
                        </Button>
                      )}
                    </Box>
                  }
                />
              </Box>
            }
            image={<Image borderRadius={0} src={TextFlag} />}
          />
        </Card>
      </WixDesignSystemProvider>
    );
  }

  if (instanceData && !canUseNewExperience) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Card>
          <MarketingPageLayout
            removeImageHorizontalPadding
            removeImageVerticalPadding
            content={
              <Box height="840px" verticalAlign="middle">
                <MarketingPageLayoutContent
                  title="The new way building text effects is available"
                  content="Build super stunning text effects for your website in a few clicks."
                  actions={
                    <Box gap="SP2">
                      <Button
                        suffixIcon={<Icons.ExternalLink />}
                        as="a"
                        href={`https://go.certifiedcode.global/get/eb222f2f-c938-4174-83cf-54d4918d3c51/${instanceData?.site?.siteId}`}
                        target="_blank"
                      >
                        Get Started
                      </Button>
                      <Button
                        suffixIcon={<Icons.RevertReset />}
                        skin="inverted"
                        onClick={() => {
                          setIsOldExperience(true);
                        }}
                      >
                        Back to classic experience
                      </Button>
                    </Box>
                  }
                />
              </Box>
            }
            // image={<Image borderRadius={0} src={TextImage} />}
          />
        </Card>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type="sticky">
        <Notification.TextLabel>
          {`Upgrade to unlock "${CONTENT[index]}"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Layout
        gap={0}
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Cell>
          <ComposerHeader
            backButtonValue={
              activeStep > 0 ? "Back" : "Back to classic experience"
            }
            onBackClick={handleBackClick}
          >
            {/* @ts-ignore */}
            <ComposerHeader.Actions justifyContent="center">
              {/* <Stepper
                type="text"
                activeStep={activeStep}
                steps={[
                  { text: "Create Effects" },
                  { text: "Add Recipients" },
                  { text: "Publish & Send" },
                ]}
                onClick={(step: any) => setActiveStep(step)}
              /> */}
              <Image borderRadius={0} transparent width={48} src={TextIcon} />
              <FloatingHelper
                placement="bottom"
                initiallyOpened={true}
                target={
                  <Badge
                    skin="warningLight"
                    suffixIcon={<Icons.StatusAlertFilledSmall />}
                  >
                    Pro Effects BETA
                  </Badge>
                }
                content={
                  <FloatingHelper.Content
                    title="Beta version might contain bugs."
                    body="In the beta version, some features may not work as expected/available. If you encounter any issues, please contact us."
                    actionText="Contact Support"
                    onActionClick={() =>
                      window.Intercom(
                        "showNewMessage",
                        "Hi, I need help with Text (Beta)!"
                      )
                    }
                  />
                }
              />
            </ComposerHeader.Actions>
            {activeStep !== 2 && (
              <ComposerHeader.MainActions>
                <Box>
                  <SegmentedToggle selected={viewType} onClick={changeViewType}>
                    <SegmentedToggle.Icon
                      value={VIEW_TYPE.desktopView}
                      tooltipText="Desktop"
                    >
                      <Icons.Desktop />
                    </SegmentedToggle.Icon>
                    <SegmentedToggle.Icon
                      value={VIEW_TYPE.mobileView}
                      tooltipText="Mobile"
                    >
                      <Icons.Mobile />
                    </SegmentedToggle.Icon>
                  </SegmentedToggle>
                </Box>
                <Box>
                  <SegmentedToggle
                    selected={"X"}
                    onClick={(_: any, value: any) => {
                      if (value === "zoom") {
                        zoomIn();
                      } else {
                        resetZoom();
                      }
                    }}
                  >
                    <SegmentedToggle.Icon
                      onClick={zoomIn}
                      value={"zoom"}
                      tooltipText="Zoom In"
                    >
                      <Icons.ZoomIn />
                    </SegmentedToggle.Icon>
                    <SegmentedToggle.Icon
                      onClick={resetZoom}
                      value={"reset"}
                      disabled={zoomLevel === 1}
                      tooltipText="Reset Zoom"
                    >
                      <Icons.RevertReset />
                    </SegmentedToggle.Icon>
                  </SegmentedToggle>
                </Box>
                {options?._id && (
                  <Button
                    prefixIcon={<Icons.Delete />}
                    skin="destructive"
                    priority="secondary"
                    onClick={deleteEffect}
                  >
                    Delete
                  </Button>
                )}
                {effects?.length <=
                  PLAN_LIMIT[
                    instanceData?.instance?.billing?.packageName || "starter"
                  ] &&
                  !options?._id && (
                    <Button
                      disabled={!options?.compId}
                      onClick={upsertEffect}
                      prefixIcon={<Icons.GetStarted />}
                    >
                      Publish
                    </Button>
                  )}
                {effects?.length <=
                  PLAN_LIMIT[
                    instanceData?.instance?.billing?.packageName || "starter"
                  ] &&
                  !options?._id && (
                    <IconButton
                      skin="premium"
                      priority="secondary"
                      as="a"
                      href={`https://www.wix.com/apps/upgrade/eb222f2f-c938-4174-83cf-54d4918d3c51?appInstanceId=${
                        (instanceData as any)["instance"]["instanceId"]
                      }`}
                      target={"_blank"}
                    >
                      <Icons.PremiumFilled />
                    </IconButton>
                  )}
                {effects?.length >
                  PLAN_LIMIT[
                    instanceData?.instance?.billing?.packageName || "starter"
                  ] &&
                  !options?._id && (
                    <Button
                      prefixIcon={<Icons.PremiumFilled />}
                      skin="premium"
                      priority="secondary"
                      as="a"
                      href={upgradeUrl}
                      target={"_blank"}
                    >
                      Upgrade to Publish
                    </Button>
                  )}
              </ComposerHeader.MainActions>
            )}
          </ComposerHeader>
        </Cell>
        <Cell>
          <Box direction="vertical">
            <Box gap="0" height="90vh">
              {activeStep === 0 && renderCreateStep()}
              {activeStep === 1 && renderRecipientsPage()}
              {activeStep === 2 && renderPublishPage()}
            </Box>
          </Box>
        </Cell>
      </Layout>
    </WixDesignSystemProvider>
  );
}

export default App;
